import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import OtpInput from "react-otp-input";
import Countdown from "react-countdown";

import { useDispatch, useSelector } from "react-redux";

//components
import AuthCard from "../../components/AuthCard/AuthCard";
import CustomButton from "../../components/CustomButton/CustomButton";
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert";

import { setVerifyUrl } from "../../appRedux/actions/auth";

import { showLoader } from "../../appRedux/actions/common";
import {
  requestVerifyOtp,
  requestResendOTP,
} from "../../appRedux/actions/auth";
const VerifyIdentity = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { token } = useSelector(({ auth }) => auth);
  const { loader, errMessage } = useSelector(({ common }) => common);
  const { email } = useParams();
  const [otp, setOtp] = useState("");
  const [countdownKey, setCountdownKey] = useState(1);

  //	const [error, setError] = useState('');
  //const [loading, setLoading] = useState(false);
  //	// console.log('otp length', otp.length);
  useEffect(() => {
    if (token !== null) {
      history.push("/");
    }
  });

  useEffect(() => {
    dispatch(setVerifyUrl({}));
  }, []);

  const submit = () => {
    dispatch(showLoader(true));
    dispatch(requestVerifyOtp({ email, otp }));
  };
  const resendOTPHandler = () => {
    dispatch(requestResendOTP(email));
    setCountdownKey((prevState) => prevState + 1);
  };

  const renderer = ({ seconds, completed }) => {
    if (completed) {
      return (
        <span
          onClick={resendOTPHandler}
          className="text-sm text-blue font-bold cursor-pointer"
        >
          Resend code
        </span>
      );
    } else {
      return (
        <span className="font-bold text-gray2">
          Resend code in 00:{seconds}
        </span>
      );
    }
  };

  return (
    <AuthCard>
      <div className="flex flex-col items-center space-y-2">
        <h1 className="text-2xl font-bold text-center">Verify your identity</h1>

        <p className="leading-none text-center text-gray2 w-[60%] text-sm">
          Please enter verification code sent to{" "}
          <span className="font-bold">{email}</span>
        </p>
      </div>

      <div className="w-[65%]">
        <form className="space-y-2">
          {errMessage && <ErrorAlert error={errMessage} />}
          <OtpInput
            value={otp}
            onChange={(otp) => setOtp(otp)}
            numInputs={6}
            inputStyle={`bg-[#F4F5F7] w-10 h-10 caret-blue border-0 border-transparent font-medium ${
              errMessage
                ? "text-red border-b-2 border-red rounded-t-md rounded-b-none"
                : "text-gray1 rounded-md"
            }`}
            focusStyle="outline-none ring-0 border-b-2 border-b-blue rounded-t-md rounded-b-none text-appBlue transition duration-500 ease-in-out ring-transparent"
            separator={<span className="w-2.5" />}
          />
        </form>

        <CustomButton
          text="Login"
          onClick={submit}
          disabled={otp.length < 6}
          loading={loader}
        />

        <div className="flex items-center justify-center mt-3">
          <span className="text-[14px]  text-gray3">
            Didn't get code?{" "}
            <Countdown
              renderer={renderer}
              date={Date.now() + 30000}
              zeroPadTime={3}
              key={countdownKey}
            />
          </span>
        </div>

        <div className="flex flex-col items-center mt-10 space-y-2">
          <Link to="/" className="underline text-sm text-gray2">
            Use another method to recieve the code
          </Link>

          <Link to="/login" className="underline text-sm text-gray2">
            Sign in with different account
          </Link>
        </div>
      </div>
    </AuthCard>
  );
};

export default VerifyIdentity;
