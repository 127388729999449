import React from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';

import AuthCard from '../AuthCard/AuthCard';
import { makeStyles } from '@material-ui/core/styles';
import MailIcon from '../../assets/mailIcon.svg';
import Phone from '../../assets/Phone.svg';
import rightArrow from '../../assets/rightArrow.svg';

export default function OTPReceive() {
	return (
		<AuthCard>
			<div className="flex flex-col justify-center items-center space-y-4">
				<div>
					<p className="  text-2xl text-appBlack font-bold">
						Verify your identity{' '}
					</p>
				</div>
				<div>
					<p className="text-gray2 text-base">
						Select a way to recieve the verification code
					</p>
				</div>
				<div className="w-full mx-auto">
					<div className="w-full bg-[#F4F5F7] bg-opacity-50 hover:bg-orange hover:bg-opacity-10 my-4 cursor-pointer ">
						<div className="flex items-center space-x-4 py-3 px-4">
							<div className=" w-12 h-12 ">
								<img src={MailIcon} alt=" " className="w-full h-full" />
							</div>
							<div>
								<p className="text-sm text-gray2">Email code to</p>
								<p className="text-appBlack text-lg font-bold">
									{' '}
									jakpasu@drinksng.com
								</p>
							</div>
							<div className="pl-5">
								<img src={rightArrow} alt=" " />
							</div>
						</div>
					</div>
					<div className="w-full bg-[#F4F5F7]  bg-opacity-50 my-4 cursor-pointer hover:bg-orange hover:bg-opacity-10  ">
						<div className="flex items-center space-x-4 py-3 px-4  ">
							<div className=" w-12 h-12 ">
								<img src={Phone} alt=" " className="w-full h-full" />
							</div>
							<div>
								<p className="text-sm text-gray2">
									Text code to number ending in
								</p>
								<p className="text-appBlack text-lg font-bold">-14</p>
							</div>
							<div className="pl-5">
								<img src={rightArrow} alt=" " />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="flex flex-col items-center mt-10 py-3">
				<Link to="/login" className="underline text-sm text-gray2">
					Sign in with different account
				</Link>
			</div>
		</AuthCard>
	);
}
