import { Avatar } from '@material-ui/core';
import { numberFormat } from '../../utils'
import FallBackImage from '../../assets/drinkslogoIcon.svg';

import React from 'react';
const InfoCard = ({
	item: {
		id,
		title,
		header,
		description,
		litrecase,
		cases,
		bottles,
		sold,
		amount,
		src,
		percentage,
		location,
		bgColor,
		breakdown,
		total_quantity,
		label,
		revenue,
	},
	index,
}) => {
	// // console.log('item', item);
	return (
		<div className={`flex justify-evenly px-4 py-3  bg-white `}>
			<div className="flex items-center  space-x-2 flex-grow break-all">
				<div>
					<Avatar className="w-6 h-6 text-sm text-appBlack bg-thinGrayBG">
						{index + 1}
					</Avatar>
				</div>

				<Avatar className="w-12 h-12 " src={src || FallBackImage}>
					DM
				</Avatar>

				<div
					style={{
						inlineSize: 200,
						overflowWrap: 'break-word',
					}}
				>
					<h1 className="text-sm font-medium text-gray2 ">{label}</h1>
					<p className="font-semibold text-appGray text-left">{description}</p>
				</div>
			</div>
			{breakdown?.map((item, i) => (
				<React.Fragment key={i}>
					<div className="border-l-2 border-thinGrayBG"></div>
					<div className="flex-grow break-all pl-2 border-appGray">
						<p className="font-semibold text-sm text-[#4C536A]  text-left">
							{item?.attribute_name}
						</p>
						<div className="font-semibold text-left text-[#151515] text-lg">
							{ numberFormat(item?.total_revenue || 0).toCurrency() }
						</div>
						<p className="font-semibold text-xs text-appGray text-left">
							{ numberFormat(item?.quantity_sold || 0).toDecimal() } Items sold
						</p>
					</div>
					<div className="border-l-2 border-thinGrayBG"></div>
				</React.Fragment>
			))}

			<div className="flex-grow break-all bg-[#F4F5F7] rounded-lg">
				<p className="font-semibold text-appGray text-center pt-2">
					Total Revenue
				</p>
				<div className="text-sm font-semibold text-center text-[#477DFB] text-lg">
					{ numberFormat(revenue).toCurrency() }
				</div>
			</div>
		</div>
	);
};

export default InfoCard;
