import React from "react";

//components
import ProfileComponents from "../../components/profile/profile";
import { useLocation } from "react-router";
const SingleUser = () => {
  const location = useLocation();
  //   console.log(location);
  return (
    <ProfileComponents
      title="User Details"
      backbtn={true}
      exportPrintBtn={true}
      displayName={location?.state?.userdetails?.name}
    />
  );
};

export default SingleUser;
