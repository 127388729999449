import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ConnectedRouter } from 'connected-react-router';
import { Route, Switch } from 'react-router-dom';

import { Provider } from 'react-redux';
import configureStore from './appRedux/store';
import { history } from './appRedux/history';
import './styles/globals.css';
import { ThemeProvider } from '@material-ui/core/styles';

import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
	typography: {
		fontFamily: 'IBM Plex Sans',
	},
	palette: {
		primary: {
			main: '#F3641F',
		},
		secondary: {
			main: '#F3641F',
		},
	},
});
const store = configureStore(/* provide initial state if any */);

const Root = (
	<ThemeProvider theme={theme}>
		<Provider store={store}>
			<ConnectedRouter history={history}>
				<Switch>
					<Route path="/" component={App} />
				</Switch>
			</ConnectedRouter>
		</Provider>
	</ThemeProvider>
);
ReactDOM.render(Root, document.getElementById('root'));
