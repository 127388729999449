import React, { useState } from 'react';
// import { IoNotifications } from 'react-icons/io5';

//mui
import { Badge, IconButton, Popover, Avatar } from '@material-ui/core';
import notifsIcon from '../../assets/notificationIcon.svg';

const NotificationDropdown = () => {
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (e) => {
		setAnchorEl(e.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	return (
		<>
			<Badge badgeContent={0} color="primary">
				<IconButton
					className="p-0 hover:bg-transparent focus:bg-transparent"
					onClick={handleClick}
					disableRipple
					disableFocusRipple
				>
					<img src={notifsIcon} />
				</IconButton>
			</Badge>

			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				classes={{
					root: '',
					paper: 'bg-transparent shadow-none pt-2 pb-5',
				}}
			>
				<div className="">
					<div
						className={`relative left-[340px] z-50 w-0 h-0 border-t-0 border-l-[9px] border-r-[9px] border-b-[9px] border-solid border-l-transparent border-r-transparent border-b-white`}
					/>
				</div>

				<div className="bg-white w-[600px] shadow-md rounded-md pb-5">
					<div className="border-b border-[#E3E7ED] p-3">
						<h1 className="text-[20px] text-[#040A1D] font-bold">
							Notifications
						</h1>
					</div>

					<div className="divide-y divide-[#E3E7ED] px-6 overflow-y-scroll h-80">
						{/* <div className="flex items-center py-4">
							<Avatar className="">DS</Avatar>
							<p className="w-[70%] ml-2 text-sm">
								New user Daniel Sams{' '}
								<span className="text-orange">(daniel@drinksng.com)</span> flied
								a complaint
							</p>

							<p className="text-[12px] text-gray2 flex-grow text-right">
								Today 11:40
							</p>
						</div> */}

						{/* <div className="flex items-center py-4">
							<Avatar
								className=""
								src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80"
							>
								DS
							</Avatar>
							<p className="w-[70%] ml-2 text-sm">
								New user Daniel Sams{' '}
								<span className="text-orange">(daniel@drinksng.com)</span>{' '}
								accepted invite to join dashboard.
							</p>

							<p className="text-[12px] text-gray2 flex-grow text-right">
								Yesterday 09:40
							</p>
						</div>

						<div className="flex items-center py-4">
							<Avatar className="">DM</Avatar>
							<p className="w-[70%] ml-2 text-sm">
								New user Daniel Sams{' '}
								<span className="text-orange">(daniel@drinksng.com)</span>{' '}
								accepted invite to join dashboard.
							</p>

							<p className="text-[12px] text-gray2 flex-grow text-right">
								Yesterday 09:40
							</p>
						</div>

						<div className="flex items-center py-4">
							<Avatar className="">DM</Avatar>
							<p className="w-[70%] ml-2 text-sm">
								New user Daniel Sams{' '}
								<span className="text-orange">(daniel@drinksng.com)</span>{' '}
								accepted invite to join dashboard.
							</p>

							<p className="text-[12px] text-gray2 flex-grow text-right">
								Yesterday 09:40
							</p>
						</div>

						<div className="flex items-center py-4">
							<Avatar className="">DM</Avatar>
							<p className="w-[70%] ml-2 text-sm">
								New user Daniel Sams{' '}
								<span className="text-orange">(daniel@drinksng.com)</span>{' '}
								accepted invite to join dashboard.
							</p>

							<p className="text-[12px] text-gray2 flex-grow text-right">
								Yesterday 09:40
							</p>
						</div> */}
						<div className="flex text-gray2 text-sm h-full justify-center items-center">
							<p> No notifications</p>
						</div>
					</div>
				</div>
			</Popover>
		</>
	);
};

export default NotificationDropdown;
