import React from 'react';

import excelIcon from '../../assets/excel.svg';
import { FormControlLabel } from '@material-ui/core';
import DateActions from '../DateActions/DateActions';
import { BpCheckbox } from '../CustomButton/customCheckBox';
import exportFromJSON from 'export-from-json';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import generatePDF from '../../utils/generatepdf';
export default function ProductsTop({
	title,
	checked,
	activePeriod,
	setActivePeriod,
	pdf,
	exportBtn,
	excelBtn,
	competitors,
	filtered,
	noborder,

	handleDateRangeSelect,
	handleDayFilter,
	exportData,
	filterByCompetitors,
	handleStateFilter,
	handleCountryFilter,
}) {
	const [checkBox, setCheckBox] = React.useState(false);
	const { topProductLists, topLocationLists, locationFilters } = useSelector(
		({ brands }) => brands
	);

	const HandleCheckBox = () => {
		setCheckBox(!checkBox);
		filterByCompetitors();
	};
	const data = exportData;

	const fileName = 'DrinksNG Data';
	const exportType = exportFromJSON.types.csv;
	return (
		<div
			className={`flex justify-between align-middle  py-2 ${
				!filtered && !noborder ? 'border-b border-thinGrayBG' : 'border-0'
			} `}
		>
			<div className=" items-center ">
				<h4 className=" px-1 text-cardHeading font-medium">{title}</h4>
			</div>
			<div
				className="flex space-x-1  divide-y  border-b-1 border-appGray
			 divide-[#EEF2F5]"
			>
				{competitors && (
					<FormControlLabel
						classes={{
							root: 'text-xs bg-[#F4F5F7] pr-2',
							label: 'text-xs font-medium',
						}}
						control={
							<BpCheckbox checked={checked} blue onChange={HandleCheckBox} />
						}
						label="Show competitors"
					/>
				)}
				{filtered && (
					<div className="flex space-x-1">
						<select className="shadow-none text-sm border border-[#E3E7ED] text-appBlack font-medium  focus:border-none rounded-md">
							{_.uniqBy(topProductLists, 'label')?.map((item) => (
								<option value={item.label}>{item.label}</option>
							))}
						</select>
						<select className="shadow-none text-sm border border-[#E3E7ED] text-appBlack font-medium  focus:border-none rounded-md" onChange={(e)=>handleCountryFilter(e.target.value)}>
							{_.uniqBy(locationFilters, 'country')?.map((item) => (
								<option value={item.country}>{item.country}</option>
							))}
						</select>
						<select className="shadow-none text-sm border border-[#E3E7ED] text-appBlack font-medium  focus:border-none rounded-md" onChange={(e)=>handleStateFilter(e.target.value)}>
							{_.uniqBy(locationFilters, 'city')?.map((item) => (
								<option value={item.city}>{item.city}</option>
							))}
						</select>
						{/* </div> */}
					</div>
				)}

				<DateActions
					// today={today}
					// week={week}
					// month={month}
					activePeriod={activePeriod}
					changePeriod={setActivePeriod}
					handleDateRangeSelect={handleDateRangeSelect}
					className="text-sm whitespace-nowrap space-x-1"
					handleDayFilter={handleDayFilter}
				/>
				<div className="">
					{exportBtn && (
						<button
							onClick={() => {
								generatePDF(exportData);
							}}
							className="flex justify-center border-2 p-1 space-x-3 rounded-md  justify-around border-[#EEF2F5] font-normal ml-2"
						>
							<button className=" text-appGray align-middle ">Export</button>
							<img src={pdf} alt="" />
						</button>
					)}
					{excelBtn && (
						<button
							onClick={() => {
								exportFromJSON({ data, fileName, exportType });
							}}
							// onClick={exportData}
							className="bg-transparent rounded-md border border-[#E9ECEF] px-[10px] py-[5px] text-[12px] text-[#9CA7B8] flex flex-row items-center ml-3"
						>
							Export
							<img
								src={excelIcon}
								alt="search"
								className="w-[24px] h-[24px] ml-2"
							/>
						</button>
					)}
				</div>
			</div>
		</div>
	);
}
