import React, { useState, useMemo, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Button, Dialog, LinearProgress } from "@material-ui/core";

import LinearDeterminate from "./linearProgress";
//components
import usersInactive from "../../assets/users-inactive.svg";
import loaderIcon from "../../assets/loader.svg";
import success from "../../assets/success.svg";
import XIcons from "../../assets/x-ixon.svg";
import { IoArrowUp, IoCloseCircle, IoArrowBack } from "react-icons/io5";
import fileUploadInactive from "../../assets/csvUpload.svg";
import CSVUpload from "../../assets/csvUploadIcon.svg";
import FormSelect from "../../components/FormSelect/FormSelect";
import FormInput from "../../components/FormInput/FormInput";
import PageTitle from "../../components/PageTitle/pageTitle";
import { useDispatch, useSelector } from "react-redux";
import { showLoader } from "../../appRedux/actions/common";
import { requestSaveUsers } from "../../appRedux/actions/auth";
import CSVIcon from "../../assets/csv.svg";
const UploadFile = () => {
  const [open, setOpen] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [loader, setLoader] = useState(true);

  const onChangeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    // console.log(event.target.files[0]);
  };
  const SaveHandler = () => {
    setOpen(true);
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 3000);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedFile(null);
    history.push("/fileUpload");
  };

  const disabled = !selectedFile

  // console.log('retard  is ', selectedFile);
  const history = useHistory();
  return (
    <div className="px-10 ">
      <div className="mx-auto w-[950px] pt-3 ">
        <h4 className="font-semibold text-[20px] text-appBlack py-1">
          Select file to upload
        </h4>
        <h4 className="font-semibold text-[14px] text-gray2 py-1">
          Upload all your orders easily by importing a CSV file with all
          information
        </h4>
      </div>
      <div className="row-span-1  mx-auto  w-[950px] p-6 bg-white  ">
        <div className="grid grid-cols-[2fr,1fr] w-full gap-5">
          {/* <div>
						<LinearDeterminate />
					</div> */}
          {selectedFile !== null ? (
            <div className="  border  border-gray-300 border-dashed rounded-md w-[900px]">
              <div className="relative w-full p-2 ">
                <div
                  className="absolute flex justify-end w-full right-5 cursor-pointer	"
                  onClick={() => {
                    setSelectedFile(null);
                  }}
                >
                  <img src={XIcons} alt="cancel" />
                </div>
                <div className="flex justify-center items-center py-4 flex-col ">
                  <img src={CSVIcon} alt="" className="py-2" />
                  <p className="text-appBlack text-[16px] font-semibold">
                    {" "}
                    {selectedFile.name}
                  </p>
                  <p className="text-gray2 text-[16px]">
                    {(Number(selectedFile.size) / 1000).toFixed(1)} kb
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col justify-center items-center space-y-2  rounded-md group w-[900px] h-52">
              <div className="flex items-center justify-center w-12 h-12 rounded-full ">
                <img
                  src={CSVUpload}
                  className="w-full h-full"
                  alt="upload csv"
                />
              </div>
              <label
                htmlFor="user-files"
                className="border border-[#E3E7ED] rounded-md cursor-pointer px-3 py-2"
              >
                <p className="text-base font-semibold text-gray2">
                  Select file
                </p>
                <input
                  type="file"
                  id="user-files"
                  multiple
                  accept=".csv"
                  onChange={onChangeHandler}
                  className="hidden"
                />
              </label>
            </div>
          )}
        </div>
      </div>
      <div className="row-span-1 w-[950px]  mx-auto ">
        <div className="flex flex-row items-center justify-end mt-[30px]">
          <Button
            variant="contained"
            className={`text-white capitalize shadow-none ${
              disabled
                ? "bg-orange/30 cursor-not-allowed"
                : "bg-orange cursor-pointer"
            }`}
            onClick={SaveHandler}
            disabled={disabled}
          >
            Upload File
          </Button>
        </div>
      </div>

      <Dialog open={open}>
        <div className="flex flex-col items-center py-5 space-y-3 w-96 bg-[#FAFAFA]">
          {loader ? (
            <>
              <img src={fileUploadInactive} alt="" className="w-9 h-9" />
              <p className="font-medium capitalize text-gray2">
                Uploading new csv file
              </p>
              <img src={loaderIcon} alt="" className="animate-spin" />
            </>
          ) : (
            <>
              <span className="flex items-center justify-center w-16 h-16 rounded-full bg-green/20">
                <img src={success} alt="" className="" />
              </span>

              <p className="font-medium capitalize text-gray2 w-[38%] text-center">
                New CSV file Added Successfully
              </p>

              <div className="space-x-3">
                <Button
                  variant="contained"
                  className="capitalize bg-white border shadow-none"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Add Another File
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  className="capitalize shadow-none"
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Done
                </Button>
              </div>
            </>
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default UploadFile;
