import {
  all,
  call,
  fork,
  put,
  takeLatest,
  takeEvery,
} from "redux-saga/effects";
import {
  signInUserSuccess,
  successGetUsers,
  successSaveUsers,
  signOutUser,
  signOutUserSuccess,
  successVerifyOtp,
  setVerifyUrl,
  myDetailsSuccess,
  successChangePwd,
  requestMyDetails,
} from "../actions/auth";
import { showErrAlert, showLoader, showModal } from "../actions/common";
import {
  REQUEST_GET_USERS,
  REQUEST_SAVE_USER,
  SIGNIN_USER,
  SIGNOUT_USER,
  REQUEST_RESET_PWD,
  REQUEST_VERIFY_OTP,
  REQUEST_CHANGE_PWD,
  REQUEST_MY_DETAILS,
  REQUEST_RESEND_OTP,
  REQUEST_UPDATE_USER_EMAIL_PHONE,
} from "../actions/constants";
import {
  getUsersFromApi,
  Login,
  CreateUser,
  Logout,
  ResetPwd,
  VerifyLogin,
  VerifyReset,
  ChangePwd,
  getmyDetails,
  ResendOTP,
  UpdateUser,
  UpdateEmailOrPhone,
} from "../api/auth";

function* ResendOTPHandler(payload) {
  const actualPayload = { email: payload.payload };
  const res = yield call(ResendOTP, actualPayload);
  // console.log('res from resend otp', res);
  // console.log('my payload' + actualPayload);
  if (res?.status === 200) {
    yield put(showLoader(false));
  } else if (res === undefined) {
    yield put(showLoader(false));
    yield put(showErrAlert("Please check your internet connection"));
  } else {
    yield put(showLoader(false));
    yield put(showErrAlert("PLease try again"));
  }
}
function* ResetPwdHandler({ payload }) {
  const res = yield call(ResetPwd, payload);
  // console.log('restt password rs', res);
  if (res?.status === 200) {
    yield put(showLoader(false));
    yield put(
      setVerifyUrl({
        type: "verify-reset",
        pathname: `/reset-password/${payload.email}/verify-identity`,
      })
    );
  } else if (res === undefined) {
    yield put(showLoader(false));
    yield put(showErrAlert("Please check your internet connection"));
  } else {
    yield put(showLoader(false));
    yield put(showErrAlert("Account does not exist"));
  }
}
// 'Account does not exist'
function* SignInUserHandler({ payload }) {
  const signInUser = yield call(Login, payload);
  if (signInUser?.status === 201) {
    yield put(showLoader(false));
    yield put(
      setVerifyUrl({
        type: "verify-login",
        pathname: `/login/${payload.email}/verify-identity`,
      })
    );
  } else if (signInUser === undefined) {
    yield put(showLoader(false));
    yield put(showErrAlert("Please check your internet connection"));
  } else {
    yield put(showLoader(false));
    yield put(showErrAlert("Unable to log in with provided credentials."));
  }
}

function* ChangePwdHandler({ payload }) {
  const actualPayload = {
    new_password: payload.newPassword,
    token: payload.token.token,
  };
  const res = yield call(ChangePwd, actualPayload);
  // console.log('res from changepwd hanfle', actualPayload);
  // console.log('res from reset', res);
  if (res?.status === 201) {
    yield put(successChangePwd(null));
    yield put(showLoader(false));
    yield put(
      setVerifyUrl({
        type: "reset-success",
        pathname: `/reset-password/reset-success`,
      })
    );
  } else {
    yield put(showLoader(false));
    yield put(showErrAlert("Verification code invalid or expired"));
  }
}

function* VerifyOTPHandler({ payload }) {
  let res;
  if (payload.type === "RESET") {
    res = yield call(VerifyReset, payload);
    // console.log('res from verifyotp shit', res);
    if (res?.status === 200) {
      const token = res.data.token;
      // console.log(token);
      yield put(showLoader(false));
      yield put(successVerifyOtp({ token }));
      yield put(
        setVerifyUrl({
          type: "reset-pwd",
          pathname: `/reset-password/new-password`,
        })
      );
    } else if (res === undefined) {
      yield put(showLoader(false));
      yield put(showErrAlert("Please check your internet connection"));
    } else {
      yield put(showLoader(false));
      yield put(showErrAlert("Verification code invalid or expired"));
    }
  } else {
    res = yield call(VerifyLogin, payload);
    if (res?.status === 200) {
      yield put(showLoader(false));
      const token = res.data.auth_token;
      yield sessionStorage.setItem("token", token);
      //yield localStorage.setItem('stayforweek', 'true');
      if (localStorage.getItem("stayforweek") === "true") {
        yield localStorage.setItem("token", token);
      }
      yield put(signInUserSuccess({ token }));
    } else if (res === undefined) {
      yield put(showLoader(false));
      yield put(showErrAlert("Please check your internet connection"));
    } else {
      yield put(showLoader(false));
      yield put(showErrAlert("Verification code invalid or expired"));
    }
  }
}

function* SignOutHandler() {
  yield localStorage.removeItem("stayforweek");
  yield put(signOutUserSuccess({ token: null }));
  const res = yield call(Logout, sessionStorage.getItem("token"));
  //// console.log(res)
  if (res.status === 204) {
    yield localStorage.removeItem("token");
    yield sessionStorage.removeItem("token");
    yield sessionStorage.removeItem("user_id");
  }
}

function* GetUsersHandler({ payload }) {
  let res = yield call(
    getUsersFromApi,
    sessionStorage.getItem("token"),
    payload
  );
  console.log(res);
  if (res.status === 200) {
    yield put(
      successGetUsers({
        userLists: res?.data.results,
        userListCount: res?.data.count,
      })
    );

    yield put(showLoader(false));
  } else {
    showErrAlert(res.error);
    yield put(showLoader(false));
  }
}

function* GetMyDetails() {
  let res;
  if (localStorage.getItem("stayforweek") === "true") {
    res = yield call(getmyDetails, localStorage.getItem("token"));
  } else {
    res = yield call(getmyDetails, sessionStorage.getItem("token"));
  }
  if (res.status === 200) {
    yield put(myDetailsSuccess(res.data));
  } else {
    yield put(signOutUser());
  }
}

function* SaveUserHandler({ payload }) {
  const res = yield call(
    CreateUser,
    sessionStorage.getItem("token"),
    payload.createUserData
  );
  console.log("resss", res);

  if (res.status === 201) {
    const res2 = yield call(
      UpdateUser,
      sessionStorage.getItem("token"),
      res.data.profile,
      payload.updateUserData
    );
    // console.log('rese222', res2);
    yield put(showLoader(false));
    yield put(successSaveUsers(res.data.users));
  } else {
    showErrAlert("Please try again");
    yield put(showLoader(false));
  }
}

function* UpdateUserHandler({ payload }) {
  console.log(payload);
  //   const res = null;
  const res = yield call(
    UpdateUser,
    sessionStorage.getItem("token"),
    payload.uuid,
    payload.personal
  );
  const res2 = yield call(
    UpdateEmailOrPhone,
    sessionStorage.getItem("token"),
    payload.userid,
    payload.emailPhone
  );

  console.log("resss", res, res2);

  if (res.status === 201) {
    yield put(showLoader(false));
    // yield put(successSaveUsers(res.data.users));
    yield put(requestMyDetails({}));
    // yield put(showModal({}));
  } else {
    showErrAlert(res.error);
    yield put(showLoader(false));
  }
}

// function* UpdateUserEmailOrPhoneHandler({ payload }) {
//   const res = yield call(
//     UpdateEmailOrPhone,
//     sessionStorage.getItem("token"),
//     payload.createUserData
//   );
//   // console.log("resss", res);

//   if (res.status === 201) {
//     yield put(showLoader(false));
//     yield put(successSaveUsers(res.data.users));
//   } else {
//     showErrAlert(res.error);
//     yield put(showLoader(false));
//   }
// }

export function* ActionWatchers() {
  yield takeLatest(SIGNIN_USER, SignInUserHandler);
  yield takeLatest(REQUEST_VERIFY_OTP, VerifyOTPHandler);
  yield takeLatest(REQUEST_CHANGE_PWD, ChangePwdHandler);
  yield takeEvery(REQUEST_GET_USERS, GetUsersHandler);
  yield takeEvery(REQUEST_SAVE_USER, SaveUserHandler);
  yield takeEvery(SIGNOUT_USER, SignOutHandler);
  yield takeEvery(REQUEST_RESET_PWD, ResetPwdHandler);
  yield takeEvery(REQUEST_MY_DETAILS, GetMyDetails);
  yield takeEvery(REQUEST_RESEND_OTP, ResendOTPHandler);
  yield takeEvery(REQUEST_UPDATE_USER_EMAIL_PHONE, UpdateUserHandler);
}

export default function* rootSaga() {
  yield all([fork(ActionWatchers)]);
}
