import React, { useState } from "react";
import LocationsInfoCard from "./locationsInfoCard";
import { useSelector } from "react-redux";
import {
  requestTopBrandDataList,
  requestTopProductDataList,
} from "../../appRedux/actions/brands";
import { numberFormat } from "../../utils";

function SearchComponent({
  ProductsTop,
  toptitle,
  searchPlaceholder,
  InfoCard,
  checked,
  countryLists,
  searchIcon,
  activePeriod,
  setActivePeriod,
  pdf,
  filtered,
  competitors,
  Pagination,
  checklabel,
  today,
  month,
  week,
  handleDateRangeSelect,
  handleDayFilter,
  handleLocationSearch,
  headers,
  brandsTable,
  filterByCompetitors,
  handleStateFilter,
  handleCountryFilter,
}) {
  const {
    // count,
    brandsCount,
    productsCount,
  } = useSelector(({ brands }) => brands);
  // const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const handleChange = (event, value) => {
    setPage(value);
    brandsTable
      ? requestTopBrandDataList({ page: page })
      : requestTopProductDataList({ page: page });
  };
  return (
    <div>
      <div class="grid grid-cols-1 gap-7 pb-5 pl-6 pr-6 ">
        <div className=" mt-3 bg-white p-4">
          <ProductsTop
            today={today}
            week={week}
            month={month}
            activePeriod={activePeriod}
            setActivePeriod={setActivePeriod}
            pdf={pdf}
            title={toptitle}
            competitors={competitors}
            filtered={filtered}
            checked={checked}
            checklabel={checklabel}
            handleDateRangeSelect={handleDateRangeSelect}
            handleDayFilter={handleDayFilter}
            filterByCompetitors={filterByCompetitors}
            handleStateFilter={handleStateFilter}
            handleCountryFilter={handleCountryFilter}
          />
        </div>
      </div>
      <div className="grid grid-cols-1 pb-5 pl-6 pr-6">
        <div className="flex w-full align-center justify-center border-b-2">
          <img src={searchIcon} alt="search icon" />
          <input
            onChange={(e) => handleLocationSearch(e.target.value)}
            type="text"
            placeholder={searchPlaceholder}
            className="w-full bg-transparent  border-0 hover:border-b focus:border-b focus:border-b-blue hover:border-b-blue  focus:outline-t-0 focus:outline-none focus:shadow-outline focus:border-0 focus:ring-0 text-appBlack font-semibold "
          />
        </div>
      </div>
      <div className="grid grid-cols-1 pb-5 pl-6 pr-6 divide-y divide-thinGrayBG">
        <div className="grid grid-cols-12 uppercase text-xs font-semibold text-gray2 py-2 ">
          {headers?.map((item, i) => (
            <div
              className={`${
                i === 0
                  ? "col-span-6 pl-12"
                  : i === 1
                  ? "col-span-3"
                  : i === 2
                  ? "col-span-2"
                  : i === 3
                  ? "col-span-1"
                  : ""
              } `}
            >
              {item}
            </div>
          ))}
        </div>
        {countryLists.length > 0 ? (
          countryLists?.map((item, index) => (
            <LocationsInfoCard
              key={item?.uuid}
              title={item?.label}
              index={index}
              amount={item?.revenue || 0}
              sold={item?.quantity_sold || 0}
              src={item?.logo}
              percentage={`${RoundTo2Decimals(
                FindPercentage(totalAmt(countryLists), item?.revenue)
              )}%`}
            />
          ))
        ) : (
          <div
            style={{ height: "40vh" }}
            className=" flex items-center justify-center  bg-white	"
          >
            <div className="  flex items-end pb-5 mb-3 ">
              <p className="text-[20px] text-gray3 font-semibold">
                Sorry, no data at the moment. All data will be shown here
              </p>
            </div>
          </div>
        )}
        <div className="flex mt-2 justify-end ">
          <Pagination
            page={page}
            count={
              brandsTable
                ? Math.ceil(brandsCount / 20)
                : Math.ceil(productsCount / 20)
            }
            shape="rounded"
            rowsPerPage={20}
            className="bg-white"
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
}

const totalAmt = (details) =>
  details?.reduce((a, c) => a + parseFloat(c?.revenue), 0);
const FindPercentage = (totalAmt, amt) => (amt / totalAmt) * 100;
const RoundTo2Decimals = (num) => num.toFixed(2);
// const RoundToWholeNumber = (num) => Math.round(num);

export default SearchComponent;
