import {
	SUCCESS_GET_BRAND_ANALYTICS,
	SUCCESS_GET_LOCATION_SALES_BREAKDOWN,
	SUCCESS_GET_TOP_BRANDS,
	SUCCESS_GET_TOP_LOCATIONS,
	SUCCESS_GET_TOP_PRODUCTS,
	SUCCESS_LOCATION_FILTERS,
	SUCCESS_GET_TOP_BRANDS_LIST,
	SUCCESS_GET_TOP_PRODUCTS_LIST,
	SUCCESS_COUNTRY_LIST,
	SUCCESS_PRODUCT_CATEGORY,
	SUCCESS_PRODUCT_SUBCATEGORY,
} from '../actions/constants';

const INIT_STATE = {
	topBrandLists: [],
	topProductLists: [],
	topLocationLists: [],
	topBrandDataList: [],
	topProductDataList: [],
	topLocationDataList: [],
	analyticList: {},
	locationSalesBreakdownLists: [],
	locationPage: { count: 0, prev: null, next: null },
	breakdownPage: { count: 0, prev: null, next: null },
	count: 0,
	next: null,
	previous: null,
	locationFilters: [],
	locationDataList: [],
	productCategories: [],
	productSubCategories: [],
};

const brands = (state = INIT_STATE, action) => {
	switch (action.type) {
		case SUCCESS_PRODUCT_SUBCATEGORY: {
			return {
				...state,
				...action.payload,
			};
		}
		case SUCCESS_PRODUCT_CATEGORY: {
			return {
				...state,
				...action.payload,
			};
		}
		case SUCCESS_COUNTRY_LIST: {
			return {
				...state,
				...action.payload,
			};
		}
		case SUCCESS_GET_TOP_PRODUCTS_LIST: {
			return {
				...state,
				...action.payload,
			};
		}
		case SUCCESS_GET_TOP_BRANDS_LIST: {
			return {
				...state,
				...action.payload,
			};
		}
		case SUCCESS_LOCATION_FILTERS: {
			return {
				...state,
				...action.payload,
			};
		}
		case SUCCESS_GET_BRAND_ANALYTICS: {
			return {
				...state,
				...action.payload,
			};
		}
		case SUCCESS_GET_TOP_BRANDS: {
			return {
				...state,
				...action.payload,
			};
		}
		case SUCCESS_GET_TOP_PRODUCTS: {
			return {
				...state,
				...action.payload,
			};
		}
		case SUCCESS_GET_TOP_LOCATIONS: {
			return {
				...state,
				...action.payload,
			};
		}
		case SUCCESS_GET_LOCATION_SALES_BREAKDOWN: {
			return {
				...state,
				...action.payload,
			};
		}

		default:
			return state;
	}
};
export default brands;
