import React from 'react';
import loader from '../../assets/loader.svg';

//mui
import { Button } from '@material-ui/core';

const CustomButton = ({ loading, onClick, disabled, text }) => {
	return (
		<Button
			disableFocusRipple
			variant="contained"
			fullWidth
			className={` relative py-2 mt-5 text-white capitalize shadow-none ${
				loading || disabled ? 'bg-orange/20' : 'bg-orange'
			}`}
			onClick={onClick}
			disabled={disabled}
		>
			{text}
			{loading && (
				<img
					src={loader}
					alt=""
					className="absolute w-5 right-2 animate-spin"
				/>
			)}
		</Button>
	);
};

export default CustomButton;
