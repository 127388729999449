import React, { useEffect } from 'react';
import Layout from '../Layout/Layout';
import PageTitle from '../PageTitle/pageTitle';
import ChartCards from '../Card/Card';
import { useSelector, useDispatch } from 'react-redux';

import { requestMyDetails } from '../../appRedux/actions/auth';
import {
	requestGetBrandAnalytics,
	requestGetTopBrands,
	requestGetTopLocations,
	requestGetTopProducts,
	requestTopBrandDataList,
	requestTopProductDataList,
	requestCountryList,
} from '../../appRedux/actions/brands';

export default function HomeComponent() {
	const dispatch = useDispatch();
	const { range } = useSelector(state => state.common.globalDate)

	useEffect(() => {
		const getRangeString = dtRng => `${dtRng.year}-${dtRng.month}-${dtRng.day}`
		const dateFilter = {
			before_data: getRangeString(range.from),
			after_date: getRangeString(range.to)
		}
		dispatch(requestMyDetails({}));
		dispatch(requestGetTopBrands(dateFilter));
		dispatch(requestGetTopProducts(dateFilter));
		dispatch(requestGetTopLocations(dateFilter));
		dispatch(requestGetBrandAnalytics(dateFilter));
		dispatch(requestTopBrandDataList(dateFilter));
		dispatch(requestTopProductDataList(dateFilter));
		dispatch(requestCountryList(dateFilter));
	}, []);
	return (
		<>
			<PageTitle title="Overview" />
			<ChartCards />
		</>
	);
}
