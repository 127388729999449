import API from './root';

export function* GetProductSubCategories(token, id) {
	console.log('id', id?.payload);
	try {
		return yield API().get(
			`/product-category/${id.payload}/product-category-children/`,
			{
				params: {
					page_size: 100,
				},
				headers: { Authorization: 'Token ' + token },
			}
		);
	} catch (error) {
		return yield error.response;
	}
}

export function* GetProductCategories(token, payload) {
	const { before_data, after_date, searchTerm, day_filter, page } = payload;

	try {
		const params = new URLSearchParams();
		if (day_filter) {
			params.append('date', day_filter);
		} else if (before_data) {
			params.append('date_range', '');
			params.append('date_range_before', after_date);
			params.append('date_range_after', before_data);
		} else if (page) {
			params.append('page', page);
		} else if (searchTerm) {
			params.append('search', searchTerm);
		} else {
		}
		return yield API().get(`/product-category/`, {
			params,
			headers: { Authorization: 'Token ' + token },
		});
	} catch (error) {
		return yield error.response;
	}
}
export function* AnalyticOverall(token, payload) {
	const { before_data, after_date, searchTerm, day_filter, page } = payload;
	// console.log('that shift', payload);
	try {
		const params = new URLSearchParams();
		if (day_filter) {
			params.append('date', day_filter);
		} else if (before_data) {
			params.append('date_range', '');
			params.append('date_range_before', after_date);
			params.append('date_range_after', before_data);
		} else if (page) {
			params.append('page', page);
		} else if (searchTerm) {
			params.append('search', searchTerm);
		} else {
		}
		return yield API().get(`/brand-product-sales/analytic-overall-overview/`, {
			params,
			headers: { Authorization: 'Token ' + token },
		});
	} catch (error) {
		return yield error.response;
	}
}
//get brands chart data
export function* GetTopBrands(token, payload) {
	const { before_data, after_date, searchTerm, day_filter, page } = payload;
	try {
		const params = new URLSearchParams();
		if (day_filter) {
			params.append('date', day_filter);
		} else if (before_data) {
			params.append('date_range', '');
			params.append('date_range_before', after_date);
			params.append('date_range_after', before_data);
		} else if (page) {
			params.append('page', page);
		} else if (searchTerm) {
			params.append('search', searchTerm);
		} else {
		}
		return yield API().get(`/brand-product-sales/top-brand-chart/`, {
			params,
			headers: { Authorization: 'Token ' + token },
		});
	} catch (error) {
		return yield error.response;
	}
}

//top brands data list
export function* GetTopBrandsDataList(token, payload) {
	const {
		before_data,
		after_date,
		searchTerm,
		day_filter,
		page,
		country,
		city,
	} = payload;
	try {
		const params = new URLSearchParams();
		if (day_filter) {
			params.append('date', day_filter);
		} else if (before_data) {
			params.append('date_range', '');
			params.append('date_range_before', after_date);
			params.append('date_range_after', before_data);
		} else if (page) {
			params.append('page', page);
		} else if (searchTerm) {
			params.append('search', searchTerm);
		} else if (country) {
			params.append('country', country);
		} else if (city) {
			params.append('city', city);
		} else {
		}
		return yield API().get(`/brand-product-sales/top-brand/`, {
			params,
			headers: { Authorization: 'Token ' + token },
		});
	} catch (error) {
		return yield error.response;
	}
}

//country list
export function* GetCountryList(token, payload) {
	const {
		before_data,
		after_date,
		searchTerm,
		day_filter,
		page,
		city,
		country,
	} = payload;
	try {
		const params = new URLSearchParams();
		if (day_filter) {
			params.append('date', day_filter);
		} else if (before_data) {
			params.append('date_range', '');
			params.append('date_range_before', after_date);
			params.append('date_range_after', before_data);
		} else if (page) {
			params.append('page', page);
		} else if (searchTerm) {
			params.append('search', searchTerm);
		} else if (country) {
			params.append('country', country);
		} else if (city) {
			params.append('city', city);
		} else {
		}
		return yield API().get(`/brand-product-sales/top-location/`, {
			params,

			headers: { Authorization: 'Token ' + token },
		});
	} catch (error) {
		return yield error.response;
	}
}
//brand sales top location

export function* GetTopLocation(token, payload) {
	const {
		before_data,
		after_date,
		searchTerm,
		day_filter,
		page,
		city,
		country,
	} = payload;
	try {
		const params = new URLSearchParams();
		if (day_filter) {
			params.append('date', day_filter);
		} else if (before_data) {
			params.append('date_range', '');
			params.append('date_range_before', after_date);
			params.append('date_range_after', before_data);
		} else if (page) {
			params.append('page', page);
		} else if (searchTerm) {
			params.append('search', searchTerm);
		} else if (country) {
			params.append('country', country);
		} else if (city) {
			params.append('city', city);
		} else {
		}
		return yield API().get(`/brand-product-sales/top-location-chart/`, {
			params,

			headers: { Authorization: 'Token ' + token },
		});
	} catch (error) {
		return yield error.response;
	}
}
//top prodcuts
export function* GetTopProducts(token, payload) {
	const {
		before_data,
		after_date,
		searchTerm,
		day_filter,
		page,
		city,
		country,
	} = payload;
	try {
		const params = new URLSearchParams();
		if (day_filter) {
			params.append('date', day_filter);
		} else if (before_data) {
			params.append('date_range', '');
			params.append('date_range_before', after_date);
			params.append('date_range_after', before_data);
		} else if (page) {
			params.append('page', page);
		} else if (searchTerm) {
			params.append('search', searchTerm);
		} else if (country) {
			params.append('country', country);
		} else if (city) {
			params.append('city', city);
		} else {
		}
		return yield API().get(`/brand-product-sales/top-product-chart/`, {
			params,

			headers: { Authorization: 'Token ' + token },
		});
	} catch (error) {
		return yield error.response;
	}
}
//top prodcuts list

export function* GetTopProductsDataList(token, payload) {
	const {
		before_data,
		after_date,
		searchTerm,
		day_filter,
		page,
		city,
		country,
	} = payload;
	try {
		const params = new URLSearchParams();
		if (day_filter) {
			params.append('date', day_filter);
		} else if (before_data) {
			params.append('date_range', '');
			params.append('date_range_before', after_date);
			params.append('date_range_after', before_data);
		} else if (page) {
			params.append('page', page);
		} else if (searchTerm) {
			params.append('search', searchTerm);
		} else if (country) {
			params.append('country', country);
		} else if (city) {
			params.append('city', city);
		} else {
		}
		return yield API().get(`/brand-product-sales/top-product/`, {
			params,

			headers: { Authorization: 'Token ' + token },
		});
	} catch (error) {
		return yield error.response;
	}
}
//this is for the location breakdown page
export function* GetProductSaleBreakDown(token, payload) {
	const {
		before_data,
		after_date,
		searchTerm,
		day_filter,
		sale_point,
		town,
		city,
		product_category,
		country,
	} = payload;
	try {
		const params = new URLSearchParams();
		if (city) {
			params.append('city', city);
		} else if (town) {
			params.append('town', town);
		} else if (sale_point) {
			params.append('sale_point', sale_point);
		} else if (day_filter) {
			params.append('date', day_filter);
		} else if (before_data) {
			params.append('date_range', '');
			params.append('date_range_before', after_date);
			params.append('date_range_after', before_data);
		} else if (searchTerm) {
			params.append('search', searchTerm);
		} else if (product_category) {
			params.append('product_category', product_category);
		} else if (country) {
			params.append('country', country);
		} else {
		}
		return yield API().get(`/brand-product-sales/sale-analytic/`, {
			params,

			headers: { Authorization: 'Token ' + token },
		});
	} catch (error) {
		return yield error.response;
	}
}

export function* ProductsSaleInfo(token, payload) {
	const { before_data, after_date, searchTerm, day_filter } = payload;
	try {
		const params = new URLSearchParams();
		if (day_filter) {
			params.append('date', day_filter);
		} else if (before_data) {
			params.append('date_range', '');
			params.append('date_range_before', after_date);
			params.append('date_range_after', before_data);
		} else if (searchTerm) {
			params.append('search', searchTerm);
		} else {
		}
		return yield API().get(`/product/top-selling-products-chart/`, {
			params,

			headers: { Authorization: 'Token ' + token },
		});
	} catch (error) {
		return yield error.response;
	}
}

//endpoint for accordion

export function* GetAccordionInfo(token, payload) {
	const { before_data, after_date, searchTerm, day_filter } = payload;
	try {
		const params = new URLSearchParams();
		if (day_filter) {
			params.append('date', day_filter);
		} else if (before_data) {
			params.append('date_range', '');
			params.append('date_range_before', after_date);
			params.append('date_range_after', before_data);
		} else if (searchTerm) {
			params.append('search', searchTerm);
		} else {
		}
		return yield API().get(`/api/brand-product-sales/`, {
			params,

			headers: { Authorization: 'Token ' + token },
		});
	} catch (error) {
		return yield error.response;
	}
}

// data for location filters
export function* LocationFilters(token, payload) {
	try {
		return yield API().get('/brand-sale-location/', {
			headers: { Authorization: 'Token ' + token },
		});
	} catch (error) {
		return yield error.response;
	}
}
