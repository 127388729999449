import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import auth from "./auth";
import brands from "./brands";
import common from "./common";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth,
    common,
    brands,
  });

export default createRootReducer;
