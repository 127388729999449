import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";

//assets
import orders from "../../assets/orders-inactive.svg";
import plus from "../../assets/plus.svg";
import Loader from "../../components/loader/loader";
import EmptyTableView from "../../components/EmptyTableVIew/EmptyTableView";
import TableActions from "../../components/TableActions/TableActions";

import PageTitle from "../../components/PageTitle/pageTitle";
import UsersTable from "./../../components/UsersTable/UsersTable";
import { requestGetUsers } from "../../appRedux/actions/auth";
import { requestMyDetails } from "../../appRedux/actions/auth";

const Users = () => {
  const dispatch = useDispatch();

  const { userLists } = useSelector(({ auth }) => auth);
  const { loader } = useSelector(({ common }) => common);
  const [activeTab, setActiveTab] = useState("all");
  const [search, setSearch] = useState("");
  const [busy, setBusy] = useState(false);
  const history = useHistory();

  const openCreateOrder = () => {
    history.push("/users/create-user");
  };

  const openUpdateOrder = (row) => {
    // console.log('row is', row);
    history.push({
      pathname: "/users/single-user",
      state: row,
    });
  };
  const onEditUser = (row) => {
    // console.log('row is', row);
    history.push({
      pathname: "/users/update-user",
      state: row,
    });
  };
  useEffect(() => {
    dispatch(requestGetUsers({}));
    dispatch(requestMyDetails({}));
  }, []);
  //   console.log(userLists);
  return (
    <div className="font-bold ">
      <PageTitle title="Users" />
      <div className="px-[23px]">
        {Boolean(loader) ? (
          <Loader />
        ) : search === "" && userLists?.length === 0 ? (
          <EmptyTableView
            title={"Your users will show here"}
            subtitle={"This is where all users."}
            btnLabel={"Create a User"}
            icon={orders}
            onClick={openCreateOrder}
          />
        ) : search !== "" && userLists.length > 0 ? (
          <>
            <TableActions
              activeTab={activeTab}
              exportData={userLists?.map((item) => {
                return {
                  //   ...item,
                  first_name: item?.profile?.first_name,
                  last_name: item?.profile?.last_name,
                  gender: item?.profile?.gender,
                  date_of_birth: item?.profile?.date_of_birth,
                  phone_number: item?.phone_number,
                  email: item?.email,
                  account_type: item?.account_type,
                };
              })}
              changeTab={setActiveTab}
              onSearchChange={(e) => {
                setSearch(e.target.value);
                dispatch(requestGetUsers({ searchTerm: e.target.value }));
              }}
            />

            <div className="flex flex-row justify-end w-full mt-4">
              <button
                onClick={openCreateOrder}
                className="h-[43px] bg-[#F3641F] rounded flex flex-row items-center text-white text-[16px] font-medium px-3"
              >
                <img src={plus} alt="plus" className="w-[16px] h-[16px] mr-4" />
                Add New User
              </button>
            </div>
            <UsersTable
              rows={userLists}
              onViewClick={openUpdateOrder}
              onEditClick={onEditUser}
              search={search}
              busy={busy}
            />
          </>
        ) : (
          <>
            <TableActions
              activeTab={activeTab}
              exportData={userLists?.map((item) => {
                return {
                  //   ...item,
                  first_name: item?.profile?.first_name,
                  last_name: item?.profile?.last_name,
                  gender: item?.profile?.gender,
                  date_of_birth: item?.profile?.date_of_birth,
                  phone_number: item?.phone_number,
                  email: item?.email,
                  account_type: item?.account_type,
                };
              })}
              changeTab={setActiveTab}
              onSearchChange={(e) => {
                setSearch(e.target.value);
                dispatch(requestGetUsers({ searchTerm: e.target.value }));
              }}
            />

            <div className="flex flex-row justify-end w-full mt-4">
              <button
                onClick={openCreateOrder}
                className="h-[43px] bg-[#F3641F] rounded flex flex-row items-center text-white text-[16px] font-medium px-3"
              >
                <img src={plus} alt="plus" className="w-[16px] h-[16px] mr-4" />
                Add New User
              </button>
            </div>
            <UsersTable
              rows={userLists}
              onViewClick={openUpdateOrder}
              onEditClick={onEditUser}
              search={search}
              busy={busy}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Users;
