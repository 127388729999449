import React, { useEffect, useState } from 'react';
import { Avatar, Button, IconButton, Popover } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import NotificationDropdown from '../notifications/notifs';
import DropdownMenu from '../ProfileDropDown/profileDropDown';
import FAQicon from '../../assets/FAQ_icon.svg';
import DocsIcon from '../../assets/docsIcon.png';
import FallBackImage from '../../assets/drinkslogoIcon.svg';

import { format } from 'date-fns';

export default function Topbar() {
	const d = new Date();
	const { myDetails } = useSelector(({ auth }) => auth);

	// const [dateState, setDateState] = useState({
	// 	date: format(d, 'dd MMMM yyyy'),
	// 	time: format(d, ' hh:mm a'),
	// });

	// useEffect(() => {
	// 	setTimeout(() => {
	// 		setDateState({
	// 			date: format(d, 'dd MMMM yyyy'),
	// 			time: format(d, ' hh:mm a'),
	// 		});
	// 	}, 1000);
	// }, [dateState]);
	return (
		<div className="sticky flex justify-end">
			<div className="flex align-middle pl-4 pt-3  justify-between">
				{/* <div className="flex align-middle">
					<Avatar
						alt="A avatr"
						src={myDetails?.profile?.picture || FallBackImage}
						alt="company"
					/>
					<div className="pl-2">
						<h1 className="font-extrabold text-appBlack">Diageo Nigerian</h1>
						<p className="font-normal text-appGray">{myDetails?.email}</p>
					</div>
				</div> */}
				<div className="flex align-middle items-center justify-evenly space-x-2 pr-5 ">
					{/* <div className="flex items-center justify-around">
						<p className="text-appGray px-1 ">{dateState.date}</p>
						<p className="text-appBlack font-bold">{dateState.time}</p>
					</div> */}
					{/* <div className=" flex align-center items-center justify-between px-2 ">
						<img
							src={feedbackIcon}
							width="auto"
							height="auto"
							alt=""
							className="px-1"
						/>
						<h5 className="text-appGray ">Feedback?</h5>
					</div> */}
					{/* <div className="flex align-middle  items-center  ">
						<FeedbackDropDown />
					</div> */}
					<div className="flex align-middle px-2  items-center ">
						<NotificationDropdown />
					</div>

					<div className="">
						<DropdownMenu />
					</div>
				</div>
			</div>
		</div>
	);
}

const FeedbackDropDown = () => {
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (e) => {
		setAnchorEl(e.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const history = useHistory();
	return (
		<>
			<IconButton
				onClick={handleClick}
				disableRipple
				disableFocusRipple
				className="px-1 hover:bg-transparent focus:bg-transparent"
			>
				<img src={FAQicon} alt="" height="auto" width="auto" />
			</IconButton>

			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				classes={{
					root: '',
					paper: 'bg-white shadow-none pt-4 pb-5',
				}}
			>
				<div className="w-[250px] rounded-md bg-white p-4">
					<div className="flex items-center space-x-2 border-b-2 border-gray-200">
						<h1 className="font-bold text-gray1 pb-2">Help</h1>
					</div>

					<div className="py-2">
						<Button
							className="flex justify-start w-full p-2 text-base font-medium capitalize rounded-md cursor-pointer hover:bg-orange/10 hover:text-orange text-gray1"
							startIcon={<img src={DocsIcon} />}
							onClick={() => {
								history.push('/documentation');
								handleClose();
							}}
						>
							Documentation
						</Button>

						<Button
							className="flex justify-start w-full p-2 text-base font-medium capitalize rounded-md cursor-pointer hover:bg-orange/10 hover:text-orange text-gray1"
							startIcon={<img src={FAQicon} />}
							onClick={() => {
								history.push('/support');
								handleClose();
							}}
						>
							Support Site
						</Button>
					</div>
				</div>
			</Popover>
		</>
	);
};
