import React from 'react';
import PieChart from '../../PieChart/piechart';
export default function ProductAMiddle({
	Top4ProductLists,
	pieChartData,
	top4Title,
}) {
	return (
		<>
			{pieChartData.length > 0 ? (
				<div className="grid grid-cols-12 gap-3 py-6  border-b-2 border-[#EEF2F5] ">
					<div className="col-span-7 justify-start ">
						<div className="">
							<PieChart pieChartData={pieChartData} />
						</div>
					</div>
					<div className=" col-span-5 items-center justify-center  px-3 h-5 ">
						<h3 className="font-appBlack font-medium text-base pt-2 pb-3">
							{top4Title}
						</h3>
						{Top4ProductLists?.map((item, i) => (
							<div
								className="flex justify-between  font-appBlack bg-[#F4F5F7] px-3 mb-1 py-2 "
								key={i}
							>
								<div className="flex align-middle items-center space-x-1 ">
									<div
										className={`w-4 h-4 p-2  rounded-full ${
											i === 0
												? 'bg-appBlue'
												: i === 1
												? 'bg-chartRed'
												: i === 2
												? 'bg-appGold'
												: i === 3
												? 'bg-appGreen'
												: ''
										}`}
									>
										<p></p>
									</div>
									<p className="text-[#4C536A] font-medium text-sm ">
										{item?.label} {''}
										<span className="font-medium text-appBlack">
											{/* {item?.description}{' '} */}
										</span>
									</p>
								</div>
								<p className="font-medium text-sm text-appBlack">
									{RoundToWholeNumber(
										FindPercentage(totalAmt(Top4ProductLists), item?.revenue)
									)}
									%
								</p>
							</div>
						))}
					</div>
				</div>
			) : (
				<div className=" h-full flex items-center justify-center">
					<div className="w-full ">
						<p className="text-[20px] text-gray3 font-semibold">
							Sorry, no data at the moment. All data will be shown here
						</p>
					</div>
				</div>
			)}
		</>
	);
}

const totalAmt = (details) =>
	details?.reduce((a, c) => a + parseFloat(c?.revenue), 0);
const FindPercentage = (totalAmt, amt) => (amt / totalAmt) * 100;
const RoundTo2Decimals = (num) =>
	Math.round((num + Number.EPSILON) * 1000) / 1000;
const RoundToWholeNumber = (num) => Math.round(num);
