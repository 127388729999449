import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

//utils
import validateEmail from '../../utils/validateEmail';

//components
import AuthCard from '../../components/AuthCard/AuthCard';
import FormInput from '../../components/FormInput/FormInput';
import CustomButton from '../../components/CustomButton/CustomButton';
import ErrorAlert from '../../components/ErrorAlert/ErrorAlert';

import { useDispatch, useSelector } from 'react-redux';
import { requestResetPwd } from '../../appRedux/actions/auth';
import { showErrAlert, showLoader } from '../../appRedux/actions/common';
const ResetPassword = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { verifyURL } = useSelector(({ auth }) => auth);

	const { loader, errMessage } = useSelector(({ common }) => common);
	const [email, setEmail] = useState('');

	useEffect(() => {
		if (verifyURL?.type === 'verify-reset') {
			history.push(verifyURL?.pathname);
		}
	});

	const handleChange = (e) => {
		setEmail(e.target.value);
	};

	const isEmailValid = validateEmail(email);
	const submit = () => {
		dispatch(showLoader(true));
		dispatch(showErrAlert(''));

		if (!isEmailValid) {
			dispatch(showErrAlert('Invalid email address'));
			dispatch(showLoader(false));
			return;
		}
		dispatch(requestResetPwd({ email }));

		/*  setTimeout(() => {
       //  history.push(`/reset-password/${email}/verify-identity`);
     }, 5000); */
	};

	return (
		<AuthCard>
			<div className="flex flex-col items-center space-y-2">
				<h1 className="text-2xl font-bold text-center">Reset Password</h1>

				<p className="leading-none text-center text-gray2 w-[60%] text-sm">
					Enter email address associated with your account and we'll send you a
					code to reset your password
				</p>
			</div>

			<div className="w-[65%]">
				<form className="space-y-2">
					{errMessage && <ErrorAlert error={errMessage} />}
					<FormInput
						id="email"
						name="email"
						type="email"
						placeholder="Email Address"
						label="Email Address"
						onChange={handleChange}
						value={email}
						error={errMessage}
					/>
				</form>

				<CustomButton
					text="Continue"
					onClick={submit}
					disabled={!isEmailValid}
					loading={loader}
				/>

				<div className="flex items-center justify-center mt-2">
					<Link
						to="/login"
						className="font-bold text-[12px] underline text-blue"
					>
						Return to login
					</Link>
				</div>

				{/* <div className="flex items-center justify-center mt-3">
					<span className="text-[12px] text-gray-400">
						Don't have an account?{' '}
						<Link to="/login" className="font-bold underline text-blue">
							Sign up
						</Link>
					</span>
				</div> */}
			</div>
		</AuthCard>
	);
};

export default ResetPassword;
