import React, { useEffect } from 'react';

function MyMap({ markerData }) {
	const [loaded, setLoaded] = React.useState(false);
	const naijaCoors = [9.081999, 8.675277];
	const ghCooors = [7.946527, -1.023194];

	useEffect(() => {
		// console.log('marker Data', markerData);

		let map = new window.mapboxgl.Map({
			zoom: markerData ? 2 : 0.5,
			container: 'map',
			style: process.env.REACT_APP_STATES_MAP_STYLE,
			accessToken: process.env.REACT_APP_MAP_TOKEN,
			renderWorldCopies: false,
			center: markerData === 'Ghana' ? ghCooors : naijaCoors,
		});
		map.on('load', () => {
			document.querySelector('#input');
			setLoaded(true);
		});

		map.on('load', () => {
			if (country) {
				map.setFilter('country', ['in', ['get', 'name_en'], country]);
			}
		});
		map.on('style.load', () => {
			const waiting = () => {
				if (!map.isStyleLoaded()) {
					setTimeout(waiting, 500);
				} else {
					markerData !== undefined && loaded && highlightCountry(country);
				}
			};
			waiting();
		});
		let country = markerData;
		async function highlightCountry(e) {
			// console.log(e);
			await map.setFilter('country', ['in', ['get', 'name_en'], e]);
			map.on('resize', () => {
				console.log('A resize event occurred.');
			});
			map.on('load', 'circle', (zz) => {
				map.flyTo({
					center: zz.features[0].geometry.coordinates,
				});
			});
		}

		return () => {
			map.remove();
		};
		// markerData !== undefined && loaded && highlightCountry(country);
	}, [markerData, loaded]);

	return (
		<div>
			<div id="map" style={{ width: '100%' }}></div>
		</div>
	);
}
export default MyMap;
