import { Avatar } from '@material-ui/core';
const InfoCard = ({
	item: {
		id,
		title,
		header,
		amt,
		sold,
		amount,
		src,
		percentage,
		location,
		bgColor,
	},
}) => {
	return (
		<div className={`flex justify-between px-4 py-3  ${bgColor} `}>
			<div className="flex items-center space-x-2 ">
				<div>
					<h1 className="text-sm font-semibold text-[#151515]">{id}</h1>
				</div>
				{!location && !header && (
					<Avatar
						className="w-8 h-8"
						src={
							src ||
							'https://images.unsplash.com/photo-1566666152520-27af5f022a0f?ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8YWxjb2hvbHxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60'
						}
					>
						DM
					</Avatar>
				)}
				<div>
					<h1 className="text-sm font-medium text-gray2">
						{title || 'Delasi Mensah'}
					</h1>
				</div>
			</div>
			<div>
				<h1 className="text-sm font-semibold text-left text-[#151515]">
					{sold || '₦3,559'}
				</h1>
			</div>
			<div>
				<h1 className="text-sm font-semibold text-left text-[#151515]">
					{amount || '₦3,559'}
				</h1>
			</div>
			<div>
				<h1 className="font-semibold text-orange text-left">
					{percentage || '₦3,559'}
				</h1>
			</div>
		</div>
	);
};

export default InfoCard;
