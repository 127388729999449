import React, { useState, useEffect } from 'react';

import ProductsTop from '../../components/productsComponent/productATop';
import PageTitle from '../../components/PageTitle/pageTitle';
import pdf from '../../assets/pdf.svg';
import filter from '../../assets/filter.svg';
import searchIcon from '../../assets/search.svg';
import FilterData from './filter';

import greenUpArrow from '../../assets/greenUpArrow.png';
import { Line } from 'react-chartjs-2';

import Pagination from '@material-ui/lab/Pagination';

import { BrandInfoCard } from './card';
import InfoCard from './tablex';
import SearchComponent from './search';
import OverviewComponent from './overview';
import { useHistory } from 'react-router-dom';
import {
	requestGetBrandAnalytics,
	requestGetTopBrands,
	requestGetTopLocations,
	requestGetTopProducts,
	requestTopProductDataList,
	requestCountryList,
	requestLocationFilters,
} from '../../appRedux/actions/brands';
import { requestMyDetails } from '../../appRedux/actions/auth';

import { useSelector, useDispatch } from 'react-redux';
import MyMap from '../../components/Map/Index';
import _ from 'lodash';
import { getDateRangeQs } from '../../utils'

function Locations() {
	const history = useHistory();
	const dispatch = useDispatch();
	const {
		topBrandLists,
		topLocationLists,
		topProductLists,
		topProductDataList,
		locationDataList,
	} = useSelector(({ brands }) => brands);
	const { globalDate } = useSelector(state => state.common);
	const period = globalDate.period.toLowerCase()

	const [activePeriod, setActivePeriod] = useState(period);
	// const [brandsRange, setBrandsRange] = React.useState(globalDate.range);
	const [chartData, setChartData] = useState(null);

	useEffect(() => {
		dispatch(requestMyDetails({}));

		const dateFilter = getDateRangeQs(globalDate.range);
		dispatch(requestGetTopLocations(dateFilter));
		dispatch(requestGetBrandAnalytics(dateFilter));
		dispatch(requestGetTopBrands(dateFilter));
		dispatch(requestGetTopProducts(dateFilter));
		dispatch(requestTopProductDataList(dateFilter));
		dispatch(requestCountryList(dateFilter));
		dispatch(requestLocationFilters(dateFilter));
	}, []);

	const handleDayFilter = (day) => {
		dispatch(requestGetTopLocations({ day_filter: day?.toLowerCase() }));
	};
	const handleCountryDayFilter = (day) => {
		dispatch(requestCountryList({ day_filter: day?.toLowerCase() }));
	};
	const handleCountryRangeSelect = (range) => {
		// setBrandsRange(range);
		dispatch(requestCountryList(getDateRangeQs(
			range.to === null
				? { ...range, to: range.from }
				: range
		)));
	};
	const handleDateRangeSelect = (range) => {
		// setBrandsRange(range);
		dispatch(requestGetTopLocations(getDateRangeQs(
			range.to === null
				? { ...range, to: range.from }
				: range
		)));
	};
	const handleLocationSearch = (term) => {
		dispatch(requestTopProductDataList({ searchTerm: term }));
	};

	const [filterCompetitors, setFilterCompetitors] = useState(false);
	const filterByCompetitors = () => {
		setFilterCompetitors(!filterCompetitors);
	};
	useEffect(() => {
		// initialiseChart();
	}, [filterCompetitors]);
	const handleProductsFilter = (day) => {
		dispatch(requestTopProductDataList({ day_filter: day?.toLowerCase() }));
	};
	const handleProductRangeSelect = (range) => {
		dispatch(requestTopProductDataList(getDateRangeQs(
			range.to === null
				? { ...range, to: range.from }
				: range
		)));
	};
	const handleStateFilter = (filterTerm) => {
		dispatch(requestTopProductDataList({ city: filterTerm }));
	};
	const handleCountryFilter = (filterTerm) => {
		dispatch(requestTopProductDataList({ country: filterTerm }));
	};
		const handleLocationCountryFilter = (filterTerm) => {
		dispatch(requestGetTopLocations({ country: filterTerm }));
	};
	const handleLocationStateFilter= (filterTerm)=>{
		dispatch(requestGetTopLocations({ city: filterTerm }));
	}

	return (
		<div className="overflow-hidden">
			<PageTitle title="Locations" />

			<div class="grid grid-cols-1 gap-7 pb-5 pl-6 pr-6 ">
				<div className=" mt-5 bg-white  p-4 border-b pb-5  border-thinGrayBG ">
					<div className="border-b border-thinGrayBG">
						<ProductsTop
							today={true}
							week={true}
							month={true}
							activePeriod={activePeriod}
							setActivePeriod={setActivePeriod}
							pdf={pdf}
							title="Location Analytics"
							excelBtn={true}
							handleDateRangeSelect={handleCountryRangeSelect}
							handleDayFilter={handleCountryDayFilter}
							exportData={locationDataList?.map(({ uuid, ...item }) => item)}
							checked={filterCompetitors}
							filterByCompetitors={filterByCompetitors}
						/>
					</div>
					<div className=" flex justify-between  py-3 w-full bg-white ">
						<div className="w-[65%] h-full bg-white">
							<span className="text-blue bg-[#ECF2FE] rounded-md px-1">
								World
							</span>
							<div id="map">
								<MyMap />
							</div>
						</div>

						<div className="w-[30%]">
							<div>Countries</div>
							{locationDataList?.map((item) => (
								<BrandInfoCard
									name={item?.country}
									amount={item?.revenue}
									growthPercent={`${RoundToWholeNumber(
										FindPercentage(totalAmt(locationDataList), item?.revenue)
									)}%`}
									totalsold={item?.quantity_sold}
									GotoDetails={() => history.push('/locations/details', item)}
								/>
							))}
						</div>
					</div>
				</div>
			</div>

			<OverviewComponent
				today={true}
				week={true}
				month={true}
				FilterData={FilterData}
				ProductsTop={ProductsTop}
				activePeriod={activePeriod}
				setActivePeriod={setActivePeriod}
				pdf={pdf}
				filter={filter}
				greenUpArrow={greenUpArrow}
				Line={Line}
				chartData={chartData}
				topdataLists={_.orderBy(topLocationLists, ['label'], ['asc'])}
				handleDateRangeSelect={handleDateRangeSelect}
				handleDayFilter={handleDayFilter}
				handleStateFilter={handleLocationStateFilter}
				handleCountryFilter={handleLocationCountryFilter}
			/>
			<SearchComponent
				today={true}
				week={true}
				month={true}
				filtered={true}
				competitors={true}
				countryLists={
					filterCompetitors === true
						? topProductDataList?.filter((o) => o.label === 'Competitor')
						: topProductDataList?.filter((o) => o.label !== 'Competitor')
				}
				searchIcon={searchIcon}
				pdf={pdf}
				setActivePeriod={setActivePeriod}
				activePeriod={activePeriod}
				ProductsTop={ProductsTop}
				Pagination={Pagination}
				InfoCard={InfoCard}
				checked={filterCompetitors}
				checklabel="With Competitors"
				toptitle="Location Breakdown"
				searchPlaceholder="Search Products"
				handleLocationSearch={handleLocationSearch}
				handleDayFilter={handleProductsFilter}
				handleDateRangeSelect={handleProductRangeSelect}
				headers={['Products', 'Quantity Sold', 'Amount', 'Percentage']}
				filterByCompetitors={filterByCompetitors}
				handleStateFilter={handleStateFilter}
				handleCountryFilter={handleCountryFilter}
				
			/>
		</div>
	);
}

const totalAmt = (details) =>
	details?.reduce((a, c) => a + parseFloat(c?.revenue), 0);
const FindPercentage = (totalAmt, amt) => (amt / totalAmt) * 100;
const RoundTo2Decimals = (num) =>
	Math.round((num + Number.EPSILON) * 1000) / 1000;
const RoundToWholeNumber = (num) => Math.round(num);

export default Locations;
