import React from 'react';
import OtherProducts from './otherAProducts';
import ProductsTop from '../productATop';
import ProductAMiddle from './productAMiddle';

export default function ProductAnalyticPage({
	pdf,
	exportBtn=true,
	activePeriod,
	setActivePeriod,
	Top4ProductLists,
	GrayEllipse,
	otherBrands,
	today,
	month,
	week,
	pieChartData,
	title,
	otherItemsTitle,
	handleDateRangeSelect,
	handleDayFilter,
	exportData,
	top4Title,
}) {
	// const extractRevenues = (dataArg) => dataArg.map(({ revenue }) => revenue);
	// const extractLabel = (dataArg) => dataArg.map(({ label }) => label);
	// console.log('first data', exportData);
	return (
		<div className="row-span-1 bg-white  p-6">
			<div className="border-b border-thinGrayBG">
				<ProductsTop
					today={today}
					week={week}
					month={month}
					activePeriod={activePeriod}
					setActivePeriod={setActivePeriod}
					pdf={pdf}
					exportBtn={exportBtn}
					title={title}
					handleDateRangeSelect={handleDateRangeSelect}
					handleDayFilter={handleDayFilter}
					exportData={exportData}
				/>
			</div>
			<ProductAMiddle
				pieChartData={pieChartData}
				Top4ProductLists={Top4ProductLists}
				top4Title={top4Title}
			/>
			{otherBrands.length > 0 && (
				<OtherProducts
					GrayEllipse={GrayEllipse}
					otherBrands={otherBrands}
					title={otherItemsTitle}
				/>
			)}
		</div>
	);
}
