import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
	requestGetLocationSalesBreakdown,
	requestGetTopLocations,
	requestLocationFilters,
	requestCategories,
	requestSubCategories,
} from '../../appRedux/actions/brands';
import { getDateRangeQs } from '../../utils'

function MoreComponent({
	ProductsTop,
	toptitle,
	searchPlaceholder,
	InfoCard,
	checked,
	countryLists,
	searchIcon,
	activePeriod,
	setActivePeriod,
	pdf,
	filtered,
	noborder,
	competitors,
	Pagination,
	checklabel,
	today,
	month,
	week,
	handleDayFilter,
	handleLocationSearch,
	handleDateRangeSelect,
	filterByCompetitors,
	filterBySalesPoint,
}) {
	const dispatch = useDispatch();
	const {
		locationBreakdownCount,
		locationFilters,
		productCategories,
		productSubCategories,
	} = useSelector(({ brands }) => brands);
	const { globalDate } = useSelector(state => state.common);

	const [saleType, setSaleType] = useState('Online');
	const [selectedItem, setSelectedItem] = useState({});
	const [page, setPage] = useState(1);

	const handleChange = (event, value) => {
		setPage(value);

		dispatch(requestGetLocationSalesBreakdown({ page: page }));
	};

	useEffect(() => {
		dispatch(requestLocationFilters({}));
		dispatch(requestCategories({}));

		const dateFilter = getDateRangeQs(globalDate.range);
		dispatch(requestGetTopLocations({ dateFilter }));
		dispatch(requestGetLocationSalesBreakdown({ dateFilter }));
	}, []);

	return (
		<div>
			<div class="grid grid-cols-1 gap-7 pb-5  pr-6 ">
				<div className=" mt-3 bg-white  p-4">
					<ProductsTop
						today={today}
						week={week}
						month={month}
						activePeriod={activePeriod}
						setActivePeriod={setActivePeriod}
						pdf={pdf}
						title={toptitle}
						competitors={competitors}
						filtered={filtered}
						noborder={noborder}
						checked={checked}
						checklabel={checklabel}
						handleDateRangeSelect={handleDateRangeSelect}
						handleDayFilter={handleDayFilter}
						filterByCompetitors={filterByCompetitors}
					/>
				</div>
			</div>
			<div className="flex w-full ">
				<div className="w-[20%] divide-y-4 divide-[#9CA7B8] ">
					<div className="py-3">
						<div className="flex flex-col w-full pb-4	">
							<label className="text-[#3D4356] px-5 font-medium text-sm">
								Country{' '}
							</label>
							<select
								className="shadow-none bg-white rounded-lg text-sm text-appBlack border border-[#E3E7ED]  focus:border-none font-semibold"
								onChange={(e) => {
									dispatch(
										requestGetLocationSalesBreakdown({
											country: e.target.value,
										})
									);
								}}
							>
								{_.uniqBy(locationFilters, 'country')?.map((item) => (
									<option value={item.country}>{item.country}</option>
								))}
							</select>
						</div>
						<div className="flex flex-col w-full pb-4	">
							<label className="text-[#3D4356] px-5 font-medium text-sm">
								State{' '}
							</label>
							<select
								className="shadow-none bg-white rounded-lg text-sm text-appBlack border border-[#E3E7ED]  focus:border-none font-semibold"
								onChange={(e) => {
									dispatch(
										requestGetLocationSalesBreakdown({
											city: e.target.value,
										})
									);
								}}
							>
								{_.uniqBy(locationFilters, 'city')?.map((item) => (
									<option value={item.city}>{item.city}</option>
								))}
							</select>
						</div>
						<div className="flex flex-col w-full pb-4	">
							<label className="text-[#3D4356] px-5 text-sm font-medium">
								Local Government Area{' '}
							</label>
							<select
								className="shadow-none bg-white rounded-lg text-sm text-appBlack border border-[#E3E7ED]  focus:border-none font-semibold"
								onChange={(e) => {
									dispatch(
										requestGetLocationSalesBreakdown({
											town: e.target.value,
										})
									);
								}}
							>
								{_.uniqBy(locationFilters, 'town')?.map((item) => (
									<option value={item.town}>{item.town}</option>
								))}
							</select>
						</div>
					</div>
					<div className="py-3">
						<div className="flex flex-col w-full pb-4	">
							<label className="text-[#3D4356] px-5 text-sm font-medium">
								Trade Type
							</label>
							<select
								value={saleType}
								className="shadow-none bg-white rounded-lg text-sm text-appBlack border border-[#E3E7ED]  focus:border-none font-semibold"
								onChange={(e) => {
									dispatch(
										requestGetLocationSalesBreakdown({
											sale_point: e.target.value,
										})
									);
									setSaleType(e.target.value);
								}}
							>
								<option value="Online">Online</option>
								<option value={`On Premise`}>On Premise</option>
							</select>
						</div>
						{saleType === 'On Premise' && (
							<div className="flex flex-col w-full pb-4	">
								<label className="text-[#3D4356] px-5 text-sm font-medium">
									LOCATION{' '}
								</label>
								<select className="shadow-none bg-white rounded-lg text-sm text-appBlack border border-[#E3E7ED]  focus:border-none font-semibold">
									{/* <option>Night Clubs</option>
									<option>Casino</option> */}
								</select>
							</div>
						)}
					</div>
					<div className="py-3">
						<div className="flex flex-col w-full pb-4	">
							<label className="text-[#3D4356] px-5 text-sm font-medium">
								CATEGORIES{' '}
							</label>
							<select
								className="shadow-none bg-white rounded-lg text-sm  text-appBlack border border-[#E3E7ED]  focus:border-none font-semibold"
								onChange={async (e) => {
									dispatch(
										requestGetLocationSalesBreakdown({
											product_category: e.target.value,
										})
									);

									let myItem = [...productCategories];
									let mydig = myItem.find((cat) => cat.uuid === e.target.value);

									await dispatch(requestSubCategories(mydig?.id));
								}}
							>
								{productCategories.map((item) => {
									return (
										<option
											value={item.uuid}
											className="hover:text-orange hover:bg-orange font-medium focus:bg-orange focus:bg-opacity-10 focus-within:text-gray-600 peer-hover:text-orange "
										>
											{item.name}
										</option>
									);
								})}
							</select>
						</div>
						<div className="flex flex-col w-full pb-4	">
							<label className="text-[#3D4356] px-5 text-sm font-medium">
								SUB CATEGORIES
							</label>
							<select
								className="shadow-none bg-white rounded-lg  text-sm text-appBlack border border-[#E3E7ED]  focus:border-none font-semibold"
								onChange={(e) =>
									dispatch(
										requestGetLocationSalesBreakdown({
											product_category: e.target.value,
										})
									)
								}
							>
								{productSubCategories?.map((item) => (
									<option value={item.uuid}>{item.name}</option>
								))}
							</select>
						</div>
					</div>
				</div>
				<div className="w-[80%]">
					<div className="grid grid-cols-1 pb-5 pl-6 pr-6">
						<div className="flex w-full align-center justify-center border-b-2">
							<img src={searchIcon} alt="search icon" />
							<input
								onChange={(e) => handleLocationSearch(e.target.value)}
								type="text"
								placeholder={searchPlaceholder}
								className="w-full bg-transparent  border-0 hover:border-b focus:border-b focus:border-b-blue hover:border-b-blue  focus:outline-t-0 focus:outline-none focus:shadow-outline focus:border-0 focus:ring-0 text-appBlack font-semibold "
							/>
						</div>
					</div>
					<div className="grid grid-cols-1 pb-5 pl-6 pr-6">
						{countryLists?.length > 0 ? (
							countryLists?.map((item, i) => (
								<InfoCard key={i} index={i} item={item} />
							))
						) : (
							<div
								style={{ height: '40vh' }}
								className=" flex items-center justify-center  bg-white	"
							>
								<div className="  flex items-end pb-5 mb-3 ">
									<p className="text-[20px] text-gray3 font-semibold">
										Sorry, no data at the moment. All data will be shown here
									</p>
								</div>
							</div>
						)}
						<div className="flex mt-2 justify-end ">
							{locationBreakdownCount > 0 && (
								<Pagination
									count={Math.ceil(locationBreakdownCount / 20)}
									className="bg-white"
									page={page}
									shape="rounded"
									onChange={handleChange}
								/>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default MoreComponent;
