import axios from 'axios';

export const API_URL = `${process.env.REACT_APP_SERVER}/api`;
// export const API_URL = "https://drinks-ng-marketplace.nw.r.appspot.com/api";

const root = () => {
	return axios.create({
		baseURL: API_URL,
		// baseURL: API_URL,
		// withCredentials: true,
		headers: {
			// 'Access-Control-Allow-Origin': '*',
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
	});
};

export default root;
