const initials = (firstName, lastName) => {
  const initials = `${firstName.substring(0,1)}${lastName.substring(0,1)}`;
  return initials;
}

const getDateRangeQs = range => {
  const getRangeString = dtRng => `${dtRng.year}-${dtRng.month}-${dtRng.day}`
  return {
    before_data: getRangeString(range.from),
    after_date: getRangeString(range.to)
  }
}

function numberFormat(value) {
  function _formatter(value, options) {
    const formatter = Intl.NumberFormat(undefined, {
      maximumFractionDigits: 2,
      trailingZeroDisplay: 'stripIfInteger',
      ...options
    })
    return formatter.format(value)
  }

  return {
    toPercent(options={}) {
      return _formatter(value / 100, {
        style: 'percent',
        maximumFractionDigits: 2,
        ...options
      })
    },
    toCurrency(currency='NGN', options={}) {
      return _formatter(value, {
        style: 'currency',
        currency,
        currencyDisplay: 'narrowSymbol',
        notation: 'compact',
        minimumSignificantDigits: 1,
        maximumSignificantDigits: 5,
        roundingMode: 'ceil',
        ...options
      })
    },
    toDecimal(options) {
      return _formatter(value, options)
    },
  }
}

export {
  initials,
  getDateRangeQs,
  numberFormat,
}
