import React from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import {
	Drawer,
	List,
	ListItem,
	Divider,
	Typography,
	Menu,
	MenuItem,
} from '@material-ui/core';
// images
import brandlogo from '../../assets/brandLogo.svg';
import Logo1 from '../../assets/drinkslogo.svg';
import { useSelector } from 'react-redux';
import brandsInactive from '../../assets/Inactive/brandsInactive.svg';
import productsInactive from '../../assets/Inactive/Brands.svg';
import customersInactive from '../../assets/Inactive/Customers.svg';
import locationsInactive from '../../assets/Inactive/locations.svg';
import settingsInactive from '../../assets/Inactive/Settings.svg';
import usersInactive from '../../assets/Inactive/usersInactive.svg';
import overviewInactive from '../../assets/Inactive/overview.svg';
import FileUploadInactive from '../../assets/Inactive/fileUpload.svg';

import overviewActive from '../../assets/Active/overview.svg';
import brandsActive from '../../assets/Active/brandsActive.svg';
import productsActive from '../../assets/cleanProducts.svg';
import usersActive from '../../assets/Active/users.svg';
import locationsActive from '../../assets/Active/locationsActive.svg';
import settingsActive from '../../assets/Active/settingsActive.svg';
import FileUploadActive from '../../assets/Active/fileUpload.svg';
const links = [
	{
		name: 'Overview',
		path: '/overview',
		activeIcon: overviewActive,
		inActiveIcon: overviewInactive,
	},
	{
		name: 'Brands',
		path: '/brands',
		activeIcon: brandsActive,
		inActiveIcon: brandsInactive,
	},
	{
		name: 'Products',
		path: '/products',
		activeIcon: productsActive,
		inActiveIcon: productsInactive,
	},
	{
		name: 'locations',
		path: '/locations',
		activeIcon: locationsActive,
		inActiveIcon: locationsInactive,
	},
	// {
	// 	name: 'Details',
	// 	path: '/locations/details',
	// 	activeIcon: locationsActive,
	// 	inActiveIcon: locationsInactive,
	// },
	// {
	// 	name: 'More',
	// 	path: '/locations/more',
	// 	activeIcon: locationsActive,
	// 	inActiveIcon: locationsInactive,
	// },
	{
		name: 'users',
		path: '/users',
		activeIcon: usersActive,
		inActiveIcon: usersInactive,
	},
	{
		name: 'fileUpload',
		path: '/fileUpload',
		activeIcon: FileUploadActive,
		inActiveIcon: FileUploadInactive,
	},
	{
		name: 'settings',
		path: '/profile',
		activeIcon: settingsActive,
		inActiveIcon: settingsInactive,
	},
];
export default function SideBar() {
	// const location = useLocation();
	// // console.log('pathname', location.pathname);
	const { myDetails } = useSelector(({ auth }) => auth);


	return (
		<Drawer
			variant="permanent"
			className="w-[115px]"
			classes={{
				paper: 'w-[115px]',
				paperAnchorLeft: 'border-0',
			}}
			anchor="left"
		>
			<div className="flex justify-center pt-5 pb-10">
				<img src={Logo1} alt="" className="w-[80%]" />
			</div>
			<div>
				<ul>
					{links.map((item, key) => (
						// myDetails.account_type.includes('Admin') &&
						// item.name !== 'fileUpload' &&
						// item.name !== 'users' && (
						<SideBarItem
							key={key}
							item={item}
							hasDropdown={item.name === 'locations'}
						/>
					))}
				</ul>
			</div>
		</Drawer>
	);
}

const SideBarItem = ({ item, hasDropdown }) => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const history = useHistory();
	const subMenuItemClass = "cursor-pointer pl-2 flex items-center h-[37px] text-[#040A1D] text-[16px] font-medium w-full rounded-md bg-white hover:bg-[#F3641F] hover:bg-opacity-10 hover:text-[#F3641F]"

	const openMenu = (e) => {
		setAnchorEl(e.currentTarget);
	};

	const navigate = (path) => {
		history.push(path);
		setAnchorEl(null);
	};

	const location = useLocation();

	return (
		<>
			{hasDropdown ? (
				<div
					aria-controls="finance-menu"
					aria-haspopup="true"
					onClick={openMenu}
					className={
						location.pathname.includes(`${item.path}`)
							? 'flex justify-center pt-3 pb-3  border-appRed border-l-4'
							: 'flex justify-center pt-3 pb-3'
					}
				>
					<img
						src={
							location.pathname.includes(`${item.path}`)
								? item.activeIcon
								: item.inActiveIcon
						}
						alt="name"
						height="auto"
						width="auto"
					/>
				</div>
			) : (
				<Link to={`${item.path}`}>
					<div
						className={
							location.pathname.includes(`${item.path}`)
								? 'flex justify-center pt-3 pb-3  border-appRed border-l-4'
								: 'flex justify-center pt-3 pb-3'
						}
					>
						<img
							src={
								location.pathname === `${item.path}`
									? item.activeIcon
									: item.inActiveIcon
							}
							alt="name"
							height="auto"
							width="auto"
						/>
					</div>
				</Link>
			)}
			<Menu
				id="finance-menu"
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={() => setAnchorEl(null)}
				elevation={3}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				PaperProps={{
					style: {
						minWidth: '180px',
						// height: '96px',
						borderRadius: '6px',
						padding: '0px 12px',
						marginLeft: '90px',
					},
				}}
				disableEnforceFocus
				disableRestoreFocus
				disableAutoFocus
				disableAutoFocusItem
			>
				<div
					onClick={() => navigate('/locations')}
					className={subMenuItemClass}
				>
					<p>Overview</p>
				</div>
				{/*<div
					onClick={() => navigate('/locations/more')}
					className={subMenuItemClass}
				>
					<p>Sales Breakdown</p>
				</div>*/}
			</Menu>
		</>
	);
};
