import _ from "lodash";
import API from "./root";

export function* ResendOTP(data) {
  try {
    return yield API().post(`/users/resend-otp/`, data);
  } catch (error) {
    return yield error.response;
  }
}

export function* Login(data) {
  try {
    return yield API().post(`/auth/brands-dashboard/token/login/`, data);
  } catch (error) {
    return yield error.response;
  }
}

export function* VerifyLogin(data) {
  try {
    return yield API().post(`/auth/dashboard/confirm-login/`, data);
  } catch (error) {
    return yield error.response;
  }
}

export function* VerifyReset(data) {
  try {
    return yield API().post(`/users/verify-otp/`, data);
  } catch (error) {
    return yield error.response;
  }
}

export function* ChangePwd(data) {
  try {
    return yield API().post(`/users/reset-password-or-pin-confirm/`, data);
  } catch (error) {
    return yield error.response;
  }
}

export function* ResetPwd(data) {
  try {
    return yield API().post(`/users/reset-password-or-pin/`, data);
  } catch (error) {
    return yield error.response;
  }
}

export function* Logout(token) {
  try {
    return yield API().post(
      `/auth/token/logout/`,
      {},
      { headers: { Authorization: "Token " + token } }
    );
  } catch (error) {
    return yield error.response;
  }
}
export function* getOtp(data) {
  try {
    return yield API.post(`/auth/dashboard/token/login/`, data);
  } catch (error) {
    return yield error.response;
  }
}

export function* getUsersFromApi(token, payload) {
  const { searchTerm, page } = payload;
  try {
    const params = new URLSearchParams();
    // params.append('page_size', 100000);

    if (searchTerm) {
      params.append("search", searchTerm);
    } else if (page) {
      params.append("page", page);
    } else {
    }
    return yield API().get(`/users/`, {
      params,
      headers: { Authorization: "Token " + token },
    });
  } catch (error) {
    return yield error.response;
  }
}

export function* getmyDetails(token) {
  try {
    return yield API().get(`/users/me/`, {
      headers: { Authorization: "Token " + token },
    });
  } catch (error) {
    return yield error.response;
  }
}

export function* CreateUser(token, data) {
  try {
    return yield API().post(`/users/dashboard-add-user/`, data, {
      headers: { Authorization: "Token " + token },
    });
  } catch (error) {
    return yield error.response;
  }
}

export function* UpdateUserNameGender(token, uuid, data) {
  try {
    return yield API().patch(`/profile/${uuid}/`, data, {
      headers: {
        Authorization: "Token " + token,
      },
    });
  } catch (error) {
    return yield error.response;
  }
}

export function* UpdateUser(token, uuid, personal) {
  const formdata = new FormData();
  formdata.append("first_name", personal.first_name);
  formdata.append("gender", personal.gender);
  formdata.append("last_name", personal.last_name);
  formdata.append("picture", personal.picture);

  for (var pair of formdata.entries()) {
    console.log(pair[0] + ", " + pair[1]);
  }

  try {
    return yield API().patch(`/profile/${uuid}/`, formdata, {
      headers: {
        Authorization: "Token " + token,
        "content-type": "multipart/form-data",
      },
    });
  } catch (error) {
    return yield error.response;
  }
}

//endpoint to update email or Phone
export function* UpdateEmailOrPhone(token, uuid, emailPhone) {
  try {
    const result = yield API().patch(`/users/${uuid}/`, emailPhone, {
      headers: {
        Authorization: "Token " + token,
      },
    });

    return result;
  } catch (error) {
    return yield error.response;
  }
}

// accordiion data
// /brand-product-sales/sale-analytic/
