import {
	SIGNIN_USER_SUCCESS,
	SIGNOUT_USER_SUCCESS,
	SUCCESS_GET_USERS,
	INIT_URL,
	VERIFY_URL,
	SUCCESS_VERIFY_OTP,
	MY_DETAILS_SUCCESS,
	SUCCESS_CHANGE_PWD,
} from '../actions/constants';

const INIT_STATE = {
	initURL: '',
	verifyURL: {},
	token: sessionStorage.getItem('token'),
	userLists: [],
	userListCount: 0,
};

const auth = (state = INIT_STATE, action) => {
	switch (action.type) {
		case INIT_URL: {
			return {
				...state,
				initURL: action.payload,
			};
		}
		case SUCCESS_CHANGE_PWD: {
			return {
				...state,
				token: action.payload,
			};
		}
		case VERIFY_URL: {
			return {
				...state,
				verifyURL: action.payload,
			};
		}
		case SUCCESS_VERIFY_OTP: {
			// console.log(action)
			return {
				...state,
				token: action.payload,
			};
		}

		case SIGNIN_USER_SUCCESS: {
			return {
				...state,
				...action.payload,
			};
		}
		case SIGNOUT_USER_SUCCESS: {
			return {
				...state,
				...action.payload,
			};
		}
		case SUCCESS_GET_USERS: {
			return {
				...state,
				...action.payload,
			};
		}
		case MY_DETAILS_SUCCESS: {
			return {
				...state,
				myDetails: action.payload,
			};
		}
		default:
			return state;
	}
};
export default auth;
