import React, { useState, useEffect } from "react";
import {
  Button,
  Avatar,
  Dialog,
  IconButton,
  LinearProgress,
  ButtonGroup,
} from "@material-ui/core";
import Layout from "../Layout/Layout";
import checkStrength from "../../utils/checkPasswordStrength";
import FileUpload from "../../assets/FileUpload.svg";

import PageTitle from "../PageTitle/pageTitle";
import pen from "../../assets/pen.svg";
import profileImage from "../../assets/profileImage.png";
import FallBackImage from "../../assets/drinkslogoIcon.svg";

// import CustomizedSwitches from '../../components/CustomSwitch/CustomSwitch';
import FormInput from "../../components/FormInput/FormInput";
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert";
import { IoClose, IoArrowBack } from "react-icons/io5";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import API from "../../appRedux/api/root";

import { requestMyDetails } from "../../appRedux/actions/auth";

import printIcon from "../../assets/print.svg";
import pdfIcon from "../../assets/pdf.svg";
import dotdotIcon from "../../assets/dotdot.svg";
import generateUserPDF from "../../utils/generateUserPDF.js";
//mui
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//components

export default function ProfileComponents({
  displayName,
  title,
  backbtn,
  exportPrintBtn,
}) {
  const location = useLocation();
  //   console.log("use r proifle bs", location?.state);
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const handleOpen = () => {
    setOpen(true);
  };
  const userInfo = location?.state;

  const [state, setState] = useState({
    uuid: "",
    userid: "",
    is_active: false,
    first_name: "",
    last_name: "",
    gender: "",
    phone_number: "",
    email: "",
    image: "",
    account_type: "",
    picture: "",
  });

  useEffect(() => {
    setState({
      ...state,
      first_name: userInfo?.profile?.first_name,
      last_name: userInfo?.profile?.last_name,
      gender: userInfo?.profile?.gender,
      picture: userInfo?.profile?.picture,
      email: userInfo?.email,
      uuid: userInfo?.profile?.uuid,
      userid: userInfo?.uuid,
      phone_number: userInfo?.phone_number,
      account_type: userInfo?.account_type,
      is_active: userInfo?.is_active,
    });
  }, [userInfo]);

  const [reset, setReset] = useState({
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });
  const [disabled] = useState(true);
  const [error] = useState("");

  const [strength, setStrength] = useState("");
  const [progress, setProgess] = useState(0);
  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    const result = checkStrength(reset.newPassword);

    if (name === "newPassword") {
      if (value === "") {
        setProgess(0);
        setStrength("");
      } else {
        setStrength(result.strength);
        setProgess(result.progress);
      }
    }

    setReset({
      ...reset,
      [name]: value,
    });
  };
  const handleUpdate = () => {
    history.push({
      pathname: "/users/update-user",
      state: !exportPrintBtn ? myDetails : location?.state,
    });
  };

  const { myDetails } = useSelector(({ auth }) => auth);
  const token =
    sessionStorage.getItem("token") || localStorage.getItem("token");
  const handlePasswordChange = () => {
    API()
      .post(
        `/users/set-password-or-pin/`,
        {
          current_password: reset.oldPassword,
          new_password: reset.confirmNewPassword,
        },
        { headers: { Authorization: "Token " + token } }
      )
      .then((res) => {
        if (res.status === 204) {
          return toast.success("Password change was successful");
        }
        console.log("res from changing pass", res);
        localStorage.removeItem("token");
        sessionStorage.removeItem("token");
        history.push("/login");
      })
      .catch((error) => {
        return toast.error("Please try again");
      });
  };

  useEffect(() => {
    dispatch(requestMyDetails());
  }, []);
  //   console.log(state);
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="grid grid-flow-row w-[950px] mx-auto pt-4 pb-10 ">
        <div className="row-span-1 py-5">
          <div className="flex flex-col ">
            <div className="flex justify-start items-center space-x-1 pb-2">
              {backbtn && (
                <div className="flex items-center  space-x-4">
                  <div className="flex items-center justify-center w-10 h-10 rounded-full border-2 border-black">
                    <IoArrowBack
                      onClick={() => history.goBack()}
                      className="w-6 h-6 font-medium text-black"
                    />
                  </div>

                  <h1 className="text-appBlack font-bold  text-[30px]">
                    {state?.first_name}
                  </h1>
                  <h1 className="text-appBlack font-bold  text-[30px]">
                    {state?.last_name}
                  </h1>
                </div>
              )}
              <PageTitle title={displayName} />
            </div>
            <div>
              {exportPrintBtn && (
                <div className="flex items-center justify-end p-3 space-x-3 bg-white rounded-md border-b border-thinGrayBG">
                  <ButtonGroup className="">
                    <Button
                      className="border-[#E3E7ED]"
                      onClick={() =>
                        !exportPrintBtn
                          ? generateUserPDF({ tickets: [myDetails] })
                          : generateUserPDF({ tickets: [location?.state] })
                      }
                    >
                      <img src={pdfIcon} alt="" />
                    </Button>
                    <Button
                      className="border-[#E3E7ED]"
                      onClick={() =>
                        generateUserPDF({
                          tickets: [location?.state],
                          printDat: true,
                        })
                      }
                    >
                      <img src={printIcon} alt="" />
                    </Button>
                  </ButtonGroup>

                  <Button
                    onClick={() => handleUpdate()}
                    variant="contained"
                    className="text-white capitalize shadow-none bg-orange"
                    startIcon={<img src={pen} alt="" />}
                  >
                    Update User Info
                  </Button>
                </div>
              )}
            </div>
            <div className="flex justify-between py-4  bg-white">
              <h1 className="font-semibold pl-5 text-gray1 text-xl capitalize">
                {title}
              </h1>

              {!exportPrintBtn && (
                <div className="flex pr-4">
                  <Button
                    onClick={() => handleUpdate()}
                    variant="contained"
                    className="text-white capitalize shadow-none bg-orange"
                    startIcon={<img src={pen} alt="" />}
                  >
                    Update Info
                  </Button>
                </div>
              )}
              {exportPrintBtn && (
                <div className=" flex items-end justify-end pr-2 ">
                  <div
                    className={` items-center space-x-2 justify-center py-1 px-4 rounded-2xl capitalize ${
                      state?.is_active === false && "bg-appGray/20 text-appGray"
                    }
												 ${state?.is_active === true && "bg-green/20 text-green"}`}
                  >
                    <p>{state.is_active ? "Active" : "Inactive"}</p>
                  </div>
                </div>
              )}
            </div>

            <div className=" grid">
              <div className="row-span-1   p-4 bg-white ">
                <div className="grid grid-cols-[1fr,4fr] gap-3 ">
                  <div className="h-40 p-2 pl-5 ">
                    <Avatar
                      className="w-full h-full"
                      src={
                        state?.picture ||
                        myDetails?.profile.picture ||
                        FallBackImage
                      }
                    />
                  </div>

                  <div className="flex flex-col justify-between py-5">
                    <div className="flex items-center divide-x ">
                      <div className="px-10 ">
                        <h1 className="text-sm capitalize whitespace-nowrap text-gray3">
                          First Name
                        </h1>

                        <p className="text-gray1 text-base font-normal">
                          {state?.first_name || myDetails?.profile.first_name}
                        </p>
                      </div>
                      <div className="px-10 ">
                        <h1 className="text-sm capitalize text-gray3 whitespace-nowrap">
                          Last Name
                        </h1>

                        <p className="text-gray1">
                          {state?.last_name || myDetails?.profile.last_name}
                        </p>
                      </div>
                      <div className="px-10 ">
                        <h1 className="text-sm capitalize text-gray3">
                          Account Type
                        </h1>

                        <p className="text-gray1 text-base font-normal whitespace-nowrap">
                          {state?.account_type || myDetails?.account_type}
                        </p>
                      </div>{" "}
                      {exportPrintBtn && (
                        <div className="px-10 ">
                          <h1 className="text-sm capitalize text-gray3">
                            Password
                          </h1>
                          <div className="pt-2">
                            <img src={dotdotIcon} alt="" />
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="flex items-center divide-x ">
                      <div className="px-10 ">
                        <h1 className="text-sm capitalize text-gray3">
                          Email Address
                        </h1>

                        <p className="text-blue">
                          {state?.email || myDetails?.email}
                        </p>
                      </div>

                      <div className="px-12 ">
                        <h1 className="text-sm capitalize text-gray3">
                          Phone Number
                        </h1>

                        <p className="text-blue whitespace-nowrap">
                          {state?.phone_number || myDetails?.phone_number}
                        </p>
                      </div>

                      {!exportPrintBtn && (
                        <div className="px-12 ">
                          <h1 className="text-sm capitalize text-gray3">
                            Password
                          </h1>

                          <Button
                            className="flex justify-start p-0 font-bolder underline capitalize cursor-pointer hover:bg-transparent text-orange whitespace-nowrap"
                            disableRipple
                            onClick={handleOpen}
                          >
                            Change Password
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row-span-1 py-3">
					<div className="">
						<h1 className="font-semibold text-gray1 text-xl ">
							Login sessions
						</h1>
						<p className="text-sm text-gray2 font-normal">
							Places where you logged into Drinks NG
						</p>
					</div>
				</div>
				<div className="row-span-1 py-4">
					{LoginSessions.length > 0 && (
						<div className="grid grid-cols-4">
							<h1 className="text-xs uppercase text-gray2 font-semibold pl-5">
								Location
							</h1>

							<h1 className="text-xs uppercase text-gray2 font-semibold">
								device
							</h1>

							<h1 className="text-xs uppercase text-gray2 font-semibold">
								ip address
							</h1>

							<h1 className="text-xs uppercase text-gray2 font-semibold">
								time
							</h1>
						</div>
					)}

					<div className="border divide-y rounded-md bg-white py-4">
						{LoginSessions.length > 0 ? (
							LoginSessions.map((item) => {
								return (
									<div className="grid grid-cols-4 py-2 ">
										<h1 className=" text-sm font-medium capitalize text-gray2 pl-5">
											{item.location}{' '}
										</h1>
										<h1 className="text-sm font-medium capitalize text-gray2">
											{item.device}{' '}
										</h1>
										<h1 className="text-sm font-medium capitalize text-gray2">
											{item.IP}
										</h1>
										<h1 className="text-sm font-medium capitalize text-gray2">
											{item.time}{' '}
										</h1>
									</div>
								);
							})
						) : (
							<div className="h-[332px] flex justify-center bg-white">
								<div className="flex flex-col justify-center items-center ">
									<div className="w-[48px]  text-center  ">
										<img
											src={FileUpload}
											alt="file upload"
											w="100%"
											height="auto"
										/>
									</div>
									<div className="w-[276px]">
										<p className="text-lg font-semibold text-center text-gray2">
											History of all your login sessions will show here
										</p>
									</div>
								</div>
							</div>
						)}
					</div>
				</div> */}
      </div>

      <Dialog open={open}>
        <div className="bg-white divide-y w-96">
          <div className="relative flex justify-center py-2 ">
            <p className="font-bold text-[20px] text-[#040A1D]">
              Reset Password
            </p>

            <IconButton
              className="absolute p-0 top-2 right-5"
              onClick={handleClose}
            >
              <IoClose className="w-7 h-7 text-gray2" />
            </IconButton>
          </div>

          <form className="p-5 space-y-2">
            <div>
              <div className="space-y-5">
                <FormInput
                  id="oldPassword"
                  name="oldPassword"
                  type="password"
                  placeholder="Old Password"
                  label="Old Password"
                  onChange={handleChange}
                  value={reset.oldPassword}
                />
                <FormInput
                  id="newPassword"
                  name="newPassword"
                  type="password"
                  placeholder="New Password"
                  label="New Password"
                  onChange={handleChange}
                  value={reset.newPassword}
                />
              </div>

              <LinearProgress
                variant="determinate"
                value={progress}
                classes={{
                  root: "bg-[#E3E7ED] mt-px",
                  bar: `${
                    strength === "Weak"
                      ? " bg-red"
                      : strength === "Good"
                      ? "bg-appBlue"
                      : strength === "Strong"
                      ? "bg-[#00cf26]"
                      : "bg-[#E3E7ED]"
                  } `,
                }}
              />

              <div className="flex items-center justify-center text-xs text-gray-300">
                Password strength:
                <span className="pl-[5px] font-semibold text-gray1">
                  {strength}
                </span>
              </div>
            </div>

            <ul className="text-[12px] text-gray2  w-[80%]">
              <li className="flex space-x-1">
                <span>&#8226;</span>
                <p>Use at least 8 characters.</p>
              </li>
              <li className="flex space-x-1">
                <span>&#8226;</span>
                <p className="">
                  Besides letters, include at least a number or symbol
                  (!@#$%^&*-_=+).
                </p>
              </li>
              <li className="flex space-x-1">
                <span>&#8226;</span>
                <p>Password is case sensitive.</p>
              </li>
            </ul>

            <FormInput
              id="confirmNewPassword"
              name="confirmNewPassword"
              type="password"
              placeholder="Confirm Password"
              label="Confirm Password"
              onChange={handleChange}
              value={reset.confirmNewPassword}
              error={error}
            />

            {error && <ErrorAlert error={error} />}

            <div className="flex items-center justify-end py-2 space-x-3">
              <Button
                variant="outlined"
                className="capitalize shadow-none"
                onClick={handleClose}
              >
                Cancel
              </Button>

              <Button
                variant="contained"
                onClick={() => {
                  handlePasswordChange();
                  handleClose();
                }}
                className={`text-white capitalize shadow-none ${
                  reset.newPassword === "" ||
                  reset.confirmNewPassword === "" ||
                  reset.newPassword.length < 8 ||
                  strength === "Weak" ||
                  (reset.newPassword !== "" &&
                    reset.confirmNewPassword !== "" &&
                    reset.confirmNewPassword !== reset.newPassword)
                    ? "bg-orange/30 cursor-not-allowed"
                    : "bg-orange cursor-pointer"
                }`}
                // disabled={disabled}
                disabled={
                  reset.newPassword === "" ||
                  reset.confirmNewPassword === "" ||
                  reset.newPassword.length < 8 ||
                  strength === "Weak" ||
                  (reset.newPassword !== "" &&
                    reset.confirmNewPassword !== "" &&
                    reset.confirmNewPassword !== reset.newPassword)
                }
              >
                Continue
              </Button>
            </div>
          </form>
        </div>
      </Dialog>
    </>
  );
}
