import { useState } from 'react'
import { useSelector } from 'react-redux';

import API from '../../appRedux/api/root'

const TemplateFile = () => {
	const [generating, setGenerating] = useState(false)

	async function generateTemplate() {
		setGenerating(true)

		let products = []
		let columns = []
		try {
			const { data } = await API().get('/bulk-file-upload-csv-column-titles')
			columns = data.column_titles

			let next = '/product/?page_size=500'
			do {
				const res = await API().get(next)
				next = res.data.next
				products = products.concat(res.data.results)
			} while (next)

		} catch(e) {}

		products = products.reduce((output, item) => {
			item.attribute.forEach(attr => {
				output.push({
					name: item.name,
					attribute: attr.name,
					category: (item.category || []).map(c => c.name || '').join(', '),
					brand: (item.brand || []).map(b => b.name || '').join(', '),
				})
			})
			if (!item.attribute.length) {
				output.push({ name: item.name, attribute: '' })
			}
			return output
		}, [])

		const getColumnIndexOf = (name) => columns.findIndex(
			title => title.toLowerCase() === name
		)

		const productColumn = getColumnIndexOf('product')
		const attrColumn = getColumnIndexOf('attribute')
		const catColumn = getColumnIndexOf('category')
		const brandColumn = getColumnIndexOf('brand')

		// reformat data to be csv-friendly
		const rows = products.map(item => {
			const row = new Array(columns.length).fill().map(() => '')
			row[productColumn] = `"${item.name}"`
			row[attrColumn] = `"${item.attribute}"`
			row[catColumn] = `"${item.category}"`
			row[brandColumn] = `"${item.brand}"`
			return row.join(',')
		})

		const filename = `Orders upload template.csv`
		const file = new File(
			[[columns.join(','), ...rows].join('\n')],
			filename,
			{ type: 'text/csv' }
		)

		const a = document.createElement('a')
		a.href = (window.URL || window.webkitURL).createObjectURL(file)
		a.download = filename
		a.click()

		setGenerating(false)
	}

	return (
		<div className=" py-5 mt-6 w-[599px] mx-auto bg-[#477DFB1A] ">
			<div className="flex justify-center space-x-3 ">
				<div className="text-[14px]">
					Which columns does my csv file need to have?
				</div>
				<button
					className="text-appBlue underline font-semibold text-[14px]"
					onClick={ generateTemplate }
				>
					{ generating ? 'Generating template...' : 'Download file sample' }
				</button>
			</div>
		</div>
	);
};

export default TemplateFile;
