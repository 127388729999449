import React from 'react';
import { Route, Switch,  } from "react-router-dom";

import Locations from "./index";
import LocationDetails from './location-details';
import MoreLocationDetails from './moredetails';

const LocationRoute = ({ match }) => {
    return (
        <Switch>
            <Route path={`${match.url}/details`} component={LocationDetails} />
            <Route path={`${match.url}/more`} component={MoreLocationDetails} />
            <Route path={`${match.url}/`} component={Locations} />
        </Switch>
    )
}



export default LocationRoute;