import React, { useEffect } from 'react';
import { Pie } from 'react-chartjs-2';

const PieChart = ({ pieChartData }) => {
	// console.log("pie chart data", pieChartData);

	const extractRevenues = (dataArg) => dataArg.map(({ revenue }) => revenue);
	const extractLabel = (dataArg) => dataArg.map(({ label }) => label);

	let revenue = extractRevenues(pieChartData);
	let label = extractLabel(pieChartData);
	// const staticData = [6, 9, 3, 2, 5];
	// const staticLabel = [
	// 	'Johnny Walker (Black)',
	// 	'Johnny Walker ',
	// 	'Moet',
	// 	'Azul',
	// 	'Hennessy',
	// ];
	// console.log("label", label);
	// console.log("reve", revenue);
	const data = {
		labels: label,
		datasets: [
			{
				data: revenue,
				backgroundColor: [
					'rgba(71, 125, 251, 1)',
					'rgba(255, 46, 91, 1)',
					'rgba(251, 136, 50, 1)',
					'rgba(56, 201, 133, 1)',
					'rgba(156, 167, 184, 1)',
				],
				borderColor: [
					'rgba(71, 125, 251, 1)',
					'rgba(255, 46, 91, 1)',
					'rgba(251, 136, 50, 1)',
					'rgba(56, 201, 133, 1)',
					'rgba(156, 167, 184, 1)',
				],
				borderWidth: 1,
			},
		],
	};
	const chartOptions = {
		radius: '60%',
		legend: {
			display: false,
			position: 'left',
		},
		title: {
			display: true,
		},
		tooltips: {
			displayColors: false,
			backgroundColor: '#fff',
			titleFontFamily: 'IBM Plex Sans',
			titleFontColor: 'rgba(242, 58, 95, 1)',
			titleFontSize: 14,
			bodyFontColor: '#9CA7B8',
			bodyFontFamily: 'IBM Plex Sans',
			borderWidth: 2,
			borderColor: ' rgba(227, 231, 237, 1)',
			bodyFontSize: 14,
			footerFontColor: '#040A1D',
			footerFontFamily: 'IBM Plex Sans',
			footerFontSize: 14,
			callbacks: {
				title: function (item, everything) {
					// // console.log('item', item);
					// // console.log('hakdajhd', everything);
					// // console.log('otther', everything.datasets[0].data[item[0].index]);

					let total = everything.datasets[0].data.reduce(function (a, b) {
						return Number(a) + Number(b);
					}, 0);

					// console.log("total", total);

					let text =
						Math.round(
							(everything.datasets[0].data[item[0].index] / total) * 100
						) + '%';
					return text;
				},
				label: function (item, everything) {
					// // console.log('itemmdmdmdm is ', item);
					// // console.log('everything is ', everything);
					// // console.log('label', everything.labels[item.index]);
					return everything.labels[item.index];
				},
				footer: function (item, everything) {
					const price = everything.datasets[0].data[item[0].index];
					return '₦' + price;
				},
			},
		},
	};
	return (
		<>
			<Pie options={chartOptions} data={data} />
		</>
	);
};

export default PieChart;
