import React, { useState } from 'react';
// import { IoChevronDownOutline } from 'react-icons/io5';
import { useHistory } from 'react-router-dom';
import dropDownIcon from '../../assets/dropDownIcon.png';
import { useDispatch } from 'react-redux';

//mui
import {
	IconButton,
	Popover,
	Avatar,
	Button,
	makeStyles,
} from '@material-ui/core';
import { signOutUser } from '../../appRedux/actions/auth';
import { useSelector } from 'react-redux';
import FallBackImage from '../../assets/drinkslogoIcon.svg';

const DropdownMenu = ({ outsideOpen }) => {
	const { myDetails } = useSelector(({ auth }) => auth);

	const history = useHistory();
	const dispatch = useDispatch();

	const logoutHandler = () => {
		handleClose();
		dispatch(signOutUser());
		sessionStorage.clear()
	};

	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (e) => {
		setAnchorEl(e.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	return (
		<div>
			<div onClick={handleClick} className="flex items-center space-x-2">
				<div className="flex align-middle items-center  ">
					<Avatar
						src={myDetails?.profile?.picture || FallBackImage}
						alt="profile"
					/>
				</div>

				<div className="flex align-middle items-center  space-x-1  ">
					{myDetails?.profile.first_name === '' &&
					myDetails?.profile.last_name === '' ? (
						<p className="font-medium font-medium text-gray-400">
							{myDetails?.account_type}
						</p>
					) : (
						<>
							<h4 className=" font-medium text-gray-400">
								{myDetails?.profile?.first_name}
							</h4>
							<h4 className=" font-medium text-gray-400">
								{myDetails?.profile?.last_name}
							</h4>
						</>
					)}
				</div>
				<IconButton
					className="p-0 hover:bg-transparent focus:bg-transparent pl-2"
					onClick={handleClick}
					disableRipple
					disableFocusRipple
				>
					<img src={dropDownIcon} alt="drop down" width="auto" />
				</IconButton>
			</div>

			<Popover
				open={outsideOpen || open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				classes={{
					root: '',
					paper: 'bg-transparent shadow-none pt-4 pb-5',
				}}
			>
				<div className="w-[250px] rounded-md bg-white p-4">
					<div className="flex items-center space-x-2">
						<Avatar
							className="w-8 h-8"
							src={myDetails?.profile?.picture || FallBackImage}
						/>

						<div>
							<div className="flex space-x-2">
								{myDetails?.profile.first_name === '' &&
								myDetails?.profile.last_name === '' ? (
									<p className="font-medium text-gray-400">
										{myDetails?.account_type}
									</p>
								) : (
									<>
										<div className="font-medium text-gray1">
											{myDetails?.profile?.first_name}
										</div>
										<div className="font-medium text-gray1">
											{myDetails?.profile?.last_name}
										</div>
									</>
								)}
							</div>
							<p className="text-[12px] text-gray2">{myDetails?.email}</p>
						</div>
					</div>

					<div className="py-2">
						<Button
							className="flex justify-start w-full p-2 text-base font-medium capitalize rounded-md cursor-pointer hover:bg-orange/10 hover:text-orange text-gray1"
							onClick={() => {
								history.push('/profile');
								handleClose();
							}}
						>
							Profile
						</Button>

						<Button
							className="flex justify-start w-full p-2 text-base font-medium capitalize rounded-md cursor-pointer hover:bg-orange/10 hover:text-orange text-gray1"
							onClick={() => logoutHandler()}
						>
							Sign Out
						</Button>
					</div>
				</div>
			</Popover>
		</div>
	);
};

export default DropdownMenu;
