import React, { useState } from 'react';
import { Menu, MenuItem } from '@material-ui/core';

const FormSelect = ({ options, value, handleChange, label, disabled }) => {
	const [anchorEl, setAnchorEl] = useState(null);

	const onItemPress = (value) => {
		setAnchorEl(null);
		handleChange(value);
	};

	return (
		<div className="relative flex items-end bg-[#F4F5F7] rounded-md w-full h-14">
			<input
				id="select"
				name={`${label} select`}
				className="w-full h-10 pl-4 font-medium placeholder-transparent transition duration-500 ease-in-out bg-transparent border-b-2 border-transparent text-gray1 caret-blue focus:border-blue peer focus:outline-none"
				placeholder={label}
				value={value}
				onFocus={(e) => setAnchorEl(e.target)}
				autoComplete={false}
			/>
			<label
				htmlFor={'select'}
				className={`absolute left-4 top-[5px] text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray2 peer-placeholder-shown:top-4 peer-focus:top-[5px] peer-focus:text-blue peer-focus:text-sm`}
			>
				{label}
			</label>
			<Menu
				id="select"
				anchorEl={anchorEl}
				keepMounted
				open={disabled ? false : Boolean(anchorEl)}
				onClose={() => setAnchorEl(null)}
				elevation={3}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				PaperProps={{
					style: {
						width: '300px',
						maxHeight: '501px',
						marginTop: '0px',
						padding: '0px 10px 0px 10px',
					},
				}}
				disableEnforceFocus
				disableRestoreFocus
				disableAutoFocus
				disableAutoFocusItem
			>
				{options.map((item, index) => (
					<MenuItem value={item.label} onClick={() => onItemPress(item.label)}>
						{item.label}
					</MenuItem>
				))}
			</Menu>
		</div>
	);
};

export default FormSelect;
