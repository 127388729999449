import React, { useEffect } from 'react';

function ShowCountryFilter({ markerData, state }) {
	const [loaded, setLoaded] = React.useState(false);

	// console.log('state', state);
	useEffect(() => {
		let map = new window.mapboxgl.Map({
			minZoom: 2,
			container: 'map',
			style: process.env.REACT_APP_COUNTRIES_MAP_STYLE,
			accessToken: process.env.REACT_APP_MAP_TOKEN,
			renderWorldCopies: false,
		});
		map.on('load', () => {
			document.querySelector('#input');
			setLoaded(true);
		});

		map.on('load', () => {
			if (markerData) {
				highlist(markerData);
			}
		});
		map.on('style.load', () => {
			const waiting = () => {
				if (!map.isStyleLoaded()) {
					setTimeout(waiting, 500);
				} else {
					loaded && highlist(markerData);
				}
			};
			waiting();
		});
		map.on('style.load', () => {
			const waiting = () => {
				if (map.isStyleLoaded()) {
					highlightState('Greater Accra Region');
				}
			};
			waiting();
		});

		async function highlist(e) {
			await map.setFilter('state', ['==', ['get', 'admin'], e]);
			map.on('resize', () => {});
			map.on('style.load', 'circle', (zz) => {
				map.flyTo({
					center: zz.features[0].geometry.coordinates,
				});
			});
			map.on('load', 'circle', (zz) => {
				map.flyTo({
					center: zz.features[0].geometry.coordinates,
				});
			});
		}

		async function highlightState(e) {
			await map.setFilter('states-boundaries', [
				'==',
				['get', 'name_en', 'gn_name', 'gns_name', 'name'],
				e,
			]);
			map.on('resize', () => {});
			map.on('style.load', 'circle', (zz) => {
				map.flyTo({
					center: zz.features[0].geometry.coordinates,
				});
			});
			map.on('load', 'circle', (zz) => {
				map.flyTo({
					center: zz.features[0].geometry.coordinates,
				});
			});
		}
		return () => {
			map.remove();
		};
	}, [markerData, loaded, state]);

	return (
		<div style={{ display: 'flex', justifyContent: 'center' }}>
			<div
				id="map"
				style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
			></div>
		</div>
	);
}
export default ShowCountryFilter;
