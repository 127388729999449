import {
	all,
	call,
	fork,
	put,
	takeLatest,
	takeEvery,
} from 'redux-saga/effects';

import {
	successGetBrandAnalytics,
	successGetLocationSalesBreakdown,
	successGetTopBrands,
	successGetTopLocations,
	successGetTopProducts,
	successLocationFilter,
	successTopBrandDataList,
	successTopProductDataList,
	successCountryList,
	successCategories,
	successSubCategories,
} from '../actions/brands';
import {
	REQUEST_GET_BRAND_ANALYTICS,
	REQUEST_GET_LOCATION_SALES_BREAKDOWN,
	REQUEST_GET_TOP_BRANDS,
	REQUEST_GET_TOP_LOCATIONS,
	REQUEST_GET_TOP_PRODUCTS,
	REQUEST_LOCATION_FILTERS,
	REQUEST_GET_TOP_BRANDS_LIST,
	REQUEST_GET_TOP_PRODUCTS_LIST,
	REQUEST_COUNTRY_LIST,
	REQUEST_PRODUCT_CATEGORY,
	REQUEST_PRODUCT_SUBCATEGORY,
} from '../actions/constants';
import {
	AnalyticOverall,
	GetProductSaleBreakDown,
	GetTopBrands,
	GetTopLocation,
	GetTopProducts,
	LocationFilters,
	GetTopBrandsDataList,
	GetTopProductsDataList,
	GetCountryList,
	GetProductCategories,
	GetProductSubCategories,
} from '../api/brands';

import { showErrAlert, showLoader } from '../actions/common';

function* GetProductSubCategoriesHandler(id) {
	let res = yield call(
		GetProductSubCategories,
		sessionStorage.getItem('token'),
		id
	);
	console.log('sub categories', res);

	if (res.status === 200) {
		yield put(successSubCategories({ productSubCategories: res?.data }));
		yield put(showLoader(false));
	} else {
		showErrAlert(res.error);
		yield put(showLoader(false));
	}
}

function* GetProductCategoriesHandler({ payload }) {
	let res = yield call(
		GetProductCategories,
		sessionStorage.getItem('token'),
		payload
	);
	if (res.status === 200) {
		yield put(successCategories({ productCategories: res?.data?.results }));
		yield put(showLoader(false));
	} else {
		showErrAlert(res.error);
		yield put(showLoader(false));
	}
}

function* GetTopProductsDataListHandler({ payload }) {
	let res = yield call(
		GetTopProductsDataList,
		sessionStorage.getItem('token'),
		payload
	);
	// console.log('res from location filters', res);
	if (res.status === 200) {
		yield put(
			successTopProductDataList({ topProductDataList: res?.data?.results })
		);
		yield put(showLoader(false));
	} else {
		showErrAlert(res.error);
		yield put(showLoader(false));
	}
}
function* GetTopBrandsDataListHandler({ payload }) {
	let res = yield call(
		GetTopBrandsDataList,
		sessionStorage.getItem('token'),
		payload
	);
	// console.log('res from location filters', res);
	if (res.status === 200) {
		yield put(
			successTopBrandDataList({ topBrandDataList: res?.data?.results })
		);
		yield put(showLoader(false));
	} else {
		showErrAlert(res.error);
		yield put(showLoader(false));
	}
}
function* GetLocationFilters({ payload }) {
	let res = yield call(
		LocationFilters,
		sessionStorage.getItem('token'),
		payload
	);
	console.log('res from location filters', res);
	if (res.status === 200) {
		yield put(successLocationFilter({ locationFilters: res?.data?.results }));
		yield put(showLoader(false));
	} else {
		showErrAlert(res.error);
		yield put(showLoader(false));
	}
}
function* GetTopBrandsHandler({ payload }) {
	// // console.log(payload);
	let res = yield call(GetTopBrands, sessionStorage.getItem('token'), payload);
	// console.log('this is top brands', res);
	if (res.status === 200) {
		yield put(
			successGetTopBrands({
				topBrandLists: res?.data.results,
				brandsCount: res?.data.count,
			})
		);
		// console.log('data is', res?.data.results);
		yield put(showLoader(false));
	} else {
		showErrAlert(res.error);
		yield put(showLoader(false));
	}
}

function* GetAnalyticsHandler({ payload }) {
	// console.log('get that b', payload);
	let res = yield call(
		AnalyticOverall,
		sessionStorage.getItem('token'),
		payload
	);
	// console.log('this is brands', res);
	if (res.status === 200) {
		yield put(
			successGetBrandAnalytics({
				analyticList: res?.data,
			})
		);
		// // console.log("data is", res?.data);
		yield put(showLoader(false));
	} else {
		showErrAlert(res.error);
		yield put(showLoader(false));
	}
}

function* GetTopProductsHandler({ payload }) {
	let res = yield call(
		GetTopProducts,
		sessionStorage.getItem('token'),
		payload
	);
	if (res.status === 200) {
		// // console.log(res);
		yield put(
			successGetTopProducts({
				topProductLists: res?.data.results,
				productsCount: res?.data.count,
			})
		);
		// // console.log("data is", res?.data.results);
		yield put(showLoader(false));
	} else {
		showErrAlert(res.error);
		yield put(showLoader(false));
	}
}

function* GetTopLocationsListHandler({ payload }) {
	let res = yield call(
		GetCountryList,
		sessionStorage.getItem('token'),
		payload
	);
	if (res.status === 200) {
		// console.log(res);
		yield put(
			successCountryList({
				locationDataList: res?.data.results,
				// count: res?.data.count,
				// next: res?.data.next,
				// previous: res?.data.previous,
			})
		);
		// // console.log("data is", res?.data.results);
		yield put(showLoader(false));
	} else {
		showErrAlert(res.error);
		yield put(showLoader(false));
	}
}
function* GetTopLocationsHandler({ payload }) {
	let res = yield call(
		GetTopLocation,
		sessionStorage.getItem('token'),
		payload
	);
	if (res.status === 200) {
		// console.log(res);
		yield put(
			successGetTopLocations({
				topLocationLists: res?.data.results,
				count: res?.data.count,
				next: res?.data.next,
				previous: res?.data.previous,
			})
		);
		// // console.log("data is", res?.data.results);
		yield put(showLoader(false));
	} else {
		showErrAlert(res.error);
		yield put(showLoader(false));
	}
}
function* GetLocationSalesBreakdownHandler({ payload }) {
	let res = yield call(
		GetProductSaleBreakDown,
		sessionStorage.getItem('token'),
		payload
	);
	if (res.status === 200) {
		// console.log(res);
		yield put(
			successGetLocationSalesBreakdown({
				locationSalesBreakdownLists: res?.data.results,
				locationBreakdownCount: res?.data.count,
			})
		);
		// // console.log("data is", res?.data.results);
		yield put(showLoader(false));
	} else {
		showErrAlert(res.error);
		yield put(showLoader(false));
	}
}

export function* ActionWatchers() {
	yield takeEvery(REQUEST_GET_BRAND_ANALYTICS, GetAnalyticsHandler);
	yield takeEvery(REQUEST_GET_TOP_BRANDS, GetTopBrandsHandler);
	yield takeEvery(REQUEST_GET_TOP_PRODUCTS, GetTopProductsHandler);
	yield takeEvery(REQUEST_GET_TOP_LOCATIONS, GetTopLocationsHandler);
	yield takeEvery(REQUEST_COUNTRY_LIST, GetTopLocationsListHandler);
	yield takeEvery(
		REQUEST_GET_LOCATION_SALES_BREAKDOWN,
		GetLocationSalesBreakdownHandler
	);
	yield takeEvery(REQUEST_LOCATION_FILTERS, GetLocationFilters);
	yield takeEvery(REQUEST_GET_TOP_BRANDS_LIST, GetTopBrandsDataListHandler);
	yield takeEvery(REQUEST_GET_TOP_PRODUCTS_LIST, GetTopProductsDataListHandler);
	yield takeEvery(REQUEST_PRODUCT_CATEGORY, GetProductCategoriesHandler);
	yield takeEvery(REQUEST_PRODUCT_SUBCATEGORY, GetProductSubCategoriesHandler);
}

export default function* rootSaga() {
	yield all([fork(ActionWatchers)]);
}
