import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

//utils
import validateEmail from '../../utils/validateEmail';

//mui
import { Switch, FormControlLabel } from '@material-ui/core';

//components
import AuthCard from '../../components/AuthCard/AuthCard';
import FormInput from '../../components/FormInput/FormInput';
import CustomButton from '../../components/CustomButton/CustomButton';
import ErrorAlert from '../../components/ErrorAlert/ErrorAlert';
import { signInUser, signInUserSuccess } from '../../appRedux/actions/auth';

import { useDispatch, useSelector } from 'react-redux';
import { showErrAlert, showLoader } from '../../appRedux/actions/common';
const Index = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { token, verifyURL } = useSelector(({ auth }) => auth);
	const { loader, errMessage } = useSelector(({ common }) => common);
	const [credentials, setCredentials] = useState({
		email: '',
		password: '',
	});

	const [oneWeek, setOneWeek] = useState(false);

	useEffect(() => {
		if (localStorage.getItem('stayforweek') && localStorage.getItem('token')) {
			dispatch(signInUserSuccess({ token: localStorage.getItem('token') }));
		}
	}, []);
	useEffect(() => {
		if (token !== null) {
			history.push('/');
		}
		if (verifyURL?.type === 'verify-login') {
			history.push(verifyURL?.pathname);
		}
	});

	const handleChange = (e) => {
		const { name, value } = e.target;
		setCredentials({
			...credentials,

			[name]: value,
		});
	};
	// const isEmailValid = validateEmail(credentials.email);
	const submit = () => {
		dispatch(showLoader(true));
		dispatch(showErrAlert(''));
		// if (!isEmailValid) {
		// 	dispatch(showErrAlert('Invalid email address'));
		// 	dispatch(showLoader(false));
		// 	return;
		// }
		dispatch(signInUser(credentials));
		// history.push(`/login/${credentials.email}/verify-identity`);
	};

	const ToggleSignIn = () => {
		if (!oneWeek) {
			localStorage.setItem('stayforweek', 'true');
		} else {
			localStorage.removeItem('stayforweek');
		}
		setOneWeek(!oneWeek);
	};

	return (
		<AuthCard>
			<div className="flex flex-col items-center space-y-1">
				<h1 className="text-2xl font-bold text-center">
					Sign in to your account
				</h1>

				<p className="leading-none text-center text-gray2 w-[60%] text-sm">
					Enter your email address and password to get access to your account
				</p>
			</div>

			<div className="w-[70%]">
				<form className="space-y-2">
					{errMessage && <ErrorAlert error={errMessage} />}
					<FormInput
						id="email"
						name="email"
						type="email"
						placeholder="Email Address"
						label="Email"
						onChange={handleChange}
						value={credentials.email}
						error={errMessage}
					/>
					<FormInput
						id="password"
						name="password"
						type="password"
						placeholder="Password"
						label="Password"
						onChange={handleChange}
						value={credentials.password}
						error={errMessage}
					/>
				</form>

				<div className="flex items-center justify-between mt-2">
					<FormControlLabel
						control={
							<Switch
								size="small"
								checked={oneWeek}
								onChange={() => ToggleSignIn()}
								color="primary"
								classes={{
									thumb: 'bg-white',
								}}
							/>
						}
						label="Stay signed in for a week"
						classes={{
							label: 'font-medium text-gray3 text-[12px]',
						}}
					/>

					<Link
						to="/reset-password"
						className="text-[12px] underline text-blue"
					>
						Forgot Password?
					</Link>
				</div>

				<CustomButton
					text="Continue"
					onClick={submit}
					disabled={
						// !isEmailValid ||
						credentials.email === '' ||
						credentials.password === ''
					}
					loading={loader}
				/>

				{/* <div className="flex items-center justify-center mt-3">
					<span className="text-[12px] text-gray-400">
						Don't have an account?{' '}
						<Link to="/login" className="font-bold underline text-blue">
							Sign up
						</Link>
					</span>
				</div> */}
			</div>
		</AuthCard>
	);
};

export default Index;
