import React from 'react';
import { numberFormat } from '../../utils'

import { Avatar, Button } from '@material-ui/core';
export const BrandInfoCard = ({
	name,
	amount,
	growthPercent,
	totalsold,
	GotoDetails,
}) => {
	console.log('growth  percente', growthPercent);
	return (
		<div
			className="flex justify-between w-full py-3 hover:bg-opacity-5 hover:bg-[#F3641F]  border-b border-thinGrayBG items-center"
			onClick={GotoDetails}
		>
			<div className="flex items-center space-x-2 ">
				<div>
					<h1 className="text-sm font-medium text-gray2">{name}</h1>
					<p className="font-medium  text-appGray text-xs">
						{numberFormat(totalsold).toDecimal()} products sold
					</p>
				</div>
			</div>
			<div>
				<div className="text-sm font-bold text-[#040A1D]">
					{numberFormat(amount).toCurrency()}
				</div>

				<p className="font-semibold text-orange text-right text-sm">
					{growthPercent}
				</p>
			</div>
		</div>
	);
};
