import React from 'react';

import ProductsTop from '../productATop';
import SaleOverviewMiddle from './SaleOverviewMiddle';

export default function ProductSaleOverview({
	activePeriod,
	setActivePeriod,
	pdf,
	greenUpArrow,
	filter,
	today,
	month,
	week,
	title,
	handleDateRangeSelect,
	handleDayFilter,
	topdataLists,
	filterItem,
}) {
	return (
		<div className=" mt-5 bg-white border-b-1 border-appGray p-4">
			<ProductsTop
				today={today}
				week={week}
				month={month}
				activePeriod={activePeriod}
				setActivePeriod={setActivePeriod}
				pdf={pdf}
				title={title}
				handleDateRangeSelect={handleDateRangeSelect}
				handleDayFilter={handleDayFilter}
			/>
			<SaleOverviewMiddle
				greenUpArrow={greenUpArrow}
				filter={filter}
				topdataLists={topdataLists}
				filterItem={filterItem}
			/>
		</div>
	);
}
