import PageTitle from '../PageTitle/pageTitle';
import UploadFile from './uploadFile';
import TemplateFile from './templatFileRow';
import FileHistory from './fileUploadHistory';
const FileUploadComponents = () => {
	return (
		<div className="overflow-hidden ">
			<div className="px-10 ">
				<div className="mx-auto w-[950px] ">
					{/* <PageTitle title="File Upload" className="px-10 mx-auto w-[950px]" /> */}
					<h1 className="text-appBlack font-bold py-6 text-[30px]">
						File Upload
					</h1>
				</div>
			</div>

			<UploadFile />
			<TemplateFile />
			<FileHistory />
		</div>
	);
};

export default FileUploadComponents;
