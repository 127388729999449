import React, { useState, useEffect } from 'react';
import ProductsTop from '../../components/productsComponent/productATop';
import PageTitle from '../../components/PageTitle/pageTitle';
import pdf from '../../assets/pdf.svg';
import filter from '../../assets/filter.svg';
import collapse from '../../assets/collapse.svg';
import searchIcon from '../../assets/search.svg';
import FilterData from './filter';

import greenUpArrow from '../../assets/greenUpArrow.png';
import { Line } from 'react-chartjs-2';
import Pagination from '@material-ui/lab/Pagination';
import MyMap from '../../components/Map/Index';

import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { useLocation } from 'react-router-dom';
import { BrandInfoCard } from './card';
import InfoCard from './tablex';
import SearchComponent from './search';
import OverviewComponent from './overview';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { requestMyDetails } from '../../appRedux/actions/auth';
import { numberFormat } from '../../utils'

import {
	requestGetTopLocations,
	requestTopBrandDataList,
	requestCountryList,
	requestGetLocationSalesBreakdown,
} from '../../appRedux/actions/brands';
import ShowCountryFilter from '../../components/Map/ShowCountryFilter';

function LocationDetails() {
	const location = useLocation();
	const dispatch = useDispatch();
	const [activePeriod, setActivePeriod] = useState('today');
	const {
		topLocationLists,

		topBrandDataList,
		locationDataList,
		locationSalesBreakdownLists,
	} = useSelector(({ brands }) => brands);

	const [chartData, setChartData] = useState(null);

	const [expanded, setExpanded] = React.useState('panel1');

	const handleChange = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};
	const [filterCompetitors, setFilterCompetitors] = useState(true);
	const filterByCompetitors = () => {
		setFilterCompetitors(!filterCompetitors);
	};
	const handleDayFilter = (day) => {
		dispatch(requestGetTopLocations({ day_filter: day?.toLowerCase() }));
	};
	const handleBrandFilter = (day) => {
		dispatch(requestTopBrandDataList({ day_filter: day?.toLowerCase() }));
	};
	const handleLocationSearch = (e) => {
		dispatch(requestTopBrandDataList({ searchTerm: e }));
	};
	const handleDateRangeSelect = (range) => {
		dispatch(
			range.to === null
				? requestTopBrandDataList({
						before_data: `${range.from.year}-${range.from.month}-${range.from.day}`,
						after_date: `${range.from.year}-${range.from.month}-${range.from.day}`,
				  })
				: requestTopBrandDataList({
						before_data: `${range.from.year}-${range.from.month}-${range.from.day}`,
						after_date: `${range.to.year}-${range.to.month}-${range.to.day}`,
				  })
		);
	};
	const handleLocationRangeSelect = (range) => {
		dispatch(
			range.to === null
				? requestGetTopLocations({
						before_data: `${range.from.year}-${range.from.month}-${range.from.day}`,
						after_date: `${range.from.year}-${range.from.month}-${range.from.day}`,
				  })
				: requestGetTopLocations({
						before_data: `${range.from.year}-${range.from.month}-${range.from.day}`,
						after_date: `${range.to.year}-${range.to.month}-${range.to.day}`,
				  })
		);
	};
	const [selectedState, setSelectedState] = useState({});
	useEffect(() => {
		dispatch(requestMyDetails({}));

		dispatch(requestTopBrandDataList({ day_filter: 'month' }));
	}, []);
	useEffect(() => {
		requestCountryList({ country: location.state.country });
	}, [filterCompetitors]);

	const handleStateFilter = (filterTerm) => {
		dispatch(requestTopBrandDataList({ city: filterTerm }));
	};
	const handleCountryFilter = (filterTerm) => {
		dispatch(requestTopBrandDataList({ country: filterTerm }));
	};

	const handleLocationCountryFilter = (filterTerm) => {
		dispatch(requestGetTopLocations({ country: filterTerm }));
	};
	const handleLocationStateFilter = (filterTerm) => {
		dispatch(requestGetTopLocations({ city: filterTerm }));
	};
	return (
		<div className="overflow-hidden">
			<PageTitle title="Locations" />

			<div class="grid grid-cols-1 gap-7 pb-5 pl-6 pr-6 ">
				<div className=" mt-5 bg-white border-b-1 border-appGray p-4">
					<div className="border-b border-thinGrayBG">
						<ProductsTop
							today={true}
							week={true}
							month={true}
							activePeriod={activePeriod}
							setActivePeriod={setActivePeriod}
							pdf={pdf}
							title="Location Analytics"
							excelBtn={true}
							filterByCompetitors={filterByCompetitors}
							handleDayFilter={handleDayFilter}
							handleDateRangeSelect={handleLocationRangeSelect}
							exportData={
								selectedState.city === undefined
									? locationDataList?.map(({ uuid, ...item }) => item)
									: locationSalesBreakdownLists
							}
						/>
					</div>
					<div className=" flex justify-end border-t-1 border-appGray w-full py-3  bg-white ">
						<div className="w-[65%] bg-white">
							<span className="text-blue bg-[#ECF2FE] rounded-md px-1">
								{location?.state?.country}
							</span>
							{selectedState?.city ? (
								<ShowCountryFilter
									markerData={location?.state?.country}
									state={selectedState.city}
								/>
							) : (
								<MyMap markerData={location?.state?.country} />
							)}
						</div>

						<div className={`w-[50%]  justify-evenly flex  `}>
							<div
								className={` mr-6 mx-3 pt-2 ${
									selectedState.city !== undefined ? 'w-1/2' : 'w-full'
								}`}
							>
								<div>
									<p className="text-[#3D4356] border-b border-thinGrayBG font-medium text-base">
										States
									</p>
								</div>
								{locationDataList?.map((item) => (
									<div
										onClick={() => setSelectedState(item)}
										className={`${
											selectedState.city === item.city
												? 'border-l-4 border-appRed px-2'
												: ''
										}`}
									>
										<BrandInfoCard
											name={item.city}
											amount={item.revenue}
											growthPercent={`${RoundToWholeNumber(
												FindPercentage(
													totalAmt(locationDataList),
													item?.revenue
												)
											)}%`}
											totalsold={item.quantity_sold}
											GotoDetails={() =>
												dispatch(
													requestGetLocationSalesBreakdown({ city: item.city })
												)
											}
										/>
									</div>
								))}
							</div>
							<div
								className={`${
									selectedState.city !== undefined ? 'w-[50%]' : 'hidden'
								}`}
							>
								<div className="flex w-full justify-between pt-2 pl-3 ">
									<div>
										<p className="text-[#3D4356]  font-medium text-base">
											{selectedState?.city} State
										</p>
									</div>
									<div>
										<p className="text-appGray font-medium text-sm">
											{selectedState.quantity_sold} Products Sold
										</p>
									</div>
								</div>

								{locationSalesBreakdownLists?.map((item, idx) => (
									<MuiAccordion
										key={idx}
										square
										expanded={expanded === idx}
										onChange={handleChange(idx)}
										className="shadow-none"
									>
										<MuiAccordionSummary
											expandIcon={<img src={collapse} alt="" />}
											aria-controls="panel1d-content"
											id="panel1d-header"
											className="shadow-none"
										>
											<div className="flex w-full justify-between hover:bg-opacity-5 hover:bg-[#F3641F]">
												<div className="flex items-center space-x-2">
													<div>
														<h1 className="text-sm font-medium text-appBlack">
															{item.label}
														</h1>
														<p className="font-medium text-appGray text-xs ">
															{item.total_quantity} products sold
														</p>
													</div>
												</div>
												<div>
													<div className="text-sm font-bold text-[#040A1D]">
														{numberFormat(item.revenue).toCurrency()}
													</div>
													<p className="font-medium text-orange text-sm">
														{`${RoundToWholeNumber(
															FindPercentage(
																totalAmt(locationSalesBreakdownLists),
																item?.revenue
															)
														)}%`}
													</p>
												</div>
											</div>
										</MuiAccordionSummary>
										<MuiAccordionDetails className="bg-[#F4F5F7]">
											<div className="flex flex-col w-full divide-y divider-#EEF2F5  pt-0 ">
												{item.breakdown?.map((list) => (
													<div className="grid grid-cols-5  py-2 ">
														<div className="col-span-4">
															<div>
																<h1 className="text-sm font-normal text-orange italic">
																	{list.attribute_name}
																</h1>
																<p className="font-normal text-xs text-appGray italic ">
																	{list.quantity_sold} products sold
																</p>
															</div>
														</div>
														<div className="">
															<p className="font-medium text-sm text-orange ">
																{`${RoundToWholeNumber(
																	FindPercentage(
																		ACCtotalAmt(item.breakdown),
																		list?.total_revenue
																	)
																)}%`}{' '}
															</p>
														</div>
													</div>
												))}
											</div>
										</MuiAccordionDetails>
									</MuiAccordion>
								))}
							</div>
						</div>
					</div>
				</div>
			</div>
			<OverviewComponent
				today={true}
				week={true}
				month={true}
				FilterData={FilterData}
				ProductsTop={ProductsTop}
				activePeriod={activePeriod}
				setActivePeriod={setActivePeriod}
				pdf={pdf}
				filter={filter}
				greenUpArrow={greenUpArrow}
				topdataLists={_.orderBy(topLocationLists, ['label'], ['asc'])}
				Line={Line}
				chartData={chartData}
				handleDayFilter={handleDayFilter}
				handleDateRangeSelect={handleLocationRangeSelect}
				handleStateFilter={handleLocationStateFilter}
				handleCountryFilter={handleLocationCountryFilter}
			/>
			<SearchComponent
				today={true}
				week={true}
				month={true}
				filtered={true}
				competitors={true}
				countryLists={
					filterCompetitors === true
						? topBrandDataList.filter((o) => o.label === 'Competitor')
						: topBrandDataList.filter((o) => o.label !== 'Competitor')
				}
				searchIcon={searchIcon}
				pdf={pdf}
				setActivePeriod={setActivePeriod}
				activePeriod={activePeriod}
				ProductsTop={ProductsTop}
				Pagination={Pagination}
				InfoCard={InfoCard}
				checked={filterCompetitors}
				checklabel="With Competitors"
				toptitle="Location Breakdown"
				searchPlaceholder="Search Brand"
				headers={['Brands', 'Quantity Sold', 'Amount', 'Percentage']}
				brandsTable
				filterByCompetitors={filterByCompetitors}
				handleDayFilter={handleBrandFilter}
				handleDateRangeSelect={handleDateRangeSelect}
				handleLocationSearch={handleLocationSearch}
				handleStateFilter={handleStateFilter}
				handleCountryFilter={handleCountryFilter}
			/>
		</div>
	);
}
const totalAmt = (details) =>
	details?.reduce((a, c) => a + parseFloat(c?.revenue), 0);
const ACCtotalAmt = (details) =>
	details?.reduce((a, c) => a + parseFloat(c?.total_revenue), 0);
const FindPercentage = (totalAmt, amt) => (amt / totalAmt) * 100;
const RoundTo2Decimals = (num) =>
	Math.round((num + Number.EPSILON) * 1000) / 1000;
const RoundToWholeNumber = (num) => Math.round(num);
const countryState = [
	{
		id: 1,
		title: 'Borno State',
		amt: '₦3,559',
		sold: '500',
		percentage: '5%',
		bgColor: 'bg-white',
		products: [
			{
				id: 1,
				title: 'Red',
				amt: '₦3,559',
				sold: '500',
				percentage: '5%',
				bgColor: 'bg-white',
			},
			{
				id: 2,
				title: 'Black',
				amt: '₦3,559',
				sold: '500',
				percentage: '5%',
				bgColor: 'bg-white',
			},
			{
				id: 3,
				title: 'Green',
				amt: '₦3,559',
				sold: '500',
				percentage: '5%',
				bgColor: 'bg-white',
			},
			{
				id: 4,
				title: 'Black Anniversary Edition',
				amt: '₦3,559',
				sold: '500',
				percentage: '5%',
				bgColor: 'bg-white',
			},
		],
	},
	{
		id: 2,
		title: 'Abia State',
		amt: '₦3,559',
		sold: '500',
		percentage: '5%',
		bgColor: 'bg-white',
		products: [
			{
				id: 1,
				title: 'Red',
				amt: '₦3,559',
				sold: '500',
				percentage: '5%',
				bgColor: 'bg-white',
			},
			{
				id: 2,
				title: 'Black',
				amt: '₦3,559',
				sold: '500',
				percentage: '5%',
				bgColor: 'bg-white',
			},
			{
				id: 3,
				title: 'Green',
				amt: '₦3,559',
				sold: '500',
				percentage: '5%',
				bgColor: 'bg-white',
			},
			{
				id: 4,
				title: 'Black Anniversary Edition',
				amt: '₦3,559',
				sold: '500',
				percentage: '5%',
				bgColor: 'bg-white',
			},
		],
	},
	{
		id: 3,
		title: 'Akwa Ibom State',
		amt: '₦3,559',
		sold: '500',
		percentage: '5%',
		bgColor: 'bg-white',
		products: [
			{
				id: 1,
				title: 'Red',
				amt: '₦3,559',
				sold: '500',
				percentage: '5%',
				bgColor: 'bg-white',
			},
			{
				id: 2,
				title: 'Black',
				amt: '₦3,559',
				sold: '500',
				percentage: '5%',
				bgColor: 'bg-white',
			},
			{
				id: 3,
				title: 'Green',
				amt: '₦3,559',
				sold: '500',
				percentage: '5%',
				bgColor: 'bg-white',
			},
			{
				id: 4,
				title: 'Black Anniversary Edition',
				amt: '₦3,559',
				sold: '500',
				percentage: '5%',
				bgColor: 'bg-white',
			},
		],
	},
	{
		id: 4,
		title: 'Niger State',
		amt: '₦3,559',
		sold: '500',
		percentage: '5%',
		bgColor: 'bg-white',
		products: [
			{
				id: 1,
				title: 'Red',
				amt: '₦3,559',
				sold: '500',
				percentage: '5%',
				bgColor: 'bg-white',
			},
			{
				id: 2,
				title: 'Black',
				amt: '₦3,559',
				sold: '500',
				percentage: '5%',
				bgColor: 'bg-white',
			},
			{
				id: 3,
				title: 'Green',
				amt: '₦3,559',
				sold: '500',
				percentage: '5%',
				bgColor: 'bg-white',
			},
			{
				id: 4,
				title: 'Black Anniversary Edition',
				amt: '₦3,559',
				sold: '500',
				percentage: '5%',
				bgColor: 'bg-white',
			},
		],
	},
];

export default LocationDetails;
