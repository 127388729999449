import React from 'react';
import { useRouteMatch } from "react-router-dom";

import App from "./pages"
import Sidebar from './components/SideBar/SideBar';
import Topbar from './components/Topbar/Topbar';

const MainApp = () => {
	const match = useRouteMatch();
	return (
		<div className="lg:flex">
			<Sidebar />
			<main className="min-h-screen lg:flex-1 bg-[#EEF2F5]">
				<Topbar />
				<div className="overflow-auto">
					<App match={match} />
				</div>
			</main>
		</div>
	);
};

export default MainApp;
