import React, { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import moment from 'moment';

const LineChartCard = ({ topdataLists }) => {
	topdataLists?.sort(function compare(a, b) {
		var dateA = new Date(a.date);
		var dateB = new Date(b.date);
		return dateA - dateB;
	});
	const [chartData, setChartData] = useState(null);

	const compareHours = (hour, tickValue, tickIndex) => {
		const tickHour = parseInt(tickValue.toString().split(':')[0]);
		if (tickIndex === 0 && hour === 0) return true;
		else if (tickIndex <= 12 && tickHour === hour) return true;
		else if (tickIndex > 12 && tickHour + 12 === hour) return true;
		return false;
	};
	const tickCallback = (value, index, values) => {
		const date = new Date();
		const hour = date.getHours();
		if (compareHours(hour, value, index)) return `Now, ${value}`;
		else if (value === '12:00am') return '12:00am';
		else if (value === '11:59pm') return '11:59pm';
		return null;
	};
	const chartOptions = {
		tooltips: {
			displayColors: false,
			backgroundColor: '#fff',
			titleFontFamily: 'IBM Plex Sans',
			titleFontColor: 'rgba(242, 58, 95, 1)',
			titleFontSize: 14,
			bodyFontColor: 'rgba(4, 10, 29, 1)',
			bodyFontFamily: 'IBM Plex Sans',
			bodyFontStyle: 'bold',
			borderWidth: 2,
			borderColor: ' rgba(227, 231, 237, 1)',
			bodyFontSize: 14,
			footerFontColor: 'rgba(71, 125, 251, 1)',
			footerFontFamily: 'IBM Plex Sans',
			footerFontSize: 14,
			footerFontStyle: 'normal',
			callbacks: {
				title: function (item, everything) {
					// console.log('item', item);
					// console.log('everything', everything);
				},
				footer: function (item, everything) {
					return item[0].label;
				},
				label: function (item, everything) {
					// console.log('label item', item);
					const price = '₦' + item.value;
					return price;
				},
			},
		},
		aspectRatio: 1,
		maintainAspectRatio: false,
		scales: {
			yAxes: [
				{
					display: true,
					gridLines: {
						display: true,
						drawBorder: false,
						color: 'white',
						zeroLineColor: '#ff4127',
					},
					ticks: {
						callback: tickCallback,
						fontColor: 'white',
					},
				},
			],

			x: {
				type: 'time',
				time: {
					// Luxon format string
					tooltipFormat: '11 HH:mm',
					unit: 'day',
					displayFormats: {
						day: 'MM/DD/YY',
					},
				},
				title: {
					display: true,
					text: 'Date',
				},
			},
		},
		legend: {
			display: false,
		},
		elements: {
			point: {
				radius: 3,
			},
		},
	};

	const initialiseChart = () => {
		let chart = document.getElementById('chart-finance').getContext('2d'),
			gradient = chart.createLinearGradient(0, 0, 0, 450);
		gradient.addColorStop(0, 'rgba(243, 100, 31, 0.2)');
		gradient.addColorStop(1, 'rgba(243, 100, 31, 0)');

		const chartData = {
			// labels: getTodaysChart(topdataLists, timeKeyVals),
			labels: topdataLists?.map((item) =>
				moment(item?.date).format('DD/MM/YY').toString()
			),

			datasets: [
				{
					label: 'Sales Value',
					data: topdataLists?.map((item) => parseFloat(item.revenue)),
					fill: true,
					backgroundColor: gradient,
					borderColor: ['#F3641F'],
					borderWidth: 2,
					tension: 0,
					pointStyle: 'dot',
					pointRadius: 5,
					pointBorderColor: 'rgb(243,100,31)',
					lineAtIndex: 2,
				},
			],
		};
		setChartData(chartData);
	};
	useEffect(() => {
		initialiseChart();
	}, [topdataLists]);
	return (
		<Line
			data={chartData}
			options={chartOptions}
			id="chart-finance"
			className="chart-canvas"
			height={null}
			width={null}
		/>
	);
};

export default LineChartCard;
