import React, { useState, useEffect } from 'react';

import { useSelector } from 'react-redux';
import _ from 'lodash';
import LineChartCard from '../../Card/LineChartCard';
import { numberFormat } from '../../../utils'

export default function SaleOverviewMiddle({
	greenUpArrow,
	filter,
	chartData,
	chartOptions,
	topdataLists,
}) {
	const { analyticList } = useSelector(({ brands }) => brands);
	const [filterQuery, setFilterQuery] = useState('');

	useEffect(() => {
		if (topdataLists.length && !filterQuery) {
			setFilterQuery(topdataLists[0]?.label)
		}
	}, [topdataLists])

	return (
		<div className=" flex  align-bottom	 max-w-[70vw]">
			{topdataLists.length > 0 && (
				<div className="flex items-end">
					<div className="px-3 pb-6 mb-1 bg-white rounded-md ">
						<h1 className="text-[12px] text-gray3 capitalize">
							All items sold
						</h1>
						<div className="border-b-2 border-[#EEF2F5] pb-2 text-[20px] font-semibold">
							{ numberFormat(analyticList?.quantity_sold || 0).toDecimal() }
							<br/>
							{ numberFormat(analyticList?.revenue || 0).toCurrency() }
						</div>
					</div>
				</div>
			)}

			<div className=" justify-end flex-grow bg-white ">
				<div className=" flex justify-end pt-4 items-end">
					<div className=" flex  align-middle items-center  text-gray3  border border-[#E3E7ED] rounded-md ">
						<img
							src={filter}
							alt=""
							width="auto"
							height="auto"
							className="mx-3"
						/>
						<label className=" font-medium text-xs pr-2">Filter by:</label>

						<select
							onChange={(e) => setFilterQuery(e.target.value)}
							className="shadow-none border-none focus:border-none font-medium text-gray2 text-sm "
						>
							{/*<option value="">All</option>*/}

							{_.uniqBy(topdataLists, 'label')?.map((item) => (
								<option value={item.label}>{item.label}</option>
							))}
						</select>
					</div>
				</div>
				<div className="max-h-[375px] pt-2 pb-2">
					{topdataLists.length > 0 ? (
						<LineChartCard
							topdataLists={
								filterQuery === ''
									? topdataLists
									: topdataLists.filter((i) => i.label === filterQuery)
							}
						/>
					) : (
						<div className=" flex h-full items-center justify-center text-[20px] text-gray3 font-semibold pb-5">
							Sorry, no data at the moment. All data will be shown here
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
