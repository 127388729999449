import React from "react";

export default function OtherProducts({ otherBrands, GrayEllipse, title }) {
  // console.log('other brands', otherBrands);
  return (
    <div className="pt-5 pb-3">
      <div className="">
        <h4 className="text-gray2 text-base font-medium">{title}</h4>
      </div>
      <div className="flex flex-wrap ">
        {/* //items come in here */}
        {otherBrands?.map((item, key) => (
          <div className=" flex  bg-[#F4F5F7] m-1 p-2 rounded-md" key={key}>
            <div className="flex items-center">
              <img src={GrayEllipse} alt="" width="auto" height="auto" />
              <p className="pl-2 text-gray2 text-sm font-medium ">
                {item?.label}
              </p>
            </div>
            <p className="font-medium text-appBlack text-sm pl-5">
              {RoundToWholeNumber(
                FindPercentage(totalAmt(otherBrands), item?.revenue)
              )}
              %
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

const totalAmt = (details) =>
  details?.reduce((a, c) => a + parseFloat(c?.revenue), 0);
const FindPercentage = (totalAmt, amt) =>
  totalAmt === 0 ? (amt / 1) * 100 : (amt / totalAmt) * 100;
const RoundTo2Decimals = (num) =>
  Math.round((num + Number.EPSILON) * 1000) / 1000;
const RoundToWholeNumber = (num) => Math.round(num);
