import React, { useEffect, useState } from "react";
import { IoArrowBack, IoArrowUp } from "react-icons/io5";
import { Button, Dialog, ButtonGroup } from "@material-ui/core";

import { ToastContainer } from "react-toastify";
import { useHistory, useLocation } from "react-router-dom";

import FormInput from "../../components/FormInput/FormInput";
import PageTitle from "../../components/PageTitle/pageTitle";
import FormSelect from "../../components/FormSelect/FormSelect";
//components
import usersInactive from "../../assets/users-inactive.svg";
import loaderIcon from "../../assets/loader.svg";
import success from "../../assets/success.svg";
import Edit from "../../assets/edit.svg";
import Delete from "../../assets/delete.svg";

import { useDispatch, useSelector } from "react-redux";
import { requestUpdateUserEmailPhone } from "../../appRedux/actions/auth";
import { showModal } from "../../appRedux/actions/common";

const UpdateUser = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const userInfo = location?.state;
  const { loader, modal } = useSelector(({ common }) => common);
  const [open, setOpen] = useState(false);
  const [disabled] = useState(false);
  // console.log(userInfo);
  const [state, setState] = useState({
    uuid: "",
    userid: "",
    first_name: "",
    last_name: "",
    gender: "",
    phone_number: "",
    email: "",
    image: "",
    account_type: "",
    picture: "",
  });

  useEffect(() => {
    setState({
      ...state,
      first_name: userInfo?.profile?.first_name,
      last_name: userInfo?.profile?.last_name,
      gender: userInfo?.profile?.gender,
      picture: userInfo?.profile?.picture,
      email: userInfo?.email,
      uuid: userInfo?.profile?.uuid,
      userid: userInfo?.uuid,
      phone_number: userInfo?.phone_number,
      account_type: userInfo?.account_type,
    });
  }, [userInfo]);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    // setOpen(false);
    dispatch(showModal());
  };

  const SaveHandler = () => {
    const data = {
      uuid: state.uuid,
      userid: state.userid,

      emailPhone: {
        email: state.email,
        phone_number: state.phone_number,
      },
      personal: {
        picture: state.image,
        first_name: state.first_name,
        last_name: state.last_name,
        gender: state.gender,
      },
    };
    dispatch(showModal());
    dispatch(requestUpdateUserEmailPhone(data));
  };

  return (
    <div className="flex flex-col mx-auto  py-5 w-[730px] space-y-3 ">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="mx-auto w-[100%]">
        <div className="flex justify-start items-center space-x-1">
          <div className="flex items-center justify-center w-10 h-10 rounded-full border-2 border-black">
            <IoArrowBack
              onClick={() => history.goBack()}
              className="w-6 h-6 font-medium text-black"
            />
          </div>
          <PageTitle title="Update User Info" />
        </div>
        <div className="">
          <div className=" grid">
            <div className="row-span-1 w-full  p-4 bg-white ">
              <h4 className="text-cardHeading text-base font-medium pt-2 pb-4">
                Personal Details
              </h4>
              <div className="grid grid-cols-2 gap-4">
                <FormInput
                  type="text"
                  label="First Name"
                  name="first_name"
                  placeholder="First Name"
                  value={state?.first_name}
                  onChange={(e) =>
                    setState({ ...state, first_name: e.target.value })
                  }
                />
                <FormInput
                  type="text"
                  label="Last Name"
                  placeholder="Last Name"
                  name="last_name"
                  value={state?.last_name}
                  onChange={(e) =>
                    setState({ ...state, last_name: e.target.value })
                  }
                />
                <FormInput
                  type="email"
                  label="Email"
                  placeholder="Email"
                  name="email"
                  value={state?.email}
                  onChange={(e) =>
                    setState({ ...state, email: e.target.value })
                  }
                />
                <FormInput
                  type="tel"
                  label="Phone Number"
                  placeholder="Phone Number"
                  name="phone_number"
                  value={state?.phone_number}
                  onChange={(e) =>
                    setState({ ...state, phone_number: e.target.value })
                  }
                />

                <FormSelect
                  disabled={true}
                  options={AccountTypeList}
                  label={"Account Type"}
                  value={state?.account_type}
                />
                <FormSelect
                  options={Gender}
                  label={"Gender"}
                  value={state?.gender}
                  handleChange={(e) =>
                    setState({ ...state, gender: e.toLocaleLowerCase() })
                  }
                />
              </div>
            </div>
            <div className="mt-5"></div>
            <div className="row-span-1 w-full  p-4 bg-white ">
              <h4 className="text-base font-medium text-cardHeading">
                Profile Photo
              </h4>
              <div className="grid grid-cols-[2fr,1fr] gap-5">
                {state.image ? (
                  <div className="w-60">
                    <div className="relative border border-gray-300 border-dashed rounded-md">
                      <div className="absolute left-0 flex justify-center w-full bottom-10">
                        <ButtonGroup className="bg-white">
                          <Button
                            onClick={() => {
                              setState({ ...state, image: "" });
                            }}
                          >
                            <img src={Delete} alt="" />
                          </Button>
                          <Button>
                            <label
                              htmlFor="profile-image"
                              className="w-full h-full cursor-pointer"
                            >
                              <img src={Edit} alt="" />
                              <input
                                type="file"
                                id="profile-image"
                                multiple
                                accept=".png,.jpeg,.jpg"
                                onChange={(e) => {
                                  setState({
                                    ...state,
                                    image: e.target.files[0],
                                  });
                                }}
                                className="hidden"
                              />
                            </label>
                          </Button>
                        </ButtonGroup>
                      </div>

                      <img
                        src={URL.createObjectURL(state.image)}
                        alt=""
                        className="object-cover w-full h-full rounded-md"
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    className="flex flex-col items-center justify-center space-y-2 border border-gray-300 border-dashed rounded-md group h-52 w-60"
                    onDragOver={(e) => e.preventDefault()}
                    onDragEnter={(e) => e.preventDefault()}
                    onDragLeave={(e) => e.preventDefault()}
                    onDrop={(e) => {
                      e.preventDefault();
                      setState({ ...state, image: e.dataTransfer.files[0] });
                    }}
                  >
                    <div className="flex items-center justify-center w-10 h-10 rounded-full bg-gray3">
                      <IoArrowUp className="w-6 h-6 font-medium text-gray2" />
                    </div>
                    <label
                      htmlFor="profile-image"
                      className="border border-[#E3E7ED] rounded-md cursor-pointer px-3 py-2"
                    >
                      <p>Upload File</p>
                      <input
                        type="file"
                        id="profile-image"
                        multiple
                        accept=".png,.jpeg,.jpg"
                        onChange={(e) => {
                          setState({ ...state, image: e.target.files[0] });
                        }}
                        className="hidden"
                      />
                    </label>

                    <p className="text-sm text-gray2">
                      or drop files to upload
                    </p>
                  </div>
                )}
                <div>
                  Current Photo
                  <div className="w-60">
                    <div className="relative border border-gray-300 border-dashed h-250px rounded-md">
                      <img
                        src={state.picture}
                        alt=""
                        className="object-cover w-[100%]  rounded-md"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row-span-1 w-full  p-4  ">
                <div className="flex flex-row items-center justify-end mt-[30px]">
                  <button
                    className="w-[83px] h-[43px] bg-transparent rounded-md text-[#4C536A] text-[16px] font-bold border border-[#E3E7ED] mr-[20px]"
                    onClick={() => history.goBack()}
                  >
                    Cancel
                  </button>
                  <Button
                    variant="contained"
                    className={`text-white capitalize shadow-none ${
                      disabled
                        ? "bg-orange/30 cursor-not-allowed"
                        : "bg-orange cursor-pointer"
                    }`}
                    onClick={() => SaveHandler()}
                    // disabled={disabled}
                  >
                    Save
                  </Button>
                </div>
              </div>
              <Dialog open={modal}>
                <div className="flex flex-col items-center py-5 space-y-3 w-96 bg-[#FAFAFA]">
                  {loader ? (
                    <>
                      <img src={usersInactive} alt="" className="w-9 h-9" />
                      <p className="font-medium capitalize text-gray2">
                        Updating User
                      </p>
                      <img src={loaderIcon} alt="" className="animate-spin" />
                    </>
                  ) : (
                    <>
                      <span className="flex items-center justify-center w-16 h-16 rounded-full bg-green/20">
                        <img src={success} alt="" className="" />
                      </span>

                      <p className="font-medium capitalize text-gray2 w-[38%] text-center">
                        User Updated Successfully
                      </p>

                      <div className="space-x-3">
                        <Button
                          variant="contained"
                          color="primary"
                          className="capitalize shadow-none"
                          onClick={() => {
                            handleClose();
                            history.push("/users");
                          }}
                        >
                          Done
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </Dialog>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const AccountTypeList = [
  { value: "Retailer", label: "Retailer" },
  { value: "Customer", label: "Customer" },
  { value: "Drinks Sales Person", label: "Drinks Sales Person" },
  { value: "Brands Analytics User", label: "Brands Analytics User" },
];
const Gender = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "other", label: "Other" },
];

export default UpdateUser;
