import React from 'react';

import searchIcon from '../../assets/search.svg';
import excelIcon from '../../assets/excel.svg';
import exportFromJSON from 'export-from-json';

const TableActions = ({ activeTab, changeTab, onSearchChange, exportData }) => {
	const data = exportData;
	const fileName = 'DrinksNG Users';
	const exportType = exportFromJSON.types.csv;
	return (
		<div className="bg-white rounded-md w-full h-[61px] flex flex-row items-center px-[25px] mt-4 justify-between">
			{/* tabs */}
			<div className="flex flex-row items-center h-full">
				<span
					onClick={() => changeTab('all')}
					className={
						activeTab === 'all'
							? 'text-[#040A1D] font-medium text-[16px] border-b-4 border-[#F3641F] h-full mr-[30px] pt-5 cursor-pointer'
							: 'font-medium text-[#9CA7B8] text-[16px] mr-[30px] cursor-pointer'
					}
				>
					All
				</span>
			</div>
			{/* search and export */}
			<div className="flex flex-row items-center">
				<span className="flex flex-row items-center bg-[#F4F5F7] rounded-md pl-3 focus-within:border-b focus-within:border-appBlue">
					<img src={searchIcon} alt="search icon" />
					<input
						onChange={onSearchChange}
						placeholder="Search Users"
						className="bg-transparent font-medium text-[14px] w-full h-[34px] 2xl:w-[657px] xl:w-[550px] lg:w-[380px] pl-3 focus:outline-none"
					/>
				</span>
				<button
					onClick={() => {
						exportFromJSON({ data, fileName, exportType });
					}}
					className="bg-transparent rounded-md border border-[#E9ECEF] px-[10px] py-[5px] text-[12px] text-[#9CA7B8] flex flex-row items-center ml-3"
				>
					Export
					<img
						src={excelIcon}
						alt="search"
						className="w-[24px] h-[24px] ml-2"
					/>
				</button>
			</div>
		</div>
	);
};

export default TableActions;
