import { utils } from 'react-modern-calendar-datepicker';
import { SHOW_ERR_ALERT, SHOW_LOADER, SHOW_MODAL, SET_GLOBAL_DATE } from "../actions/constants";

const today = new Date()
const INIT_STATE = {
  errMessage: "",
  loader: false,
  modal: false,
  globalDate: {
    period: 'Today',
    range: {
      from: utils().getToday(),
      to: utils().getToday()
    }
  }
};

const common = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SHOW_ERR_ALERT: {
      return {
        ...state,
        errMessage: action.payload,
      };
    }
    case SHOW_MODAL: {
      return {
        ...state,
        modal: !state.modal,
      };
    }
    case SHOW_LOADER: {
      return {
        ...state,
        loader: action.payload,
      };
    }
    case SET_GLOBAL_DATE: {
      return {
        ...state,
        globalDate: {
          ...state.globalDate,
          ...action.payload,
        }
      }
    }
    default:
      return state;
  }
};
export default common;
