import React, { useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import { Button, Dialog, ButtonGroup } from '@material-ui/core';
//components
import usersInactive from '../../assets/users-inactive.svg';
import loaderIcon from '../../assets/loader.svg';
import success from '../../assets/success.svg';
import { IoArrowUp, IoArrowBack } from 'react-icons/io5';

import Edit from '../../assets/edit.svg';
import Delete from '../../assets/delete.svg';
import FormSelect from '../../components/FormSelect/FormSelect';
import FormInput from '../../components/FormInput/FormInput';
import PageTitle from '../../components/PageTitle/pageTitle';
import { useDispatch, useSelector } from 'react-redux';
import { showLoader } from '../../appRedux/actions/common';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API, { API_URL } from '../../appRedux/api/root';
const AddNewUser = ({ update }) => {
	const token = sessionStorage.getItem('token');
	const history = useHistory();
	const dispatch = useDispatch();
	const location = useLocation();
	const { loader } = useSelector(({ common }) => common);
	const [loading, setLoading] = useState(false);

	const [open, setOpen] = useState(false);
	const [disabled] = useState(false);
	const [image, setImage] = useState('');
	const [accType, setAccType] = useState('');
	const [gender, setGender] = useState('');
	const [state, setState] = useState({
		email: '',
		first_name: '',
		last_name: '',
		account_type: '',
		password: '',
		re_password: '',
		phone: '',
	});

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = (value) => {
		setOpen(false);
	};
	// console.log('error', error);
	const SaveHandler = () => {
		setLoading(false);
		if (state.re_password !== state.password) {
			return toast.error('Passwords do not match');
		} else if (gender === '') {
			return toast.error('Please choose a gender');
		} else if (accType === '') {
			return toast.error('Please choose an account type');
		}
		const data = {
			phone_number: state.phone,
			email: state.email,
			password: state.password,
			account_type: accType,
		};

		const profile = {
			first_name: state.first_name,
			last_name: state.last_name,
			gender: gender,
			picture: image,
		};
		const formdata = new FormData();

		Object.entries(profile).map((item) => formdata.append(item[0], item[1]));
		dispatch(showLoader(true));
		handleClickOpen();
		if (formdata.get('gender' === null) || formdata.get('gender' === '')) {
			return toast.error('Please select a gender');
		} else {
			API()
				.post(`${API_URL}/users/dashboard-add-user/`, data, {
					headers: { Authorization: 'Token ' + token },
				})
				.then((res) => {
					console.log('res', res);
					res.status === 201 &&
						API()
							.patch(`${API_URL}/profile/${res?.data?.uuid}/`, profile, {
								headers: {
									Authorization: 'Token ' + token,
									'content-type': 'multipart/form-data',
								},
							})
							.then((res) => console.log(res))
							.catch((error) => console.log(error));
					setLoading(true);
					dispatch(showLoader(false));
				})
				.catch((error) => {
					dispatch(showLoader(false));
					setLoading(false);
					if (error.response) {
						console.log(error.response.data);
						if (error.response.data?.email) {
							toast.error('Please check the email ');
						} else if (error.response.data?.account_type) {
							toast.error('Please choose a valid account type');
						} else if (error.response.data?.phone_number) {
							toast.error('Phone number already taken');
						}
						// console.log(error.response.status);
						// console.log(error.response.headers);
					}
				});
		}
	};
	return (
		<div className="flex flex-col mx-auto  py-5 w-[730px] space-y-3 ">
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
			<div className="mx-auto w-[100%]">
				<div className="flex justify-start items-center space-x-1">
					<div className="flex items-center justify-center w-10 h-10 rounded-full border-2 border-black">
						<IoArrowBack
							onClick={() => history.goBack()}
							className="w-6 h-6 font-medium text-black"
						/>
					</div>
					{update ? (
						<PageTitle title="Update User Info" />
					) : (
						<PageTitle title="Add New User" />
					)}
				</div>
				<div className="">
					<div className=" grid">
						<div className="row-span-1 w-full  p-4 bg-white ">
							<h4 className="text-cardHeading text-base font-medium pt-2 pb-4">
								Personal Details
							</h4>

							<form className="grid grid-cols-2 gap-4" autoComplete="off">
								<FormInput
									type="text"
									label="First Name"
									placeholder="First Name"
									value={
										state.first_name ||
										location?.state?.userdetails?.profile?.first_name
									}
									onChange={(e) =>
										setState({ ...state, first_name: e.target.value })
									}
								/>
								<FormInput
									type="text"
									label="Last Name"
									placeholder="Last Name"
									value={
										state.last_name ||
										location?.state?.userdetails?.profile?.last_name
									}
									onChange={(e) =>
										setState({ ...state, last_name: e.target.value })
									}
								/>
								<FormInput
									name="email"
									type="email"
									placeholder="Email Address"
									label="Email"
									value={state.email || location?.state?.userdetails?.email}
									onChange={(e) =>
										setState({ ...state, email: e.target.value })
									}
								/>
								<FormInput
									type="tel"
									label="Phone Number"
									placeholder="Phone Number"
									// pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
									value={
										state.phone || location?.state?.userdetails?.phone_number
									}
									onChange={(e) =>
										setState({ ...state, phone: e.target.value })
									}
								/>
								<FormInput
									type="password"
									placeholder="Password"
									label="Password"
									value={state.password}
									onChange={(e) =>
										setState({ ...state, password: e.target.value })
									}
								/>
								<FormInput
									type="password"
									placeholder="Re-type Password"
									label="Re-type Password"
									value={state.re_password}
									onChange={(e) =>
										setState({ ...state, re_password: e.target.value })
									}
								/>
								<FormSelect
									options={AccountTypeList}
									label={'Account Type'}
									value={accType || location?.state?.userdetails?.account_type}
									handleChange={(e) => {
										setAccType(e);
									}}
								/>
								<FormSelect
									options={Gender}
									label={'Gender'}
									value={
										gender || location?.state?.userdetails?.profile?.gender
									}
									handleChange={(e) => setGender(e)}
								/>
							</form>
						</div>
						<div className="mt-5"></div>
						<div className="row-span-1 w-full  p-4 bg-white ">
							<h4 className="text-base font-medium text-cardHeading">
								Profile Photo
							</h4>
							<div className="grid grid-cols-[2fr,1fr] gap-5">
								<div className="grid grid-cols-[2fr,1fr] gap-5">
									{image && (
										<div className="w-60">
											<div className="relative border border-gray-300 border-dashed rounded-md">
												<div className="absolute left-0 flex justify-center w-full bottom-10">
													<ButtonGroup className="bg-white">
														<Button
															onClick={() => {
																setImage('');
															}}
														>
															<img src={Delete} alt="" />
														</Button>
														<Button>
															<label
																htmlFor="profile-image"
																className="w-full h-full cursor-pointer"
															>
																<img src={Edit} alt="" />
																<input
																	type="file"
																	id="profile-image"
																	multiple
																	accept=".png,.jpeg,.jpg"
																	onChange={(e) => {
																		setImage(e.target.files[0]);
																	}}
																	className="hidden"
																/>
															</label>
														</Button>
													</ButtonGroup>
												</div>

												<img
													src={URL.createObjectURL(image)}
													alt=""
													className="object-cover w-full h-full rounded-md"
												/>
											</div>
										</div>
									)}

									{!image && (
										<div
											className="flex flex-col items-center justify-center space-y-2 border border-gray-300 border-dashed rounded-md group h-52 w-60"
											onDragOver={(e) => e.preventDefault()}
											onDragEnter={(e) => e.preventDefault()}
											onDragLeave={(e) => e.preventDefault()}
											onDrop={(e) => {
												e.preventDefault();
												setImage(e.dataTransfer.files[0]);
											}}
										>
											<div className="flex items-center justify-center w-10 h-10 rounded-full bg-gray3">
												<IoArrowUp className="w-6 h-6 font-medium text-gray2" />
											</div>
											<label
												htmlFor="profile-image"
												className="border border-[#E3E7ED] rounded-md cursor-pointer px-3 py-2"
											>
												<p>Upload File</p>
												<input
													type="file"
													id="profile-image"
													multiple
													accept=".png,.jpeg,.jpg"
													onChange={(e) => {
														setImage(e.target.files[0]);
													}}
													className="hidden"
												/>
											</label>

											<p className="text-sm text-gray2">
												or drop files to upload
											</p>
										</div>
									)}
								</div>
							</div>
						</div>{' '}
						<div className="row-span-1 w-full  p-4  ">
							<div className="flex flex-row items-center justify-end mt-[30px]">
								<button
									className="w-[83px] h-[43px] bg-transparent rounded-md text-[#4C536A] text-[16px] font-bold border border-[#E3E7ED] mr-[20px]"
									onClick={() => history.goBack()}
								>
									Cancel
								</button>
								<Button
									variant="contained"
									className={`text-white capitalize shadow-none ${
										disabled
											? 'bg-orange/30 cursor-not-allowed'
											: 'bg-orange cursor-pointer'
									}`}
									onClick={SaveHandler}
									disabled={disabled}
								>
									Save
								</Button>
							</div>
						</div>
						<Dialog open={open}>
							<div className="flex flex-col items-center py-5 space-y-3 w-96 bg-[#FAFAFA]">
								{loader ? (
									<>
										<img src={usersInactive} alt="" className="w-9 h-9" />
										<p className="font-medium capitalize text-gray2">
											Saving new user
										</p>
										<img src={loaderIcon} alt="" className="animate-spin" />
									</>
								) : loading ? (
									<>
										<span className="flex items-center justify-center w-16 h-16 rounded-full bg-green/20">
											<img src={success} alt="" className="" />
										</span>

										<p className="font-medium capitalize text-gray2 w-[38%] text-center">
											New user Added Successfully
										</p>

										<div className="space-x-3">
											<Button
												variant="contained"
												className="capitalize bg-white border shadow-none"
												onClick={() => {
													handleClose();
												}}
											>
												Add Another User
											</Button>

											<Button
												variant="contained"
												color="primary"
												className="capitalize shadow-none"
												onClick={() => {
													handleClose();
													history.push('/users');
												}}
											>
												Done
											</Button>
										</div>
									</>
								) : (
									<>
										<p className="font-medium capitalize text-gray2 whitespace-nowrap ">
											Sorry that was unsuccessful{' '}
										</p>
										<p className="font-medium capitalize text-gray2 w-[38%] text-center">
											Please try again{' '}
										</p>
										<div className="space-x-3">
											<Button
												variant="contained"
												className="capitalize bg-white border shadow-none"
												onClick={() => {
													handleClose();
												}}
											>
												Add Another User
											</Button>

											<Button
												variant="contained"
												color="primary"
												className="capitalize shadow-none"
												onClick={() => {
													handleClose();
													history.push('/users');
												}}
											>
												Done
											</Button>
										</div>
									</>
								)}
							</div>
						</Dialog>
					</div>
				</div>
			</div>
		</div>
	);
};

const AccountTypeList = [
	{ value: 'Drinks Admin', label: 'Drinks Admin' },
	{ value: 'Brands Analytics Admin', label: 'Brands Analytics Admin' },
	{ value: 'Brands Analytics User', label: 'Brands Analytics User' },
];
const Gender = [
	{ value: 'male', label: 'Male' },
	{ value: 'female', label: 'Female' },
	{ value: 'other', label: 'Other' },
];
export default AddNewUser;
