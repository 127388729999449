import React from 'react';
import { useSelector } from 'react-redux';
import { numberFormat } from '../../utils'
import LineChartCard from '../../components/Card/LineChartCard';

function OverviewComponent({
	FilterData,
	today,
	month,
	week,
	ProductsTop,
	activePeriod,
	setActivePeriod,
	pdf,
	filter,

	handleDateRangeSelect,
	handleDayFilter,
	topdataLists,
	handleStateFilter,
	handleCountryFilter,
}) {
	const { analyticList } = useSelector(({ brands }) => brands);
	return (
		<div class="grid grid-cols-1 gap-7 pb-5 pl-6 pr-6 ">
			<div className=" mt-5 bg-white border-b-1 border-appGray p-4">
				<ProductsTop
					today={today}
					week={week}
					month={month}
					activePeriod={activePeriod}
					setActivePeriod={setActivePeriod}
					pdf={pdf}
					title="Revenue Overview"
					handleDateRangeSelect={handleDateRangeSelect}
					handleDayFilter={handleDayFilter}
					handleStateFilter={handleStateFilter}
					handleCountryFilter={handleCountryFilter}
				/>
				<FilterData filter={filter} handleStateFilter={handleStateFilter}
				handleCountryFilter={handleCountryFilter} />
				<div className="px-3"></div>
				<div className=" flex 	px-3">
					{topdataLists.length > 0 && (
						<div className="flex items-start">
							<div className="px-3 pb-6 mb-1 bg-white rounded-md ">
								<h1 className="text-[12px] text-gray3 capitalize">
									All items sold
								</h1>
								<div className="border-b-2 border-[#EEF2F5]">
									<p className="text-[20px] font-semibold">
										{numberFormat(analyticList?.quantity_sold || 0).toDecimal()}
									</p>
									<div className="flex items-center pb-2 border-b-1 border-appGray text-[20px] font-semibold">
										{ numberFormat(analyticList?.revenue || 0).toCurrency() }
									</div>
								</div>
							</div>
						</div>
					)}
					<div className=" justify-end flex-grow bg-white ">
						<div className="h-[275px]">
							{topdataLists.length > 0 ? (
								<LineChartCard topdataLists={topdataLists} />
							) : (
								<div className=" flex h-full  items-center justify-center	">
									<div className="  flex items-end pb-5 mb-3 ">
										<p className="text-[20px] text-gray3 font-semibold">
											Sorry, no data at the moment. All data will be shown here
										</p>
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default OverviewComponent;
