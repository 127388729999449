import React from 'react';

//assets
import plus from "../../assets/plus.svg";

const EmptyTableView = ({title, subtitle, icon, onClick, btnLabel}) => {
  return (
    <div 
      className="flex flex-col items-center justify-center w-full h-[451px] bg-white rounded-md mr-4 mt-3">
      <img src={icon} alt="table icon" className="w-[72px] h-[72px]"/>
      <p className="text-[#3D4356] text-[18px] font-semibold mb-2">{title}</p>
      <p className="text-[#8892A2] text-[14px] font-normal mb-8 w-[308px] text-center">{subtitle}</p>
      {btnLabel && (
        <button 
          className="flex flex-row items-center justify-between rounded-md bg-[#F3641F] text-white px-3 font-medium w-[175px] h-[43px]"
          onClick={onClick}
        >
          <img src={plus} alt="add"/>
          {btnLabel}
        </button>
      )}
    </div>
  )
}

export default EmptyTableView;