import { createAction } from 'redux-actions';
import {
	REQUEST_GET_BRAND_ANALYTICS,
	REQUEST_GET_LOCATION_SALES_BREAKDOWN,
	REQUEST_GET_SALES_SUMMARY,
	REQUEST_GET_TOP_BRANDS,
	REQUEST_GET_TOP_LOCATIONS,
	REQUEST_GET_TOP_PRODUCTS,
	SUCCESS_GET_BRAND_ANALYTICS,
	SUCCESS_GET_LOCATION_SALES_BREAKDOWN,
	SUCCESS_GET_SALES_SUMMARY,
	SUCCESS_GET_TOP_BRANDS,
	SUCCESS_GET_TOP_LOCATIONS,
	SUCCESS_GET_TOP_PRODUCTS,
	REQUEST_LOCATION_FILTERS,
	SUCCESS_LOCATION_FILTERS,
	SUCCESS_GET_TOP_BRANDS_LIST,
	REQUEST_GET_TOP_BRANDS_LIST,
	REQUEST_GET_TOP_PRODUCTS_LIST,
	SUCCESS_GET_TOP_PRODUCTS_LIST,
	REQUEST_COUNTRY_LIST,
	SUCCESS_COUNTRY_LIST,
	REQUEST_PRODUCT_CATEGORY,
	SUCCESS_PRODUCT_CATEGORY,
	REQUEST_PRODUCT_SUBCATEGORY,
	SUCCESS_PRODUCT_SUBCATEGORY,
} from './constants';
export const requestCountryList = createAction(REQUEST_COUNTRY_LIST);
export const successCountryList = createAction(SUCCESS_COUNTRY_LIST);
export const requestTopBrandDataList = createAction(
	REQUEST_GET_TOP_BRANDS_LIST
);
export const successTopBrandDataList = createAction(
	SUCCESS_GET_TOP_BRANDS_LIST
);
export const requestTopProductDataList = createAction(
	REQUEST_GET_TOP_PRODUCTS_LIST
);

export const successTopProductDataList = createAction(
	SUCCESS_GET_TOP_PRODUCTS_LIST
);
export const requestLocationFilters = createAction(REQUEST_LOCATION_FILTERS);
export const successLocationFilter = createAction(SUCCESS_LOCATION_FILTERS);
export const requestGetBrandAnalytics = createAction(
	REQUEST_GET_BRAND_ANALYTICS
);
export const successGetBrandAnalytics = createAction(
	SUCCESS_GET_BRAND_ANALYTICS
);

export const requestGetSalesSummary = createAction(REQUEST_GET_SALES_SUMMARY);
export const successGetSalesSummary = createAction(SUCCESS_GET_SALES_SUMMARY);

export const requestGetTopBrands = createAction(REQUEST_GET_TOP_BRANDS);
export const successGetTopBrands = createAction(SUCCESS_GET_TOP_BRANDS);

export const requestGetTopProducts = createAction(REQUEST_GET_TOP_PRODUCTS);
export const successGetTopProducts = createAction(SUCCESS_GET_TOP_PRODUCTS);

export const requestGetTopLocations = createAction(REQUEST_GET_TOP_LOCATIONS);
export const successGetTopLocations = createAction(SUCCESS_GET_TOP_LOCATIONS);

export const requestGetLocationSalesBreakdown = createAction(
	REQUEST_GET_LOCATION_SALES_BREAKDOWN
);
export const successGetLocationSalesBreakdown = createAction(
	SUCCESS_GET_LOCATION_SALES_BREAKDOWN
);
export const requestCategories = createAction(REQUEST_PRODUCT_CATEGORY);
export const successCategories = createAction(SUCCESS_PRODUCT_CATEGORY);

export const requestSubCategories = createAction(REQUEST_PRODUCT_SUBCATEGORY);

export const successSubCategories = createAction(SUCCESS_PRODUCT_SUBCATEGORY);
