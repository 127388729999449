import { Avatar, Button } from '@material-ui/core';
import FallBackImage from '../../assets/drinkslogoIcon.svg';
import { numberFormat } from '../../utils'

const LocationsInfoCard = (props) => {
	return (
		<div
			className={`grid grid-cols-12 py-3  text-sm font-medium bg-white hover:bg-[#f4f5f7] hover:bg-opacity-5`}
		>
			<div className=" col-span-6 flex items-center space-x-3 pl-4 ">
				<div className=" bg-thinGrayBG rounded-full h-6 w-6 flex items-center justify-center ">
					<h1 className="text-sm  text-appBlack ">{props?.index + 1}</h1>
				</div>

				<div className="flex space-x-2 items-center justify-center">
					<Avatar className="w-10 h-10" src={props?.src || FallBackImage}>
						DM
					</Avatar>
					<div>
						<h1 className="text-sm font-medium text-gray2">{props?.title}</h1>
					</div>
				</div>
			</div>
			<div className="col-span-3 flex items-center">
				<h1 className="text-sm font-medium text-left text-gray1">
					{ numberFormat(props?.sold).toDecimal() }
				</h1>
			</div>
			<div className="col-span-2 flex items-center">
				<h1 className="text-sm font-medium text-left text-gray1">
				{ numberFormat(props?.amount).toCurrency() }
				</h1>
			</div>
			<div className="col-span-1 flex items-center justify-end">
				<h1 className="font-medium text-appRed text-left pr-6">
					{props?.percentage}
				</h1>
			</div>
		</div>
	);
};

export default LocationsInfoCard;
