export const SHOW_ERR_ALERT = 'SHOW_ERR_ALERT';
export const SHOW_MODAL = 'SHOW_MODAL';
export const SHOW_LOADER = 'SHOW_LOADER';
export const SET_GLOBAL_DATE = 'SET_GLOBAL_DATE';
export const INIT_URL = 'INIT_URL';
export const VERIFY_URL = 'VERIFY_URL';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';

export const REQUEST_SAVE_USER = 'REQUEST_SAVE_USER';
export const SUCCESS_SAVE_USER = 'SUCCESS_SAVE_USER';
export const REQUEST_GET_USERS = 'REQUEST_GET_USERS';
export const SUCCESS_GET_USERS = 'SUCCESS_GET_USERS';

export const REQUEST_RESET_PWD = 'REQUEST_RESET_PWD';

export const REQUEST_CHANGE_PWD = 'REQUEST_CHANGE_PWD';
export const SUCCESS_CHANGE_PWD = 'SUCCESS_CHANGE_PWD';

export const REQUEST_VERIFY_OTP = 'REQUEST_VERIFY_OTP';
export const SUCCESS_VERIFY_OTP = 'SUCCESS_VERIFY_OTP';

export const REQUEST_MY_DETAILS = 'REQUEST_MY_DETAILS';
export const MY_DETAILS_SUCCESS = 'MY_DETAILS_SUCCESS';

export const REQUEST_RESEND_OTP = 'REQUEST_RESEND_OTP';
export const REQUEST_RESEND_OTP_SUCCESS = 'REQUEST_RESEND_OTP_SUCCESS';

export const REQUEST_GET_BRAND_ANALYTICS = 'REQUEST_GET_BRAND_ANALYTICS';
export const SUCCESS_GET_BRAND_ANALYTICS = 'SUCCESS_GET_BRAND_ANALYTICS';

export const REQUEST_GET_SALES_SUMMARY = 'REQUEST_GET_SALES_SUMMARY';
export const SUCCESS_GET_SALES_SUMMARY = 'SUCCESS_GET_SALES_SUMMARY';

export const REQUEST_GET_TOP_BRANDS = 'REQUEST_GET_TOP_BRANDS';
export const SUCCESS_GET_TOP_BRANDS = 'SUCCESS_GET_TOP_BRANDS';
//
export const REQUEST_GET_TOP_BRANDS_LIST = 'REQUEST_GET_TOP_BRANDS_LIST';
export const SUCCESS_GET_TOP_BRANDS_LIST = 'SUCCESS_GET_TOP_BRANDS_LIST';

//
export const REQUEST_GET_TOP_PRODUCTS = 'REQUEST_GET_TOP_PRODUCTS';
export const SUCCESS_GET_TOP_PRODUCTS = 'SUCCESS_GET_TOP_PRODUCTS';

//
export const REQUEST_GET_TOP_PRODUCTS_LIST = 'REQUEST_GET_TOP_PRODUCTS_LIST';
export const SUCCESS_GET_TOP_PRODUCTS_LIST = 'SUCCESS_GET_TOP_PRODUCTS_LIST';
export const REQUEST_GET_TOP_LOCATIONS = 'REQUEST_GET_TOP_LOCATIONS';
export const SUCCESS_GET_TOP_LOCATIONS = 'SUCCESS_GET_TOP_LOCATIONS';

export const REQUEST_GET_LOCATION_SALES_BREAKDOWN =
	'REQUEST_GET_LOCATION_SALES_BREAKDOWN';
export const SUCCESS_GET_LOCATION_SALES_BREAKDOWN =
	'SUCCESS_GET_LOCATION_SALES_BREAKDOWN';

export const REQUEST_UPDATE_USER_EMAIL_PHONE =
	'REQUEST_UPDATE_USER_EMAIL_PHONE';
export const SUCCESS_UPDATE_USER_EMAIL_PHONE =
	'SUCCESS_UPDATE_USER_EMAIL_PHONE';
export const REQUEST_LOCATION_FILTERS = 'REQUEST_LOCATION_FILTERS';
export const SUCCESS_LOCATION_FILTERS = 'SUCCESS_LOCATION_FILTERS';

export const REQUEST_COUNTRY_LIST = 'REQUEST_COUNTRY_LIST';
export const SUCCESS_COUNTRY_LIST = 'SUCCESS_COUNTRY_LIST';
export const SUCCESS_PRODUCT_CATEGORY = 'SUCCESS_PRODUCT_CATEGORY';
export const REQUEST_PRODUCT_CATEGORY = 'REQUEST_PRODUCT_CATEGORY';
export const REQUEST_PRODUCT_SUBCATEGORY = 'REQUEST_PRODUCT_SUBCATEGORY';
export const SUCCESS_PRODUCT_SUBCATEGORY = 'SUCCESS_PRODUCT_SUBCATEGORY';
