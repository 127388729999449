import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import ProductsTop from '../../components/productsComponent/productATop';
import MoreComponent from './moretable';
import Pagination from '@material-ui/lab/Pagination';
import InfoCard from './tablemore';
import searchIcon from '../../assets/search.svg';
import PageTitle from '../../components/PageTitle/pageTitle';
import {
	requestGetBrandAnalytics,
	requestGetLocationSalesBreakdown,
	requestLocationFilters,
} from '../../appRedux/actions/brands';
import { requestMyDetails } from '../../appRedux/actions/auth';
import { getDateRangeQs } from '../../utils'


function MoreLocationDetails() {
	const dispatch = useDispatch();
	const { locationSalesBreakdownLists } = useSelector(({ brands }) => brands);
	const { globalDate } = useSelector(state => state.common);
	const period = globalDate.period.toLowerCase()

	const [activePeriod, setActivePeriod] = useState(period);
	// const [brandsRange, setBrandsRange] = React.useState(globalDate.range);

	useEffect(() => {
		dispatch(requestMyDetails({}));

		const dateFilter = getDateRangeQs(globalDate.range);
		dispatch(requestGetLocationSalesBreakdown(dateFilter));
		dispatch(requestGetBrandAnalytics(dateFilter));
		dispatch(requestLocationFilters({}));
	}, []);

	const handleDayFilter = (day) => {
		dispatch(
			requestGetLocationSalesBreakdown({ day_filter: day?.toLowerCase() })
		);
	};

	const handleDateRangeSelect = (range) => {
		// setBrandsRange(range);
		dispatch(requestGetLocationSalesBreakdown(getDateRangeQs(
			range.to === null
				? { ...range, to: range.from }
				: range
		)));
	};

	const handleLocationSearch = (term) => {
		dispatch(requestGetLocationSalesBreakdown({ searchTerm: term }));
	};

	const [filterCompetitors, setFilterCompetitors] = useState(true);
	const filterByCompetitors = () => {
		setFilterCompetitors(!filterCompetitors);
	};

	return (
		<div className="overflow-hidden">
			<PageTitle title="Locations" />
			<div class="grid grid-cols-1 gap-7 pb-5 pl-6 pr-6 ">
				<MoreComponent
					month={true}
					countryLists={
						filterCompetitors === true
							? locationSalesBreakdownLists.filter(
									(o) => o.label === 'Competitor'
							  )
							: locationSalesBreakdownLists.filter(
									(o) => o.label !== 'Competitor'
							  )
					}
					searchIcon={searchIcon}
					competitors={true}
					setActivePeriod={setActivePeriod}
					activePeriod={activePeriod}
					ProductsTop={ProductsTop}
					Pagination={Pagination}
					InfoCard={InfoCard}
					checked={filterCompetitors}
					checklabel="Show Competitors"
					noborder={true}
					toptitle="Detailed Product Location Overview"
					searchPlaceholder="Search by Products or brand"
					handleDateRangeSelect={handleDateRangeSelect}
					handleDayFilter={handleDayFilter}
					handleLocationSearch={handleLocationSearch}
					filterByCompetitors={filterByCompetitors}
					filterBySalesPoint
				/>
			</div>
		</div>
	);
}

export default MoreLocationDetails;
