import React from 'react';
import { Card } from '../../Card/Card';
import { BrandInfoCard } from '../../brandComponents/brands';
import { useSelector } from 'react-redux';
import exportFromJSON from 'export-from-json';

export default function RightSideProduct({
	ExcelIcon,
	topItemList,
	title,
	exportData,
}) {
	const data = exportData;
	// const newArray = data?.map(({ uuid, logo, ...item }) => item);

	const fileName = 'DrinksNG Data';
	const exportType = exportFromJSON.types.csv;
	return (
		<div class="col-span-1 bg-white p-4 overflow-y-auto ">
			<div className="flex justify-between align-middle  border-b border-[#EEF2F5] py-3 ">
				<p className="font-medium text-cardHeading text-base">{title}</p>
				<div className="flex  border space-x-3 rounded-md  p-1 justify-center border-[#b6d8f1] font-normal ml-2">
					<button
						onClick={() => {
							exportFromJSON({ data, fileName, exportType });
						}}
						className=" text-appGray align-middle "
						disabled={data.length === 0 ? true : false}
					>
						Export
					</button>

					<button
						disabled={data.length === 0 ? true : false}
						onClick={() => {
							exportFromJSON({ data, fileName, exportType });
						}}
					>
						<img
							className="cursor-pointer"
							src={ExcelIcon}
							alt="export to excel"
						/>
					</button>
				</div>
			</div>
			<Card>
				<div className="divide-y divide-[#EEF2F5] over-flow-auto ">
					{topItemList?.map((item, i) => (
						<BrandInfoCard
							amount={item?.revenue}
							name={item?.label}
							growthPercent={`${
								RoundToWholeNumber(
									FindPercentage(
										totalAmt(topItemList), item?.revenue
									)
								)
							}%`}
							key={i}
						/>
					))}
				</div>
			</Card>
		</div>
	);
}

const totalAmt = (details) =>
	details?.reduce((a, c) => a + parseFloat(c?.revenue), 0);
const FindPercentage = (totalAmt, amt) => (amt / totalAmt) * 100;
const RoundTo2Decimals = (num) =>
	Math.round((num + Number.EPSILON) * 1000) / 1000;
const RoundToWholeNumber = (num) => Math.round(num);
