import { createAction } from "redux-actions";

import {
  REQUEST_GET_USERS,
  REQUEST_SAVE_USER,
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SUCCESS_GET_USERS,
  SUCCESS_SAVE_USER,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  INIT_URL,
  REQUEST_RESET_PWD,
  VERIFY_URL,
  SUCCESS_VERIFY_OTP,
  REQUEST_VERIFY_OTP,
  REQUEST_CHANGE_PWD,
  SUCCESS_CHANGE_PWD,
  REQUEST_MY_DETAILS,
  MY_DETAILS_SUCCESS,
  REQUEST_RESEND_OTP,
  REQUEST_RESEND_OTP_SUCCESS,
  REQUEST_UPDATE_USER_EMAIL_PHONE,
  SUCCESS_UPDATE_USER_EMAIL_PHONE,
} from "./constants";

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url,
  };
};

export const setVerifyUrl = (url) => {
  return {
    type: VERIFY_URL,
    payload: url,
  };
};

export const requestMyDetails = createAction(REQUEST_MY_DETAILS);
export const myDetailsSuccess = createAction(MY_DETAILS_SUCCESS);

export const signInUser = createAction(SIGNIN_USER);
export const signInUserSuccess = createAction(SIGNIN_USER_SUCCESS);

export const requestResetPwd = createAction(REQUEST_RESET_PWD);
export const requestChangePwd = createAction(REQUEST_CHANGE_PWD);
export const successChangePwd = createAction(SUCCESS_CHANGE_PWD);

export const signOutUser = createAction(SIGNOUT_USER);
export const signOutUserSuccess = createAction(SIGNOUT_USER_SUCCESS);

export const requestGetUsers = createAction(REQUEST_GET_USERS);
export const successGetUsers = createAction(SUCCESS_GET_USERS);

export const requestSaveUsers = createAction(REQUEST_SAVE_USER);
export const successSaveUsers = createAction(SUCCESS_SAVE_USER);

export const requestVerifyOtp = createAction(REQUEST_VERIFY_OTP);
export const successVerifyOtp = createAction(SUCCESS_VERIFY_OTP);

export const requestResendOTP = createAction(REQUEST_RESEND_OTP);
export const requestResendOTPSuccess = createAction(REQUEST_RESEND_OTP_SUCCESS);

export const requestUpdateUserEmailPhone = createAction(
  REQUEST_UPDATE_USER_EMAIL_PHONE
);

export const successUpdateUserEmailPhone = createAction(
  SUCCESS_UPDATE_USER_EMAIL_PHONE
);
