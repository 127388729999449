import React, { useState, useEffect } from 'react';
import DateActions from '../DateActions/DateActions';
import greenUpArrow from '../../assets/greenUpArrow.png';
import { Avatar, Button } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import FallBackImage from '../../assets/drinkslogoIcon.svg';
import { useHistory } from 'react-router-dom';
import {
	requestGetBrandAnalytics,
	requestGetTopBrands,
	requestGetTopLocations,
	requestGetTopProducts,
	requestTopBrandDataList,
	requestTopProductDataList,
	requestCountryList,
} from '../../appRedux/actions/brands';
import LineChartCard from './LineChartCard';
import _ from 'lodash';
import { getDateRangeQs, numberFormat } from '../../utils'

export default function ChartCards() {
	const history = useHistory();
	const dispatch = useDispatch();
	const {
		analyticList,
		topBrandLists,
		locationDataList,

		topBrandDataList,
		topProductDataList,
	} = useSelector(({ brands }) => brands);
	const { period, range: globalRange } = useSelector(state => state.common.globalDate);


	const [activePeriod, setActivePeriod] = useState(period.toLowerCase());
	// const [brandsRange, setBrandsRange] = React.useState({});

	const handleDateRangeSelect = (range) => {
		// setBrandsRange(range);
		const dateFilter = getDateRangeQs(
			range.to === null
				? { ...range, to: range.from }
				: range
		)

		dispatch(requestGetTopBrands(dateFilter))
		dispatch(requestGetBrandAnalytics(dateFilter))
		dispatch(requestTopProductDataList(dateFilter))
		dispatch(requestCountryList(dateFilter))
		dispatch(requestTopBrandDataList(dateFilter))
	};

	const handleDayFilter = (day) => {
		dispatch(requestGetTopBrands({ day_filter: day?.toLowerCase() }));
		dispatch(requestGetBrandAnalytics({ day_filter: day?.toLowerCase() }));
		dispatch(requestTopProductDataList({ day_filter: day?.toLowerCase() }));
		dispatch(requestCountryList({ day_filter: day?.toLowerCase() }));
		dispatch(requestTopBrandDataList({ day_filter: day?.toLowerCase() }));
	};

	return (
		<React.Fragment>
			<div className="pl-6 pr-6">
				<div className="border-b border-thinGrayBG">
					<div className="flex align-middle justify-between  p-5 pt-6 bg-white">
						<div className=" items-center p2 ">
							<h4 className=" px-2 text-cardHeading font-semibold">
								Revenue Overview
							</h4>
						</div>
						<div className=" px-2 ">
							<DateActions
								today
								week
								month
								activePeriod={activePeriod}
								changePeriod={setActivePeriod}
								handleDayFilter={handleDayFilter}
								handleDateRangeSelect={handleDateRangeSelect}
							/>
						</div>
					</div>
				</div>
				<div className="bg-white pb-5">
					{topBrandLists.length > 0 && (
						<div className="px-5 pt-2 bg-white rounded-md border-b-2 border-[#EEF2F5] w-[15%] ">
							<h1 className="text-[12px] text-gray3 capitalize">
								All items sold
							</h1>

							<div className="">
								<p className="text-[20px] font-semibold">
									{ numberFormat(analyticList?.quantity_sold || 0).toDecimal() }
								</p>
								<div className="flex items-center pb-2  border-appGray text-[20px] font-semibold">
									{ numberFormat(analyticList?.revenue || 0).toCurrency() }
								</div>
							</div>
						</div>
					)}
					<div className="p-5">
						<div className="h-[275px] bg-white">
							{topBrandLists.length > 0 ? (
								<LineChartCard
									topdataLists={_.orderBy(
										topBrandLists,
										['label'],
										['asc']
									)}
								/>
							) : (
								<div className="flex items-center justify-center h-[275px]">
									<p className="text-[20px] text-gray3 font-semibold">
										Sorry, no data at the moment. All data will be shown here
									</p>
								</div>
							)}
						</div>
					</div>
				</div>
				<div className="grid grid-cols-3 gap-5 pt-5 pb-5">
					<Card>
						<div className="h-[15%] flex justify-between items-center">
							<Title title="Top Brands" className="text-cardHeading" />
							<Button
								variant="contained"
								size="small"
								className="py-[2px] capitalize shadow-none bg-orange/10 text-orange"
								onClick={() => history.push('/brands')}
							>
								View All
							</Button>
						</div>

						<div className="divide-y divide-[#EEF2F5] h-[85%] overflow-y-auto pr-4 smallscroll">
							{topBrandDataList?.map((item, i) => (
								<InfoCard
									key={i}
									name={item?.label}
									// subTitle={item?.revenue}
									src={item?.logo}
									amount={item?.revenue}
									location
									brands
								/>
							))}
						</div>
					</Card>

					<Card>
						<div className="h-[15%] flex justify-between items-center">
							<Title
								title="Top Selling products"
								className="text-cardHeading"
							/>
							<Button
								variant="contained"
								size="small"
								className="py-[2px] capitalize shadow-none bg-orange/10 text-orange"
								onClick={() => history.push('/products')}
							>
								View All
							</Button>
						</div>

						<div className="divide-y divide-[#EEF2F5] h-[85%] overflow-y-auto pr-4 smallscroll">
							{topProductDataList?.map((item, i) => (
								<InfoCard
									key={i}
									name={item?.label}
									src={item?.image}
									subTitle={item?.quantity_sold}
									amount={item?.revenue}
								/>
							))}
						</div>
					</Card>

					<Card>
						<div className="h-[15%] flex justify-between items-center">
							<Title title="Top Locations" className="text-cardHeading" />

							<Button
								variant="contained"
								size="small"
								className="py-[2px] capitalize shadow-none bg-orange/10 text-orange"
								onClick={() => history.push('/locations')}
							>
								View All
							</Button>
						</div>

						<div className="divide-y divide-[#EEF2F5] h-[85%] overflow-y-auto pr-4 smallscroll">
							{locationDataList?.map((item, i) => (
								<InfoCard
									key={i}
									name={item?.city}
									amount={item?.revenue}
									location
								/>
							))}
						</div>
					</Card>
				</div>
			</div>
		</React.Fragment>
	);
}

const Title = ({ title, className }) => {
	return (
		<h1 className={`font-medium capitalize text-gray1 ${className}`}>
			{title}
		</h1>
	);
};

const InfoCard = ({ name, subTitle, amount, src, location, brands }) => {
	return (
		<div className="flex justify-between py-3">
			<div className="flex items-center space-x-2">
				{!location && (
					<Avatar className="w-8 h-8" src={src || FallBackImage}>
						{name?.charAt(0)}
					</Avatar>
				)}
				{brands && (
					<Avatar className="w-8 h-8" src={src || FallBackImage}>
						{name?.charAt(0)}
					</Avatar>
				)}
				<div>
					<h1 className="text-sm font-medium text-gray2">{name} </h1>

					{!location && (
						<p className="text-[12px] text-gray3">
							{ numberFormat(subTitle).toDecimal() } Sold
						</p>
					)}
				</div>
			</div>
			<div className="text-sm font-semibold text-[#151515]">
				{ numberFormat(amount).toCurrency() }
			</div>
		</div>
	);
};

export const Card = ({ children }) => {
	return (
		<div className="p-5 bg-white divide-y rounded-md h-80 divide-[#EEF2F5]">
			{children}
		</div>
	);
};
