import jsPDF from 'jspdf';
import 'jspdf-autotable';
// Date Fns is used to format the dates we receive
// from our API call
import { format } from 'date-fns';

// define a generatePDF function that accepts a tickets argument
const generateUserPDF = ({ tickets, printDat }) => {
	// initialize jsPDF
	const doc = new jsPDF();

	// define the columns we want and their titles
	const tableColumn = [
		'Id',
		'First Name',
		'Last Name',
		'Email',
		'Contact',
		'Created At',
	];
	// define an empty array of rows
	const tableRows = [];
	console.log('tickers', tickets);
	// for each ticket pass all its data into an array
	tickets.forEach((ticket) => {
		const ticketData = [
			ticket.id,
			ticket.profile.first_name,
			ticket.profile.last_name,
			ticket.email,
			ticket.phone_number,
			// called date-fns to format the date on the ticket
			format(new Date(ticket.created_at), 'yyyy-MM-dd'),
		];
		// push each tickcet's info into a row
		tableRows.push(ticketData);
	});

	// startY is basically margin-top
	doc.autoTable(tableColumn, tableRows, { startY: 20 });
	// we use a date string to generate our filename.
	const dateStr = 'DrinksNG User Data';
	// ticket title. and margin-top + margin-left
	// doc.text('Closed tickets within the last one month.', 14, 15);
	// we define the name of our PDF file..

	printDat
		? window.open(doc.output('bloburl'), '_blank')
		: doc.save(`report_${dateStr}.pdf`);
};

export default generateUserPDF;
