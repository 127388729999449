import React from 'react';

import { TableCell, TableHead, TableRow, Checkbox } from '@material-ui/core';
import { BpCheckbox, BpIcon, BpCheckedIcon } from './UsersTable';
const headCells = [
	{ id: 'name', label: 'Name' },
	{ id: 'email', label: 'Email' },
	{ id: 'telephone', label: 'Phone Number' },
	{ id: 'acc_type', label: 'Account Type' },
	{ id: 'status', label: 'status' },
	{ id: 'actions', numeric: true, label: 'Action' },
];

const CustomTableHead = ({
	onSelectAllClick,
	numSelected,
	rowCount,
	className,
}) => {
	return (
		<TableHead className="bg-transparent">
			<TableRow>
				<TableCell padding="checkbox">
					<BpCheckbox
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
						inputProps={{ 'aria-label': 'select all desserts' }}
						className={className}
					/>
				</TableCell>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						className="uppercase text-[12px]"
						align={headCell.numeric ? 'right' : 'left'}
					>
						{headCell.label}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
};

export default CustomTableHead;
