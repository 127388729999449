import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Calendar, utils } from 'react-modern-calendar-datepicker';
import { Popover } from '@material-ui/core';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { MenuItem, FormControl, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import roundArrow from '../../assets/round-arow.svg';
import { format, subDays } from 'date-fns';
import { SET_GLOBAL_DATE } from "../../appRedux/actions/constants";
const DateActions = ({
	activePeriod,
	changePeriod,
	align = 'right',
	className,
	today,
	month,
	week,
	handleDayFilter,
	handleDateRangeSelect,
}) => {
	const { globalDate } = useSelector(({ common }) => common);
	const dispatch = useDispatch()
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const id = open ? 'calendar-popover' : undefined;

	const [selectedDayRange, setSelectedDayRange] = useState(globalDate.range);

	const handlePopoverOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};
	const [age, setAge] = React.useState(globalDate.period);
	const handleChange = (event) => {
		setAge(event.target.value);
		handleDayFilter(event.target.value);
		// setPeriod(event.target.value);
		const glob = timePeriod(event.target.value);
		dispatch({ type: SET_GLOBAL_DATE, payload: glob })
	};
	const timePeriod = (time) => {
		if (time === ' ') return null;

		let dateRange = {
			from: {
				year: subDays(new Date(), 7).getFullYear(),
				month: subDays(new Date(), 7).getMonth() + 1,
				day: subDays(new Date(), new Date().getDay()).getDate(),
			},
			to: utils().getToday(),
		}
		if (time === 'Month') {
			dateRange = {
				from: {
					year: subDays(new Date(), 0).getFullYear(),
					month: subDays(new Date(), 0).getMonth() + 1,
					day: 1,
				},
				to: utils().getToday(),
			};
		} else if (time === 'Today') {
			dateRange = {
				from: {
					year: subDays(new Date(), 0).getFullYear(),
					month: subDays(new Date(), 0).getMonth() + 1,
					day: subDays(new Date(), 0).getDate(),
				},
				to: utils().getToday(),
			};
		}

		setSelectedDayRange(dateRange);
		return {
			period: time,
			range: dateRange
		}
	};
	return (
		<>
			<div className={`flex items-center  bg-transparent ${className}`}>
				<div>
					<FormControl
						variant="standard"
						classes={{
							root: 'flex justify-between bg-[#F3641F] text-[#fff]  rounded-md mx-2 pl-3 text-sm font-medium',
							// MenuProps: 'hover:bg-[#F3641F] hover:bg-opacity-10',
							// icon: 'text-[#fff]',
						}}
						sx={{ m: 1 }}
					>
						<Select
							disableUnderline={true}
							defaultValue={'Today'}
							classes={{
								root: 'flex justify-between bg-[#F3641F] text-[#fff]  rounded-md text-sm font-medium ',
								// MenuProps: 'hover:bg-[#F3641F] hover:bg-opacity-10',
								icon: 'text-[#fff]',
							}}
							labelId="demo-simple-select-standard-label"
							id="demo-simple-select-standard"
							value={age}
							onChange={handleChange}
							label="Age"
							anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
							transformOrigin={{ vertical: 'top', horizontal: 'center' }}
						>
							<MenuItem
								fullWidth
								className={
									'text-sm font-medium hover:bg-[#F3641F] hover:bg-opacity-10 focus:bg-[#F3641F] focus:bg-opacity-10 w-full'
								}
								value={'Today'}
							>
								Today
							</MenuItem>
							<MenuItem
								className={
									'hover:bg-[#F3641F] hover:bg-opacity-10 focus:bg-[#F3641F] focus:bg-opacity-10'
								}
								value={'Week'}
							>
								Week
							</MenuItem>
							<MenuItem
								className={
									'hover:bg-[#F3641F] hover:bg-opacity-10 focus:bg-[#F3641F] focus:bg-opacity-10'
								}
								value={'Month'}
							>
								Month
							</MenuItem>{' '}
						</Select>
					</FormControl>
				</div>
				<button
					aria-describedby={id}
					onClick={handlePopoverOpen}
					className="flex flex-row items-center bg-white border border-[#E3E7ED] h-[35px] rounded-md px-4"
				>
					<p className="text-[#9CA7B8] text-[14px] font-medium mr-3">
						{selectedDayRange.from === null
							? format(new Date(), 'MMM dd,yyyy')
							: format(
									new Date(
										`${selectedDayRange.from.year}-${selectedDayRange.from.month}-${selectedDayRange.from.day}`
									),
									'MMM dd, yyyy'
							  )}
					</p>
					<img
						src={roundArrow}
						alt="round arrow"
						className="w-[15px] h-[15px]"
					/>
					<p className="text-[#9CA7B8] text-[14px] font-medium ml-3">
						{selectedDayRange.to === null
							? format(
									new Date(
										`${utils().getToday().year}-${utils().getToday().month}-${
											utils().getToday().day
										}`
									),
									'MMM dd, yyyy'
							  )
							: format(
									new Date(
										`${selectedDayRange.to.year}-${selectedDayRange.to.month}-${selectedDayRange.to.day}`
									),
									'MMM dd, yyyy'
							  )}{' '}
					</p>
				</button>
				<Popover
					id={id}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center',
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
					open={open}
					anchorEl={anchorEl}
					onClose={handlePopoverClose}
					disableRestoreFocus
				>
					<Calendar
						value={selectedDayRange}
						onChange={(e) => {
							setSelectedDayRange(e);
							handleDateRangeSelect(e);
							dispatch({
								type: SET_GLOBAL_DATE,
								payload: {
									range: e
								}
							})
						}}
						colorPrimary="#F3641F"
						colorPrimaryLight="rgba(243, 100, 31, 0.1)"
						shouldHighlightWeekends={false}
						maximumDate={utils().getToday()}
					/>
				</Popover>
			</div>
		</>
	);
};

export default DateActions;
