import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Users from './index';
import AddNewUser from './add-new-user';
import SingleUser from './single-user';
import UpdateUser from './user-update';

const UserRoute = ({ match }) => {
	return (
		<Switch>
			{/* <Redirect exact from={`${match.url}/`} to={`${match.url}/`} /> */}
			<Route path={`${match.url}/create-user`} component={AddNewUser} />
			<Route path={`${match.url}/single-user`} component={SingleUser} />
			<Route path={`${match.url}/update-user`}>
				<UpdateUser />
			</Route>
			<Route path={`${match.url}`} component={Users} />
		</Switch>
	);
};

export default UserRoute;
