import React from 'react';

import { Route, Switch, Redirect } from 'react-router-dom';

import Homepage from './home/index';
import Brands from './brands/index';
import ProducstPage from './products/index';
import ProfilePage from './profile/profilePage';
import Users from './users/route';
import Locations from './locations/route';
import FileUpload from './fileUpload/index';

const App = ({ match }) => (
	<div className="">
		<Switch>
			{/*  <Redirect exact from={`${match.url}/`} to={`${match.url}/`} /> */}
			<Route path={`${match.url}profile`} component={ProfilePage} />
			<Route path={`${match.url}fileUpload`} component={FileUpload} />

			<Route path={`${match.url}products`} component={ProducstPage} />
			<Route path={`${match.url}locations`} component={Locations} />
			<Route path={`${match.url}brands`} component={Brands} />
			<Route path={`${match.url}users`} component={Users} />
			<Route path={`${match.url}overview`} component={Homepage} />
		</Switch>
	</div>
);

export default App;
