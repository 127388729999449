import React, { useState, useEffect } from 'react';
import { Avatar, Button } from '@material-ui/core';
import PageTitle from '../PageTitle/pageTitle';

import ExcelIcon from '../../assets/excelIcon.svg';
import { requestMyDetails } from '../../appRedux/actions/auth';

import GrayEllipse from '../../assets/grayEllipse.png';
import greenUpArrow from '../../assets/greenUpArrow.png';
import filter from '../../assets/filter.svg';
import pdf from '../../assets/pdf.svg';
import {
	requestGetBrandAnalytics,
	requestGetTopBrands,
	requestTopBrandDataList,
} from '../../appRedux/actions/brands';
import { SET_GLOBAL_DATE } from "../../appRedux/actions/constants";
import { useSelector, useDispatch } from 'react-redux';
import FallBackImage from '../../assets/drinkslogoIcon.svg';
import ProductAnalyticPage from '../productsComponent/Analytics';
import RightSideProduct from '../productsComponent/Rightside';
import ProductSaleOverview from '../productsComponent/SaleOverview';
import _ from 'lodash';
import { getDateRangeQs, numberFormat } from '../../utils'
//just for changes sake

export default function BrandsComponent() {
	const dispatch = useDispatch();
	const { topBrandLists, topBrandDataList } = useSelector(
		({ brands }) => brands
	);
	const globalDate = useSelector(state => state.common.globalDate);
	const period = globalDate.period.toLowerCase()

	const [activePeriod, setActivePeriod] = useState(period);
	const [brandSalePeriod, setActiveBrandSalePeriod] = useState(period);
	// const [brandsRange, setBrandsRange] = React.useState(globalDate.range);
	const [chartData, setChartData] = useState(null);

	useEffect(() => {
		dispatch(requestMyDetails({}));

		const dateFilter = getDateRangeQs(globalDate.range)
		dispatch(requestGetTopBrands(dateFilter));
		dispatch(requestGetBrandAnalytics(dateFilter));
		dispatch(requestTopBrandDataList(dateFilter));
	}, []);

	// // console.log('top brand list', topBrandLists);
	const handleDayFilter = (day) => {
		// console.log("dayyy....", day?.toLowerCase());
		dispatch(requestGetTopBrands({ day_filter: day?.toLowerCase() }));
	};
	const handleDayListFilter = (day) => {
		// console.log("dayyy....", day?.toLowerCase());
		dispatch(requestTopBrandDataList({ day_filter: day?.toLowerCase() }));
	};
	const handleDateRangeSelect = (range) => {
		// setBrandsRange(range);
		// console.log("lalalla", range);
		dispatch(requestGetTopBrands(getDateRangeQs(
			range.to === null
				? { ...range, to: range.from }
				: range
		)));
	};
	const handleDateListRangeSelect = (range) => {
		// setBrandsRange(range);
		// console.log("lalalla", range);
		dispatch(requestTopBrandDataList(getDateRangeQs(
			range.to === null
				? { ...range, to: range.from }
				: range
		)));
	};
	// // console.log('brands Range', brandsRange);

	return (
		<div className="overflow-hidden">
			<PageTitle title="Brands" />
			<div>
				<div class="grid grid-cols-3 gap-7 pb-5 pl-6 pr-6 ">
					{/* row one */}
					<main class="col-span-2  ">
						<div className="grid grid-rows-2">
							<ProductAnalyticPage
								pdf={pdf}
								exportBtn={false}
								activePeriod={activePeriod}
								setActivePeriod={setActivePeriod}
								Top4ProductLists={topBrandDataList?.slice(0, 4)}
								GrayEllipse={GrayEllipse}
								otherBrands={topBrandDataList?.slice(4, 10)}
								pieChartData={topBrandDataList?.slice(0, 4)}
								title="Brand Analytics"
								otherItemsTitle="Other Brands"
								handleDateRangeSelect={handleDateListRangeSelect}
								handleDayFilter={handleDayListFilter}
								exportData={topBrandDataList}
								top4Title="Top 4 Brands"
							/>
							<div className="row-span-1 ">
								<ProductSaleOverview
									pdf={pdf}
									activePeriod={brandSalePeriod}
									setActivePeriod={setActiveBrandSalePeriod}
									greenUpArrow={greenUpArrow}
									filter={filter}
									chartData={chartData}
									title="Brands Sale Overview"
									handleDateRangeSelect={handleDateRangeSelect}
									handleDayFilter={handleDayFilter}
									topdataLists={_.orderBy(
										topBrandLists,
										['label'],
										['asc']
									)}
								/>
							</div>
						</div>
					</main>

					<RightSideProduct
						ExcelIcon={ExcelIcon}
						topItemList={topBrandDataList}
						title="Top Brands"
						exportData={topBrandDataList?.map(
							({ uuid, image, logo, ...item }) => item
						)}
					/>
				</div>
			</div>
		</div>
	);
}

export const BrandInfoCard = ({ name, amount, growthPercent, src }) => {
	return (
		<div className="flex justify-between py-3   items-center">
			<div className="flex items-center space-x-2">
				<Avatar className="w-8 h-8" src={src || FallBackImage}>
					{name?.charAt(0)}
				</Avatar>

				<div>
					<h1 className="text-sm font-medium text-gray2">{name || ''}</h1>
				</div>
			</div>
			<div>
				<div className="text-sm font-semibold text-[#151515]">
					{ numberFormat(amount).toCurrency() }
				</div>
				<p className="font-medium text-orange text-sm text-right">
					{growthPercent}
				</p>
			</div>
		</div>
	);
};

export const Top4Items = ({ name, percentage, imgOnPie }) => {
	return (
		<div className="flex justify-between  font-appBlack bg-gray-200 px-3 mb-1 py-2">
			<div className="flex align-middle items-center">
				<img
					src={imgOnPie}
					className="pr-2"
					alt=""
					height="auto"
					width="auto"
				/>
				<p className="">{name}</p>
			</div>
			<p className="font-semibold text-appBlack">{percentage}</p>
		</div>
	);
};
