import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';

//utils
import checkStrength from '../../utils/checkPasswordStrength';

//mui
import { LinearProgress } from '@material-ui/core';

//components
import AuthCard from '../../components/AuthCard/AuthCard';
import FormInput from '../../components/FormInput/FormInput';
import CustomButton from '../../components/CustomButton/CustomButton';
import ErrorAlert from '../../components/ErrorAlert/ErrorAlert';

import { showLoader, showErrAlert } from '../../appRedux/actions/common';
import { requestChangePwd } from '../../appRedux/actions/auth';

import { useDispatch, useSelector } from 'react-redux';
const NewPassword = () => {
	const history = useHistory();

	const dispatch = useDispatch();
	const [reset, setReset] = useState({
		newPassword: '',
		confirmNewPassword: '',
	});

	const [strength, setStrength] = useState('');
	const [progress, setProgess] = useState(0);

	const { loader, errMessage } = useSelector(({ common }) => common);
	const { token } = useSelector(({ auth }) => auth);
	const { verifyURL } = useSelector(({ auth }) => auth);
	useEffect(() => {
		if (verifyURL?.type === 'reset-success') {
			history.push(verifyURL?.pathname);
		}
	});
	const handleChange = (e) => {
		const { name, value } = e.target;
		const result = checkStrength(reset.newPassword);

		if (name === 'newPassword') {
			if (value === '') {
				setProgess(0);
				setStrength('');
			} else {
				setStrength(result.strength);
				setProgess(result.progress);
			}
		}

		setReset({
			...reset,
			[name]: value,
		});
	};

	const submit = () => {
		const { newPassword, confirmNewPassword } = reset;

		dispatch(showLoader(true));
		dispatch(showErrAlert(''));

		//TODO check if passwords are the same
		if (newPassword !== confirmNewPassword) {
			dispatch(showErrAlert('Passwords do not match, please try again.'));
			dispatch(showLoader(false));
			return;
		}
		dispatch(requestChangePwd({ newPassword, token }));
		// console.log('token is ', token);
	};

	return (
		<AuthCard>
			<div className="flex flex-col items-center space-y-2">
				<h1 className="text-2xl font-bold text-center">Reset Password</h1>
			</div>

			<div className="w-[65%]">
				<form className="space-y-2">
					<div>
						<FormInput
							id="newPassword"
							name="newPassword"
							type="password"
							placeholder="New Password"
							label="New Password"
							onChange={handleChange}
							value={reset.newPassword}
						/>

						<LinearProgress
							variant="determinate"
							value={progress}
							classes={{
								root: 'bg-[#E3E7ED] mt-px',
								bar: `${
									strength === 'Weak'
										? ' bg-red'
										: strength === 'Good'
										? 'bg-appBlue'
										: strength === 'Strong'
										? 'bg-[#00cf26]'
										: 'bg-[#E3E7ED]'
								} `,
							}}
						/>

						<div className="flex items-center justify-center text-[12px] text-gray-300">
							Password strength:
							<span className="pl-[5px] font-semibold text-gray1">
								{strength}
							</span>
						</div>
					</div>

					<ul className="text-[12px] text-gray2  w-[80%]">
						<li className="flex space-x-1">
							<span>&#8226;</span>
							<p>Use at least 8 characters.</p>
						</li>
						<li className="flex space-x-1">
							<span>&#8226;</span>
							<p className="">
								Besides letters, include at least a number or symbol
								(!@#$%^&*-_=+).
							</p>
						</li>
						<li className="flex space-x-1">
							<span>&#8226;</span>
							<p>Password is case sensitive.</p>
						</li>
					</ul>

					<FormInput
						id="confirmNewPassword"
						name="confirmNewPassword"
						type="password"
						placeholder="Confirm Password"
						label="Confirm Password"
						onChange={handleChange}
						value={reset.confirmNewPassword}
						error={errMessage}
					/>

					{errMessage && <ErrorAlert error={errMessage} />}
				</form>

				<CustomButton
					text="Continue"
					onClick={submit}
					disabled={
						reset.newPassword === '' ||
						reset.confirmNewPassword === '' ||
						reset.newPassword.length < 8 ||
						strength === 'Weak' ||
						(reset.newPassword !== '' &&
							reset.confirmNewPassword !== '' &&
							reset.confirmNewPassword !== reset.newPassword)
					}
					loading={loader}
				/>

				<div className="flex items-center justify-center mt-2">
					<Link
						to="/login"
						className="font-bold text-[12px] underline text-blue"
					>
						Return to login
					</Link>
				</div>
			</div>
		</AuthCard>
	);
};

export default NewPassword;
