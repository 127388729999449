import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import { getDateRangeQs } from '../../utils'
import PageTitle from '../PageTitle/pageTitle';

import ExcelIcon from '../../assets/excelIcon.svg';

import GrayEllipse from '../../assets/grayEllipse.png';
import greenUpArrow from '../../assets/greenUpArrow.png';
import filter from '../../assets/filter.svg';
import pdf from '../../assets/pdf.svg';

import ProductAnalyticPage from './Analytics';
import RightSideProduct from './Rightside';
import ProductSaleOverview from './SaleOverview';
import {
	requestGetBrandAnalytics,
	requestGetTopProducts,
	requestTopProductDataList,
} from '../../appRedux/actions/brands';
import { requestMyDetails } from '../../appRedux/actions/auth';

export default function ProductsComponent() {
	const dispatch = useDispatch();
	const { topProductLists, topProductDataList } = useSelector(
		({ brands }) => brands
	);
	const { period, range: globalRange } = useSelector(state => state.common.globalDate);

	const [activePeriod, setActivePeriod] = useState(period.toLowerCase());
	const [productSalePeriod, setActiveproductSalePeriod] = useState(period.toLowerCase());

	useEffect(() => {
		dispatch(requestMyDetails({}));
		dispatch(requestGetTopProducts(getDateRangeQs(globalRange)));
		dispatch(requestGetBrandAnalytics(getDateRangeQs(globalRange)));
		dispatch(requestTopProductDataList(getDateRangeQs(globalRange)));
	}, []);

	// const [brandsRange, setBrandsRange] = React.useState({});
	const handleDayFilter = (day) => {
		dispatch(requestGetTopProducts({ day_filter: day?.toLowerCase() }));
	};
	const handleDayListFilter = (day) => {
		// console.log("dayyy....", day?.toLowerCase());
		dispatch(requestTopProductDataList({ day_filter: day?.toLowerCase() }));
	};
	const handleDateListRangeSelect = (range) => {
		// setBrandsRange(range);
		// console.log("lalalla", range);
		dispatch(requestTopProductDataList(getDateRangeQs(
			range.to === null
				? { ...range, to: range.from }
				: range
		)));
	};
	const handleDateRangeSelect = (range) => {
		// setBrandsRange(range);
		// console.log('lalalla', range);
		dispatch(requestGetTopProducts(getDateRangeQs(
			range.to === null
				? { ...range, to: range.from }
				: range
		)));
	};

	return (
		<div className="overflow-hidden">
			<PageTitle title="Products" />
			<div>
				<div class="grid grid-cols-3 gap-7 pb-5 pl-6 pr-6 ">
					{/* row one */}
					<main class="col-span-2  ">
						<div className="grid grid-rows-2">
							<ProductAnalyticPage
								pdf={pdf}
								activePeriod={activePeriod}
								setActivePeriod={setActivePeriod}
								Top4ProductLists={topProductDataList?.slice(0, 4)}
								GrayEllipse={GrayEllipse}
								otherBrands={topProductDataList?.slice(4, 10)}
								pieChartData={topProductDataList?.slice(0, 4)}
								title="Products Analytics"
								otherItemsTitle="Other Products"
								handleDateRangeSelect={handleDateListRangeSelect}
								handleDayFilter={handleDayListFilter}
								exportData={topProductDataList}
								top4Title="Top 4 Products"
							/>
							<div className="row-span-1 ">
								<ProductSaleOverview
									pdf={pdf}
									activePeriod={productSalePeriod}
									setActivePeriod={setActiveproductSalePeriod}
									greenUpArrow={greenUpArrow}
									filter={filter}
									title="Products Sale Overview"
									handleDateRangeSelect={handleDateRangeSelect}
									handleDayFilter={handleDayFilter}
									topdataLists={_.orderBy(
										topProductLists,
										['label'],
										['asc']
									)}
								/>
							</div>
						</div>
					</main>

					<RightSideProduct
						ExcelIcon={ExcelIcon}
						topItemList={topProductDataList}
						title="Top Products"
						exportData={topProductDataList?.map(
							({ uuid, logo, image, ...item }) => item
						)}
					/>
				</div>
			</div>
		</div>
	);
}

export const Top4Items = ({ name, percentage, imgOnPie }) => {
	return (
		<div className="flex justify-between  font-appBlack bg-gray-200 px-3 mb-1 py-2">
			<div className="flex align-middle items-center">
				<img
					src={imgOnPie}
					className="pr-2"
					alt=""
					height="auto"
					width="auto"
				/>
				<p className=""> {name}</p>
			</div>
			<p className="font-semibold text-appBlack">{percentage}</p>
		</div>
	);
};
