import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import Countdown from 'react-countdown';

import { Link } from 'react-router-dom';
//components
import AuthCard from '../../components/AuthCard/AuthCard';
import CustomButton from '../../components/CustomButton/CustomButton';
import ErrorAlert from '../../components/ErrorAlert/ErrorAlert';

import { showLoader } from '../../appRedux/actions/common';
import {
	requestVerifyOtp,
	requestResetPwd,
	requestResendOTP,
} from '../../appRedux/actions/auth';

import { useDispatch, useSelector } from 'react-redux';
const VerifyIdentity = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { email } = useParams();
	const [otp, setOtp] = useState('');
	const [countdownKey, setCountdownKey] = useState(1);

	const { loader, errMessage } = useSelector(({ common }) => common);
	const { verifyURL } = useSelector(({ auth }) => auth);
	useEffect(() => {
		if (verifyURL?.type === 'reset-pwd') {
			history.push(verifyURL?.pathname);
		}
	});

	const submit = () => {
		dispatch(showLoader(true));

		dispatch(requestVerifyOtp({ type: 'RESET', email, otp }));
	};
	const resendOTPHandler = () => {
		dispatch(requestResendOTP(email));
		setCountdownKey((prevState) => prevState + 1);
	};

	const renderer = ({ seconds, completed }) => {
		if (completed) {
			return (
				<span
					onClick={resendOTPHandler}
					className="text-[12px] text-blue font-bold cursor-pointer"
				>
					Resend code
				</span>
			);
		} else {
			return (
				<span className="font-bold text-gray2">
					Resend code in 00:{seconds}
				</span>
			);
		}
	};

	const onCompleteHandler = () => {
		dispatch(requestResetPwd({ email }));
	};
	return (
		<AuthCard>
			<div className="flex flex-col items-center space-y-2">
				<h1 className="text-2xl font-bold text-center">Verify your identity</h1>

				<p className="leading-none text-center text-gray2 w-[60%] text-sm">
					Please enter verification code sent to{' '}
					<span className="font-bold">{email}</span>
				</p>
			</div>

			<div className="w-[65%]">
				<form className="space-y-2">
					{errMessage && <ErrorAlert error={errMessage} />}
					<OtpInput
						value={otp}
						onChange={(otp) => setOtp(otp)}
						numInputs={6}
						inputStyle={`bg-[#F4F5F7] w-10 h-10 caret-blue border-0 border-transparent font-medium ${
							errMessage
								? 'text-red border-b-2 border-red rounded-t-md rounded-b-none'
								: 'text-gray1 rounded-md'
						}`}
						focusStyle="outline-none ring-0 border-b-2 border-b-blue rounded-t-md rounded-b-none text-appBlue transition duration-500 ease-in-out ring-transparent"
						separator={<span className="w-2.5" />}
					/>
				</form>

				<CustomButton
					text="Continue"
					onClick={submit}
					disabled={otp.length < 6}
					loading={loader}
				/>

				<div className="flex items-center justify-center mt-3">
					<span className="text-[12px] text-gray3">
						Didn't get code?{' '}
						<Countdown
							onComplete={onCompleteHandler}
							renderer={renderer}
							date={Date.now() + 30000}
							zeroPadTime={3}
							key={countdownKey}
						/>
					</span>
				</div>

				<div className="flex flex-col items-center mt-10 space-y-2">
					<Link to="/" className="underline text-[12px] text-gray2">
						Use another method to recieve the code
					</Link>
				</div>
			</div>
		</AuthCard>
	);
};

export default VerifyIdentity;
