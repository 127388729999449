import React, { memo, useEffect } from 'react';
import {
	Redirect,
	Route,
	Switch,
	useLocation,
	useRouteMatch,
	useHistory,
} from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
//components

//pages

import ResetPassword from './pages/reset-password';
import ResetVerifyIdentity from './pages/reset-password/verify-identity';
import NewPassword from './pages/reset-password/new-password';
import ResetSuccess from './pages/reset-password/reset-success';
import OTPReceive from './components/otpReceival/otpReceive';
import Login from './pages/login';
import VerifyIdentity from './pages/login/verify-identity';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MainApp from './MainApp';

import { setInitUrl } from './appRedux/actions/auth';
const RestrictedRoute = ({
	component: Component,
	location,
	token,
	...rest
}) => (
	<Route
		{...rest}
		render={(props) =>
			token ? (
				<Component {...props} />
			) : (
				<Redirect to={{ pathname: '/login', state: { from: location } }} />
			)
		}
	/>
);

const App = () => {
	const { token, initURL } = useSelector(({ auth }) => auth);

	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();
	const match = useRouteMatch();

	useEffect(() => {
		if (initURL === '') {
			dispatch(setInitUrl(location.pathname));
		}
	});

	useEffect(() => {
		if (location.pathname === '/') {
			if (token === null) {
				history.push('/login');
			} else if (initURL === '' || initURL === '/' || initURL === '/login') {
				history.push('/overview');
			} else {
				history.push(initURL);
			}
		}
	}, [token, initURL, location, history]);

	return (
		<Switch>
			
			<Route
				exact
				path="/reset-password/:email/verify-identity"
				component={ResetVerifyIdentity}
			/>
			<Route
				exact
				path="/reset-password/new-password"
				component={NewPassword}
			/>
			<Route
				exact
				path="/reset-password/reset-success"
				component={ResetSuccess}
			/>
			<Route exact path="/reset-password" component={ResetPassword} />
			<Route
				exact
				path="/login/:email/verify-identity"
				component={VerifyIdentity}
			/>
			<Route exact path="/receiveOTP" component={OTPReceive} />
			<Route path="/login" component={Login} />
			<RestrictedRoute
				path={`${match.url}`}
				token={token}
				location={location}
				component={MainApp}
			/>
		</Switch>
	);
};

export default memo(App);
