import React, { useState } from 'react';
import FileUpload from '../../assets/FileUpload.svg';

const FileHistory = () => {
	const [fileHistory, setfileHistory] = useState([
		// {
		// 	date: ' December 20, 2020',
		// 	time: '11:04 PM',
		// 	uploadBy: ' Nathan Rose',
		// 	fileName: 'Sales from Niger',
		// },
		// {
		// 	date: ' December 03, 2020',
		// 	time: '03:93 PM',
		// 	uploadBy: ' Sean Willis',
		// 	fileName: 'Sales from Niger',
		// },
		// {
		// 	date: ' December 03, 2020',
		// 	time: '10:44 PM',
		// 	uploadBy: 'Olga Hansen',
		// 	fileName: 'Sales from Niger',
		// },
		// {
		// 	date: ' December 03, 2020',
		// 	time: '10:44 PM',
		// 	uploadBy: 'Connie Robbins',
		// 	fileName: 'Sales from Niger',
		// },
		// {
		// 	date: ' October 06, 2020',
		// 	time: '07:44 PM',
		// 	uploadBy: 'Debbie Garrett',
		// 	fileName: 'Sales from Agege',
		// },
	]);

	return (
		<div className="p-10 ">
			<div className="mx-auto w-[950px] py-3 ">
				<h4 className="font-semibold text-[20px] text-appBlack py-1 border-b border-[#E3E7ED]">
					File Upload History
				</h4>
			</div>

			<div className=" mx-auto w-[950px]  ">
				{fileHistory?.length > 0 ? (
					<div className="w-full ">
						<div className="grid grid-cols-4  px-4 py-2 w-full text-gray2 font-semibold text-xs">
							<p className="px-2">DATE</p>
							<p>TIME</p>
							<p>UPLOAD BY</p>
							<p>FILE NAME</p>
						</div>
						{React.Children.toArray(
							fileHistory.map((item) => {
								return (
									<div className="grid grid-cols-4 bg-white p-4 w-full text-sm font-medium text-gray2 border-b border-[#E3E7ED] align-middle ">
										<div className="px-2">{item.date}</div>
										<div>{item.time}</div>
										<div>{item.uploadBy}</div>
										<div>{item.fileName}</div>
									</div>
								);
							})
						)}
					</div>
				) : (
					<div className="h-[332px] flex justify-center bg-white">
						<div className="flex flex-col justify-center items-center ">
							<div className="w-[48px]  text-center  ">
								<img
									src={FileUpload}
									alt="file upload"
									w="100%"
									height="auto"
								/>
							</div>
							<div className="w-[276px]">
								<p className="text-lg font-semibold text-center text-gray2">
									History of all your file uploads will show up here.
								</p>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default FileHistory;
