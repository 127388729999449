import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';

function FilterData({ filter, handleStateFilter, handleCountryFilter }) {
	const { analyticList, topBrandLists, topProductLists, locationFilters } =
		useSelector(({ brands }) => brands);
	//   // console.log(topBrandLists, topProductLists);
	// console.log('filters', locationFilters);
	return (
		<div className=" flex space-x-2 justify-end pt-4 items-end border-b pb-3  border-thinGrayBG ">
			<div className=" flex flex-col">
				<label className="text-gray3 text-sm font-medium">Country</label>
				<div className=" flex gap-1 align-middle items-center border border-[#E3E7ED] text-gray3 rounded-md">
					<img
						src={filter}
						alt=""
						width="auto"
						height="auto"
						className="px-1"
					/>
					<label className="text-gray3 text-xs font-medium">Filter by:</label>
					<select className="shadow-none border-none text-gray2 focus:border-none font-medium text-sm " onChange={(e)=>handleCountryFilter(e.target.value)}>
						{_.uniqBy(locationFilters, 'country')?.map((item) => (
							<option value={item.country}>{item.country}</option>
						))}
					</select>
				</div>
			</div>
			<div className=" flex flex-col">
				<label className="text-gray3 text-sm font-medium">State/Region</label>

				<select className="shadow-none border-[#E3E7ED] border   rounded-md text-gray2  font-medium text-sm " onChange={(e)=>handleStateFilter(e.target.value)}>
					{_.uniqBy(locationFilters, 'city')?.map((item) => (
						<option value={item.city}>{item.city}</option>
					))}
				</select>
			</div>
			<div className=" flex flex-col">
				<label className="text-gray3 text-sm font-medium">Brand</label>

				<select className="shadow-none border-[#E3E7ED] border   rounded-md text-gray2  font-medium text-sm ">
					{_.uniqBy(topBrandLists, 'label')?.map((item) => (
						<option>{item.label}</option>
					))}
				</select>
			</div>
			<div className=" flex flex-col">
				<label className="text-gray3 text-sm font-medium">Product</label>

				<select className="shadow-none border-[#E3E7ED] border   rounded-md text-gray2  font-medium text-sm ">
					{_.uniqBy(topProductLists, 'label')?.map((item) => (
						<option>{item.label}</option>
					))}
				</select>
			</div>
		</div>
	);
}

export default FilterData;
