import React, { useState, useEffect } from 'react';
import { getComparator, stableSort } from '../../utils/tableFunctions';
import useTableMethods from '../../customHooks/useTableMethods';

//icons
import trash from '../../assets/trash.svg';
import edit from '../../assets/edit.svg';
import view from '../../assets/view.svg';
import loader from '../../assets/loader.svg';
import { styled } from '@material-ui/core/styles';
import {
	Table,
	TableBody,
	TableCell,
	TablePagination,
	TableRow,
	Checkbox,
	Avatar,
	ButtonGroup,
	Button,
} from '@material-ui/core';
import FallBackImage from '../../assets/drinkslogoIcon.svg';
import { useSelector, useDispatch } from 'react-redux';
import Pagination from '@material-ui/lab/Pagination';

//components
import CustomTableHead from './TableHead';
import { requestGetUsers } from '../../appRedux/actions/auth';

const UsersTable = ({
	rows,
	onDeleteClick,
	onEditClick,
	onViewClick,
	search = '',
	busy = false,
	notFound = false,
}) => {
	const {
		handleSelectAllClick,
		handleClick,
		handleChangePage,
		handleChangeRowsPerPage,
		isSelected,
		emptyRows,
		order,
		orderBy,
		selected,
		page,
		rowsPerPage,
	} = useTableMethods(rows);
	const { userLists, userListCount } = useSelector(({ auth }) => auth);
	// console.log('user count list', userListCount);
	const dispatch = useDispatch();
	const [listPagination, setListPagination] = useState(1);
	const handleChange = (event, value) => {
		setListPagination(value);
		setListPagination(value);
		dispatch(requestGetUsers({ page: value }));
	};
	return (
		<div className="w-full mt-5">
			{busy || (search.length > 0 && userLists.length === 0) ? (
				<>
					<Table className="min-w-[750px]" size="small">
						<CustomTableHead
							numSelected={selected.length}
							onSelectAllClick={handleSelectAllClick}
							rowCount={rows.length}
						/>
					</Table>
					<div className="bg-white w-full h-[312px] flex flex-col justify-center items-center mt-2">
						{busy && !notFound ? (
							<img src={loader} alt="loading" className="w-5 animate-spin" />
						) : (
							search && (
								<>
									<p className="text-[#3D4356] text-[18px] font-bold text-center mb-2">
										No Search Result for "{search}"
									</p>
									<p className="text-[#8892A2] text-[14px] w-[448px] text-center font-normal">
										<span className="text-[#8892A2] text-[14px] font-bold">
											Search tips:{' '}
										</span>
										Some search terms require an exact match. Try typing the
										entire term, or use a different word or phrase.
									</p>
								</>
							)
						)}
					</div>
				</>
			) : (
				<Table className="min-w-[750px]" size="small">
					<CustomTableHead
						numSelected={selected.length}
						onSelectAllClick={handleSelectAllClick}
						rowCount={rows.length}
						className=" bg-white hover:border hover:border-[#E9ECEF] outline-[#E9ECEF] "
					/>
					<TableBody className="bg-white">
						{stableSort(rows, getComparator(order, orderBy))
							.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							.map((row, index) => {
								const isItemSelected = isSelected(index);
								// // console.log(row?.status)
								return (
									<TableRow
										hover
										role="checkbox"
										aria-checked={isItemSelected}
										tabIndex={-1}
										key={row?.id}
										selected={isItemSelected}
										className="border-b-0 border-l-4 hover:border-l-orange border-transparent hover:bg-[#EEF2F5]"
									>
										<TableCell padding="checkbox">
											{/* <Checkbox
												classes={{
													root: ' bg-white hover:border hover:border-[#E9ECEF] outline-[#E9ECEF] shadow-md',
													boxShadow: '1px',
												}}
												checked={isItemSelected}
												onClick={(event) => handleClick(event, index)}

												
											/> */}
											<BpCheckbox
												checked={isItemSelected}
												onClick={(event) => handleClick(event, index)}
											/>
										</TableCell>
										<TableCell className="flex items-center space-x-2">
											<Avatar
												className="w-8 h-8"
												src={row?.profile.picture || FallBackImage}
											/>
											<p className="text-sm font-medium text-gray2">
												{row?.profile?.first_name} {row?.profile?.last_name}
											</p>
										</TableCell>
										<TableCell className="text-sm font-medium text-gray2">
											{row?.email}
										</TableCell>
										<TableCell className="text-sm font-medium text-gray2">
											{row?.phone_number}
										</TableCell>
										<TableCell className="text-sm font-medium text-gray2">
											{row?.account_type || 'Adminstrator'}
										</TableCell>
										<TableCell>
											<span
												className={`flex flex-grow-0 items-center space-x-2 justify-center py-1 rounded-lg capitalize w-[fit-content] px-3 
												${row?.is_active === false && 'bg-appGray/20 text-appGray'}
												 ${row?.is_active === true && 'bg-green/20 text-green'}
                      `}
											>
												<p className="">
													{row?.is_active ? 'Active' : 'Inactive'}
												</p>
											</span>
										</TableCell>

										<TableCell align="right">
											<ButtonGroup size="medium">
												{/* <Button onClick={() => onViewClick(row)}>
													<img src={trash} alt="" />
												</Button> */}
												<Button onClick={() => onEditClick(row)}>
													<img src={edit} alt="" />
												</Button>
												<Button onClick={() => onViewClick(row)}>
													<img src={view} alt="" />
												</Button>
											</ButtonGroup>
										</TableCell>
									</TableRow>
								);
							})}
						{emptyRows > 0 && (
							<TableRow style={{ height: 53 * emptyRows }}>
								<TableCell colSpan={7} />
							</TableRow>
						)}
					</TableBody>
				</Table>
			)}
			{!busy && userLists?.length > 0 && (
				<div className="flex w-full items-center justify-between">
					<Pagination
						count={Math.ceil(userListCount / 50)}
						page={listPagination}
						onChange={handleChange}
						shape="rounded"
					/>{' '}
					<TablePagination
						rowsPerPageOptions={[5, 10, 25, 50]}
						component="div"
						count={rows.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</div>
			)}
		</div>
	);
};

export default UsersTable;
export const BpIcon = styled('span')(({ theme }) => ({
	borderRadius: 3,
	width: 18,
	height: 18,
	boxShadow: ' 1px 1px rgba(16,22,26,.2)',
	backgroundColor: ' #fff',
	backgroundImage: 'url(../../assets/Rectangle.svg)',
	border: ' 1px solid rgba(16,22,26,.2)',

	'.Mui-focusVisible &': {
		outline: '2px auto rgba(19,124,189,.6)',
		outlineOffset: 2,
	},
	'input:hover ~ &': {
		backgroundColor: '#fff',
	},
	'input:disabled ~ &': {
		boxShadow: 'none',
		background:
			theme.palette.mode === 'dark'
				? 'rgba(57,75,89,.5)'
				: 'rgba(206,217,224,.5)',
	},
}));
export const BpCheckedIcon = styled(BpIcon)({
	backgroundColor: '#F3641F',
	backgroundImage:
		'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
	'&:before': {
		display: 'block',
		width: 18,
		height: 18,
		backgroundImage:
			"url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
			" fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
			"1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
		content: '""',
		content: '""',
	},
	'input:hover ~ &': {
		backgroundColor: '#F3641F',
	},
});

export function BpCheckbox(props) {
	return (
		<Checkbox
			sx={{
				'&:hover': { bgcolor: '#fff' },
			}}
			disableRipple
			color="primary"
			checkedIcon={<BpCheckedIcon />}
			icon={<BpIcon />}
			inputProps={{ 'aria-label': 'Checkbox demo' }}
			{...props}
		/>
	);
}
